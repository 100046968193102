import React, {useState} from 'react';
// import Swiper, {Autoplay} from 'react-id-swiper';
// // import 'swiper/css/swiper.css';
 
import  Swiper  from "react-id-swiper";
import SwiperCore, { Autoplay,  Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
// import 'swiper/swiper-bundle.css';
import '../AppStyle.css'

const SliderPrincipal = () => {
    const [swiper, setSwiper] = useState(null);

    const goNext = () => {
        if (swiper !== null) {
          swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
          swiper.slidePrev();
        }
    };
    SwiperCore.use([Autoplay]);

    return (
        // <section className="sliderbanner">
        <div class="info-box" id='backgroundSlider'>
            {/* <img class="img--layout" src="img/about_layout.png" alt="img"/> */}
            <img class="img--bg" src="img/banner_01.png" alt="img"/> 
            
        </div>  
        // </section>
        // <section className="slider-area slider-area2 text-center">
        //     <div className="homepage-slide1">                 
        //         <div className="single-slide-item slide-bg1">
        //         </div> 
        //     </div>
        // </section>



        // <section className="slider-area slider-area2 text-center">
        //     <div className="homepage-slide1">
        //         <Swiper getSwiper={setSwiper} pagination={{ clickable: true }} autoplay>
        //             <div className="single-slide-item slide-bg1">
        //             </div>
        //             <div className="single-slide-item slide-bg2">
        //                 <div className="slide-item-table">
        //                     <div className="slide-item-tablecell">
        //                         <div className="container">
        //                             <div className="row">
        //                                 <div className="col-lg-8 mx-auto">
        //                                     <div className="slider-heading">
        //                                         <p className="slider__meta text-primary">help the people in need</p>
        //                                         <h2 className="slider__title text-primary">Your small help make world better</h2>
        //                                         <a href="#" className="theme-btn text-primary">discover more</a>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div> 
        //             </div> 
        //         </Swiper>
        //         <div className="owl-dots">
        //             <div onClick={goPrev} className="owl-dot"><span></span></div>
        //             <div onClick={goNext} className="owl-dot"><span></span></div>
        //         </div>
        //     </div>
        // </section>
    );
};

export default SliderPrincipal;


import React, {useState,useEffect,useRef} from 'react'
import ReactHtmlTableToExcel from 'react-html-table-to-excel' 
import CurrencyFormat from 'react-currency-format';

import "./cuotaempleoPage.css"

 function ResumenAccesibilidad (props) {
    const [dataAccesibilidad, setDataAccesibilidad] = useState(props.listaSedes);
    const [showTable, setShowTable] = useState(false);
    const tableRefHide = useRef(null);
    useEffect(() => {
        setDataAccesibilidad(props.listaSedes);
        setShowTable(false)
    }, [props.listaSedes]);

    return(
        <div className="table-responsive">
            <div className="card">
                {/* <div className="card-header text-center">
                    <h4 className="card-text text-primary font-w900"> SUB MATERIAS DE ACCESIBILIDAD</h4>
                </div> */}
             
                <div className="card-body">
                    <div className="col text-right" style={{marginBottom: '5px'}}> 
                        <ReactHtmlTableToExcel
                            id="test-table-xls-button"
                            className="btn btn-danger"
                            table="tblCuota"
                            filename="CONADIS - EJE ACCESIBILIDAD"
                            sheet="EJE ACCESIBILIDAD"
                            buttonText="Descargar Información"
                        /> 
                    </div> 
                    <table id="tblCuota" border="1" className="table table-striped table-bordered">
                        <thead>
                            <tr className='tablita'> 
                                <th rowSpan= "3">ENTIDADES</th>
                                <th rowSpan= "3">TOTAL DE FISCALIZACIONES</th>
                                <th colSpan= "12">SUB MATERIAS DE ACCESIBILIDAD</th>
                            </tr>
                            <tr className='tablita'>  
                                <th colSpan= "2">ACCESIBILIDAD EN LA COMUNICACIÓN</th>
                                <th colSpan= "2">ACCESIBILIDAD EN LA EDIFICACIÓN</th>
                                <th colSpan= "2">ACCESIBILIDAD EN LA EDIFICACION Y TRANSPORTE</th>
                                <th colSpan= "2">ACCESIBILIDAD TICS</th>
                                <th colSpan= "2">ACCESIBILDAD URBANA</th>
                                <th colSpan= "2">ACCESIBILIDAD URBANA Y ESTACIONAMIENTOS PCD</th>
                            </tr>
                            <tr className='tablita'>                                       
                                <th>(ABS) </th>
                                <th>(%)</th>
                                <th>(ABS) </th>
                                <th>(%)</th> 
                                <th>(ABS) </th>
                                <th>(%)</th> 
                                <th>(ABS) </th>
                                <th>(%)</th>
                                <th>(ABS) </th>
                                <th>(%)</th> 
                                <th>(ABS) </th>
                                <th>(%)</th> 
                            </tr> 
                        </thead>
                        <tbody style={{fontSize: '14px'}}>
                            {
                                dataAccesibilidad.map((x,index)=>{                           
                                    return( 
                                        <tr key={index}>
                                            <td class="colorNegrita" >{x.entidad}</td>
                                            <td class="colorNegrita ">{x.tot_ef || 0}</td>
                                            <td class="colorNegrita ">{x.tot_comunica || 0}</td>
                                            <td class="colorNegrita ">{parseFloat(x.tot_comunica_porc || 0).toFixed(2)}</td>
                                            <td class="colorNegrita ">{x.tot_edificacion || 0}</td> 
                                            <td class="colorNegrita ">{parseFloat(x.tot_edificacion_porc || 0).toFixed(2)}</td> 
                                            <td class="colorNegrita ">{x.tot_transporte || 0}</td>
                                            <td class="colorNegrita ">{parseFloat(x.tot_transporte_porc || 0).toFixed(2)}</td>
                                            <td class="colorNegrita ">{x.tot_tics || 0}</td> 
                                            <td class="colorNegrita ">{parseFloat(x.tot_tics_porc || 0).toFixed(2)}</td>                                
                                            <td class="colorNegrita ">{x.tot_urbana || 0}</td> 
                                            <td class="colorNegrita ">{parseFloat(x.tot_urbana_porc || 0).toFixed(2)}</td>
                                            <td class="colorNegrita ">{x.tot_estapcd || 0}</td> 
                                            <td class="colorNegrita ">{parseFloat(x.tot_estapcd_porc || 0).toFixed(2)}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                        <tfoot>
                            <tr className='tablita'>
                                <th colSpan= "14" className="colorNegritaLeft">
                                    *Accesibilidad: contemplada en el artículo 15, 16, 17, 19, 20, 21, 22, 23° de la Ley N° 29973, Ley General de la Persona con Discapacidad.<br/>
                                    1/ Comprende los 43 distritos y las provincia de Lima: Barranca, Cajatambo, Canta, Cañete, Huaral, Huarochirí, Huaura, Oyón y Yauyos.<br/>
                                    Nota.- De las fiscalizaciones realizadas en materia de Accesibilidad, se debe indicar que a la fecha de la fiscaliación, ninguna entidad cumple con presentar accesibilidad en sus Entidades. 
                                    Asimismo, las fiscalizaciones en materia de accesibilidad física, se han desarrollado de forma presencial en Lima Metropolitana y a novel de provincia, se han requerido información al respecto.<br/>
                                    Fuente: Sub Dirección de Fiscalización - CONADIS<br/>
                                    {/* Fecha de Corte: 31/12/2021 */}
                                </th> 
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>     
    );
    
}
export default ResumenAccesibilidad;


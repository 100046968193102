 
import React, {useEffect,useRef,useState } from 'react'
import axios from 'axios';
import '../AppStyle.css';
import PageHeader from '../componentes/header';
import CuotaempleoPage from './ejeEmpleo/empleo/cuotaEmpleoPage';
import RubroEmpleoPage from './ejeEmpleo/rubro/rubroEmpleoPage';
import SliderPrincipal from '../componentes/SliderPrincipal';
import EstablecimientoSalud from './Instituciones/establecimientos/EstablecimientoSalud';
import CebePrite from './Instituciones/cebeprite/CebePrite';
import CentroCER from './Instituciones/cer/CER';
import Certificadoras from './Instituciones/certificadoras/Certificadoras';


const PageInstituciones = (props) => {
    const [saludShow, setSaludShow] = useState(false); 
    const [cebepriteShow, setCebepriteShow] = useState(false);
    const [cebShow, setCEBShow] = useState(false);
    const [certificaShow, setCertificaShow] = useState(true);  

    const clickSalud = (e) => {
        e.preventDefault();
        setSaludShow(true); 
        setCebepriteShow(false);
        setCEBShow(false);
        setCertificaShow(false);
    }
    const clickCebeprite = (e) => {
        e.preventDefault();
        setSaludShow(false); 
        setCebepriteShow(true);
        setCEBShow(false);
        setCertificaShow(false);        
    }
    const clickCEB = (e) => {
        e.preventDefault();
        setSaludShow(false); 
        setCebepriteShow(false);
        setCEBShow(true);
        setCertificaShow(false); 
    }
    const clickCertifica = (e) => {
        e.preventDefault();
        setSaludShow(false); 
        setCebepriteShow(false);
        setCEBShow(false);
        setCertificaShow(true); 
    }

    return (
        <>
         <PageHeader activeInstituciones={true}/>
            {/* SIS FISCALIZACIÓN */}
            <SliderPrincipal/>
            {/* <section className="section shop"> */}
            <div className="section shop margin-top"> 
                <div className="container_extra">
                    <div className="row margin-bottom ">
                        <div className="col-12"> 
                            <div className="heading heading--primary heading--center">
                                <h2 className="heading__title no-margin-bottom text-primary"><span>INSTITUCIONES CERTIFICADORAS DEL SECTOR <br/>SALUD Y EDUCACIÓN </span></h2>
                            </div>
                        </div>
                    </div> 
                    <div className="row margin-bottom">
                        <div className="col" onClick={clickCertifica}>
                            <div className={`sistemas team-item team-item--rounded${certificaShow ? " fisca_activo" : ""}`}>  
                                <div className="team-item__img-holder">
                                     <div className="team-item__img">
                                        <img className="img--bg" src="img/certifica.png" alt="EJE PRESUPUESTO"/>
                                    </div>
                                </div>
                                <div className="team-item__description">
                                    <div className="team-item__name"><h5>INSTITUCIONES CERTIFICADORAS</h5></div>
                                    <div className="team-item__position text-dark">Instituciones donde las personas con discapacidad pueden atenderse y gestionar su certificado de discapacidad</div>
                                </div>
                            </div>
                        </div>                        
                        <div className="col" id='idEmpleo' onClick={clickSalud}>
                             <div className={`sistemas team-item team-item--rounded${saludShow ? " fisca_activo" : ""}`}> 
                                <div className="team-item__img-holder"> 
                                     <div className="team-item__img">
                                        <img className="img--bg" src="img/salud.png" alt="EJE EMPLEO"/>
                                    </div>
                                </div>
                                <div className="team-item__description">
                                    <div className="team-item__name"><h5>ESTABLECIMIENTOS DE SALUD</h5></div>
                                    <div className="team-item__position text-dark">Servicios de salud a nivel nacional, según categorías por niveles de atención.</div>
                                </div>
                            </div>
                        </div>
                        <div className="col" id='idAccesibilidad' onClick={clickCebeprite}>
                            <div className={`sistemas team-item team-item--rounded${cebepriteShow ? " fisca_activo" : ""}`}> 
                                <div className="team-item__img-holder">
                                     <div className="team-item__img">
                                        <img className="img--bg" src="img/cebeprite.png" alt="EJE ACCESIBILIDAD"/>
                                    </div>
                                </div>
                                <div className="team-item__description">
                                    <div className="team-item__name"><h5>CEBE Y PRITE</h5></div>
                                    <div className="team-item__position text-dark">Servicios educativos que atienden a estudiantes con discapacidad a nivel nacional.</div>
                                </div>
                            </div>
                        </div>
                        <div className="col" onClick={clickCEB}>
                            <div className={`sistemas team-item team-item--rounded${cebShow ? " fisca_activo" : ""}`}> 
                                <div className="team-item__img-holder">
                                     <div className="team-item__img">
                                        <img className="img--bg" src="img/cer_insti.png" alt="EJE EDUCACION"/>
                                    </div>
                                </div>
                                <div className="team-item__description">
                                    <div className="team-item__name "> <h5 className='font-w900'>CERP</h5></div>
                                    <div className="team-item__position text-dark">Servicios prestados por EsSalud, cuya finalidad es desarrollar programas de carácter formativo integral.</div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                    <div className="row margin-bottom">
                        {saludShow ? <EstablecimientoSalud /> : ''}
                        {cebepriteShow ? <CebePrite /> : ''}
                        {cebShow ? <CentroCER /> : ''}
                        {certificaShow ? <Certificadoras /> : ''}
                    </div>
                </div>
            </div>
            {/* </section> */}
        </>
    )
}

export default PageInstituciones
    
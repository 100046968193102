const FiltroOpcion = (props) => {
    const { label, onChangeOpcion, opciones } = props;
    return <div className="row align-items-baseline">
        <div className="col-12 text-right">
            <ul className="shop-filter">
                <li className="shop-filter__item shop-filter__item--active text-primary">
                    <span><h6 className='text-primary'><i className="fa fa-info-circle" aria-hidden="true"></i>&nbsp;{label}</h6></span>
                    <ul className="shop-filter__sub-list">
                        {
                            opciones.map((opcion) => <>
                                <li>
                                    <a onClick={(e) => { onChangeOpcion(opcion); }}>* {opcion.label}</a>
                                </li>
                            </>)
                        }
                    </ul>
                </li>
            </ul>
        </div>
    </div>
}

export default FiltroOpcion;
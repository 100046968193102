
import React, {useState,useEffect,useRef} from 'react'
import ReactHtmlTableToExcel from 'react-html-table-to-excel' 

import "../cuotaempleoPage.css"

// const ResumenTablaEmpleo = (props) => {
 function ResumenTablaEmpleo (props) {
    console.log(props);
    const [dataCuota, setDataCuota] = useState(props.listaSedes);
    const [showTable, setShowTable] = useState(false);
    const tableRefHide = useRef(null);
    useEffect(() => {
        setDataCuota(props.listaSedes);
        setShowTable(false)
    }, [props.listaSedes]);

    return(
        <div className="table-responsive">
            <div className="col text-right" style={{marginBottom: '5px'}}> 
                <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-danger"
                    table="tblCuota"
                    filename="CONADIS - EJE EMPLEO (CUOTA DE EMPLEO)"
                    sheet="CUOTA DE EMPLEO"
                    buttonText="Descargar Información"
                /> 
            </div> 
            <table id="tblCuota" border="1" className="table table-striped table-bordered">
                <thead>
                    {/* <tr className='tablita'>
                        <th colspan= "7">
                            <div className="col text-right"> 
                                <ReactHtmlTableToExcel
                                    id="test-table-xls-button"
                                    className="btn btn-danger"
                                    table="tblCuota"
                                    filename="CONADIS - EJE EMPLEO (CUOTA DE EMPLEO)"
                                    sheet="CUOTA DE EMPLEO"
                                    buttonText="Descargar Información"
                                /> 
                            </div> 
                        </th>
                    </tr> */}
                    <tr className='tablita'>
                        <th colSpan= "7"> CUOTA DE EMPLEO (5%) </th> 
                    </tr>
                    <tr className='tablita'> 
                        <th>REGIÓN</th>
                        <th>TOTAL DE TRABAJADORES</th>
                        <th>TOTAL DE PCD</th>
                        <th>% CUMPLIMIENTO</th>
                        <th>TOTAL DE PCD FALTANTES</th>
                        <th>% FALTANTE AL CUMPLIMIENTO DEL 5%</th>
                        <th>TOTAL DE ENTIDADES FISCALIZADAS</th>
                    </tr>
                </thead>
                <tbody style={{fontSize: '14px'}}>
                    {
                        // parseFloat(sedes[index].porc_cump || 0).toFixed(4) >=5
                        dataCuota.map((x,index)=>{                           
                            return( 
                                <tr key={index}>                                        
                                    <td className="colorNegrita">{x.sede}</td>                                      
                                    <td className="colorNegrita" >{x.tot_tbj || 0}</td>
                                    <td className="colorNegrita" >{x.tot_pcd || 0}</td>
                                    <td className="colorNegrita" >{parseFloat(x.porc_cump || 0).toFixed(2)}</td>
                                    <td className="colorNegrita" >{x.tot_pcdf || 0}</td>
                                    <td className="colorNegrita" >{parseFloat(x.porc_fc || 0).toFixed(2)}</td>
                                    <td className="colorNegrita" >{x.tot_ef || 0}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
                <tfoot>
                    <tr className='tablita'>
                        <th colSpan= "7" className="colorNegritaLeft">
                            *Cuota de Empleo: Contemplada en el artículo 49° de la Ley N° 29973, Ley General de la Persona con Discapacidad.<br/>
                            Fuente: Sub Dirección de Fiscalización - CONADIS<br/>
                            {/* Fecha de Corte: 31/12/2021 */}
                        </th> 
                    </tr>
                </tfoot>
            </table>
            { showTable ?
                <table id="tblCuotaHide" border="1" ref={tableRefHide}>
                    <thead> 									
                        <tr> 
                            <th>REGIÓN</th>
                            <th>TOTAL DE TRABAJADORES</th>
                            <th>TOTAL DE PCD</th>
                            <th>% CUMPLIMIENTO</th>
                            <th>TOTAL DE PCD FALTANTES</th>
                            <th>% FALTANTE AL CUMPLIMIENTO DEL 5%</th>
                            <th>TOTAL DE ENTIDADES FISCALIZADAS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataCuota.map((x,index)=>{                           
                                return( 
                                    <tr key={index}>                                        
                                        <td >{x.sede}</td>  
                                        <td >{x.tot_tbj || 0}</td>
                                        <td >{x.tot_pcd || 0}</td>
                                        <td >{parseFloat(x.porc_cump || 0).toFixed(2)}</td>
                                        <td >{x.tot_pcdf || 0}</td>
                                        <td >{parseFloat(x.porc_fc || 0).toFixed(2)}</td>
                                        <td >{x.tot_ef || 0}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colSpan= "7" className="colorNegritaLeft">
                                *Cuota de Empleo: contemplada en el artículo 49° de la Ley N° 29973, Ley General de la Persona con Discapacidad.<br/>
                                Fuente: Sub Dirección de Fiscalización - CONADIS<br/>
                                Fecha de Corte: 31/12/2021
                            </th> 
                        </tr>
                    </tfoot>
                </table> : '' }
        </div>     
    );
    
}
export default ResumenTablaEmpleo;
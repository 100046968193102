import React, {useState} from 'react';
// import Swiper from 'react-id-swiper';
// import 'swiper/css/swiper.css';
import  Swiper  from "react-id-swiper";
import SwiperCore, { Autoplay,  Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

const SliderTestimonio = () => {
    const [swiper, setSwiper] = useState(null);

    const goNext = () => {
        if (swiper !== null) {
          swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
          swiper.slidePrev();
        }
    };
    SwiperCore.use([Autoplay]);
    // const params = {
    //     slidesPerView : 5,
    //     loop: true,
    //     speed: 1000,
    //     spaceBetween : 30,
    //     autoplay: {
    //         delay: 3000,
    //         disableOnInteraction: false
    //     },
    //     // Responsive breakpoints
    //     breakpoints: {
    //         1499:{
    //             slidesPerView : 5
    //         },

    //         991:{
    //             slidesPerView : 3
    //         },

    //         767:{
    //             slidesPerView : 3

    //         },

    //         575:{
    //             slidesPerView : 2
    //         }
    //     }
    // }

    return (
        // <section className="clientlogo-area">
        //     <div className="container">
        //         <div className="row">
        //             <div className="col-md-12">
        //                 <div className="client-logo">
        //                     <Swiper {...params}>
        //                         <div className="client-logo-item">
        //                             <img src="/images/client-logo.png" alt="brand image" />
        //                         </div>
        //                         <div className="client-logo-item">
        //                             <img src="/images/client-logo.png" alt="brand image" />
        //                         </div>
        //                         <div className="client-logo-item">
        //                             <img src="/images/client-logo.png" alt="brand image" />
        //                         </div>
        //                         <div className="client-logo-item">
        //                             <img src="/images/client-logo.png" alt="brand image" />
        //                         </div>
        //                         <div className="client-logo-item">
        //                             <img src="/images/client-logo.png" alt="brand image" />
        //                         </div>
        //                         <div className="client-logo-item">
        //                             <img src="/images/client-logo.png" alt="brand image" />
        //                         </div>
        //                     </Swiper>
        //                 </div>
        //             </div>
        //         </div> 
        //     </div>
        // </section>

        <section className="section testimonials testimonials--style-1">
            <div className="container">
                <div className="row align-items-end margin-bottom">
                    
                    <div className="col-lg-8 col-xl-7 offset-xl-1">
                        <div className="heading heading--primary"><span className="heading__pre-title">Testimonials</span>
                            <h2 className="heading__title"><span>What People</span> <span>Says About Us</span></h2>
                        </div>
                    </div>
                    <div className="col-lg-4 col-xl-3">
                            
                        <div className="slider__nav testimonials-style-1__nav">
                            <div className="slider__arrows">
                                <div className="slider__prev"><i className="fa fa-chevron-left" aria-hidden="true"></i>
                                </div>
                                <div className="slider__next"><i className="fa fa-chevron-right" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                            
                    </div>
                </div>
                <div className="row">
                    {/* <div className="col-xl-10 offset-xl-1"> col-lg-8 mx-auto */}
                    <div className="col-lg-12 mx-auto ">
                        <div className="testimonials-slider testimonials-slider--style-1 slick-initialized slick-slider">
                            <Swiper getSwiper={setSwiper} 
                            // pagination={{ clickable: true }} autoplay
                            slidesPerView= 'auto'
                                centeredSlides= 'true'
                                loop= 'true'
                                slidesPerGroup= '1'
                                paginationClickable= 'true'
                                // pagination= {{
                                //     el= '.swiper-pagination',
                                //     clickable= 'true'
                                // }}
                            
                            >
                                <div className="testimonials-slider__item">
                                    <div className="testimonials-slider__icon">“</div>
                                    <div className="testimonials-slider__text">
                                        <p>Slickhead grunion lake trout. Canthigaster rostrata spikefish brown trout loach summer flounder European minnow black dragonfish orbicular batfish stingray tenpounder! Flying characin herring, Moses sole sea snail grouper discus. European eel slender snipe eel. Gulper eel dealfish ocean sunfish; rohu yellow-and-black triplefin Atlantic saury</p>
                                        <div className="testimonials-slider__author"><span className="testimonials-slider__name">Jack Wolfskin, </span><span className="testimonials-slider__position">Volunteer</span></div>
                                    </div>
                                </div>
                                <div className="testimonials-slider__item">
                                    <div className="testimonials-slider__icon">“</div>
                                    <div className="testimonials-slider__text">
                                        <p>Slickhead grunion lake trout. Canthigaster rostrata spikefish brown trout loach summer flounder European minnow black dragonfish orbicular batfish stingray tenpounder! Flying characin herring, Moses sole sea snail grouper discus. European eel slender snipe eel. Gulper eel dealfish ocean sunfish; rohu yellow-and-black triplefin Atlantic saury</p>
                                        <p>Slickhead grunion lake trout. Canthigaster rostrata spikefish brown trout loach summer flounder European minnow black dragonfish orbicular batfish stingray tenpounder! Flying characin herring, Moses sole sea snail grouper discus. European eel slender snipe eel. Gulper eel dealfish ocean sunfish; rohu yellow-and-black triplefin Atlantic saury</p>
                                        <div className="testimonials-slider__author"><span className="testimonials-slider__name">Jack Wolfskin, </span><span className="testimonials-slider__position">Volunteer</span></div>
                                    </div>
                                </div>
                                <div className="testimonials-slider__item">
                                    <div className="testimonials-slider__icon">“</div>
                                    <div className="testimonials-slider__text">
                                        <p>Slickhead grunion lake trout. Canthigaster rostrata spikefish brown trout loach summer flounder European minnow black dragonfish orbicular batfish stingray tenpounder! Flying characin herring, Moses sole sea snail grouper discus. European eel slender snipe eel. Gulper eel dealfish ocean sunfish; rohu yellow-and-black triplefin Atlantic saury</p>
                                        <div className="testimonials-slider__author"><span className="testimonials-slider__name">Jack Wolfskin, </span><span className="testimonials-slider__position">Volunteer</span></div>
                                    </div>
                                </div>
                            </Swiper>
                        </div>
                    </div>
                </div>

                 
            </div>
        </section>
    );
};

export default SliderTestimonio;

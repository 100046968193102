import React, {useState,useEffect} from 'react'
import { NavLink } from 'react-router-dom';

import '../AppStyle.css';

function PageHeader (props) {

    const [menuWeb, setMenuWeb] = useState(false);
    const [menuMobile, setMenuMobile] = useState(false); 
    const showMenuWeb = () => {
        setMenuWeb(true); 
    }
    const showMenuMobile = () => {
        setMenuMobile(true); 
    }
    const closeMenu = () => {
        setMenuWeb(false);
        setMenuMobile(false);  
    }
    // alert(menuWeb);
    return (
        <>
            {/* MENU MOBILE */}
             { 
                <div className={`aside-dropdown${(menuWeb || menuMobile) ? " aside-dropdown--active" : " aside-dropdown"}`}> 
                    <div className="aside-dropdown__inner">
                        <span className="aside-dropdown__close" onClick={closeMenu}>
                            {/* <svg className="icon">
                                <use xlinkHref="#close"></use>
                            </svg> */}
                            <i className="fa fa-close fa-2x" aria-hidden="true"></i>
                        </span>
                        {/* MENU MOBILE */} 
                        {   (!menuWeb && menuMobile) ?
                            <div className="aside-dropdown__item d-lg-none d-block" id="socials">
                                <ul className="aside-menu"> 
                                    <li className={props.activeHome ? "aside-menu__item aside-menu__item--active" : "aside-menu__item"}>
                                         <NavLink to="/" className="aside-menu__link"> <span>INICIO</span></NavLink>
                                    </li>
                                     <li className={props.activeTableros ? "aside-menu__item aside-menu__item--active" : "aside-menu__item"}> 
                                        <NavLink to="/principal" className="aside-menu__link"> <span>RESULTADOS DE DISCAPACIDAD</span></NavLink>
                                    </li>
                                     <li className={props.activeMapa ? "aside-menu__item aside-menu__item--active" : "aside-menu__item"}>
                                         <NavLink to="/mapa" className="aside-menu__link"> <span>MAPA</span></NavLink>
                                    </li>
                                    <li className={props.activeFisca ? "aside-menu__item aside-menu__item--active" : "aside-menu__item"}> 
                                        <NavLink to="/fiscalizacion" className="aside-menu__link"> <span>FISCALIZACIÓN</span></NavLink>
                                    </li>
                                    <li className={props.activeInstituciones ? "aside-menu__item aside-menu__item--active" : "aside-menu__item"}> 
                                        <NavLink to="/instituciones" className="aside-menu__link"> <span>INSTITUCIONES</span></NavLink>
                                    </li>
                                </ul>

                                <div className="aside-inner"><span className="aside-inner__title">Linea Gratuita</span><a className="aside-inner__link" href="tel:080000151">0800-00151</a></div>
                                <div className="aside-inner"><span className="aside-inner__title">Central Telefónica</span><a className="aside-inner__link" href="tel:6305170">630-5170</a></div>
                                <div className="aside-inner"><span className="aside-inner__title">WhatsApp te Informa</span><a className="aside-inner__link" href="tel:938202248">938202248 / 938719927</a></div>
                                <div className="aside-inner"><span className="aside-inner__title">Atención</span>
                                    <a className="aside-inner__link" href="tel:6305170">Portal de Transparencia</a>
                                    <a className="aside-inner__link" href="tel:6305170">Libro de Reclamaciones</a>
                                </div>
                                <ul className="aside-socials">
                                    <li className="aside-socials__item"><a className="aside-socials__link" href="https://facebook.com/mimp.conadis" target="_blank"><i className="fa fa-facebook fa-2x" aria-hidden="true"></i></a></li>
                                    <li className="aside-socials__item"><a className="aside-socials__link" href="https://twitter.com/Mimpconadis" target="_blank"><i className="fa fa-twitter fa-2x" aria-hidden="true"></i></a></li>
                                    <li className="aside-socials__item"><a className="aside-socials__link" href="https://instagram.com/mimpconadis/" target="_blank"><i className="fa fa-instagram fa-2x" aria-hidden="true"></i></a></li>
                                    <li className="aside-socials__item"><a className="aside-socials__link" href="https://youtube.com/conadis2011" target="_blank"><i className="fa fa-youtube fa-2x" aria-hidden="true"></i></a></li>
                                </ul>
                                <h6 className="footer-copyright text-primary">© 2022 CONADIS <br/> Todos los Derechos Reservados</h6>
                            </div> : ''
                        }
                        {/* MENU WEB */}
                        {   (menuWeb && !menuMobile) ?
                            <div className="aside-dropdown__item" id="socials">
                                <ul className="aside-menu">  
                                    <li className={props.activeHome ? "aside-menu__item aside-menu__item--active" : "aside-menu__item"}>
                                         <NavLink to="/" className="aside-menu__link"> <span>INICIO</span></NavLink>
                                    </li>
                                     <li className={props.activeTableros ? "aside-menu__item aside-menu__item--active" : "aside-menu__item"}> 
                                        <NavLink to="/principal" className="aside-menu__link"> <span>RESULTADOS DE DISCAPACIDAD</span></NavLink>
                                    </li>
                                     <li className={props.activeMapa ? "aside-menu__item aside-menu__item--active" : "aside-menu__item"}>
                                         <NavLink to="/mapa" className="aside-menu__link"> <span>MAPA</span></NavLink>
                                    </li>
                                    <li className={props.activeFisca ? "aside-menu__item aside-menu__item--active" : "aside-menu__item"}> 
                                        <NavLink to="/fiscalizacion" className="aside-menu__link"> <span>FISCALIZACIÓN</span></NavLink>
                                    </li>
                                    <li className={props.activeInstituciones ? "aside-menu__item aside-menu__item--active" : "aside-menu__item"}> 
                                        <NavLink to="/instituciones" className="aside-menu__link"> <span>INSTITUCIONES</span></NavLink>
                                    </li>
                                </ul>
                                <div className="aside-inner"><span className="aside-inner__title">Linea Gratuita</span><a className="aside-inner__link" href="tel:080000151">0800-00151</a></div>
                                <div className="aside-inner"><span className="aside-inner__title">Central Telefónica</span><a className="aside-inner__link" href="tel:6305170">630-5170</a></div>
                                <div className="aside-inner"><span className="aside-inner__title">WhatsApp te Informa</span><a className="aside-inner__link" href="tel:938202248">938202248 / 938719927</a></div>
                                <div className="aside-inner"><span className="aside-inner__title">Atención</span>
                                    <a className="aside-inner__link" href="http://www.transparencia.gob.pe/enlaces/pte_transparencia_enlaces.aspx?id_entidad=13649&id_tema=1&ver=D#.YJVPjrVKiUm" target="_blank">Portal de Transparencia</a>
                                    <a className="aside-inner__link" href="https://reclamos.servicios.gob.pe/?institution_id=45" target="_blank">Libro de Reclamaciones</a>
                                </div>
                                <ul className="aside-socials" >
                                    <li className="aside-socials__item"><a className="aside-socials__link" href="https://facebook.com/mimp.conadis" target="_blank"><i className="fa fa-facebook fa-2x" aria-hidden="true"></i></a></li>
                                    <li className="aside-socials__item"><a className="aside-socials__link" href="https://twitter.com/Mimpconadis" target="_blank"><i className="fa fa-twitter fa-2x" aria-hidden="true"></i></a></li>
                                    <li className="aside-socials__item"><a className="aside-socials__link" href="https://instagram.com/mimpconadis/" target="_blank"><i className="fa fa-instagram fa-2x" aria-hidden="true"></i></a></li>
                                    <li className="aside-socials__item"><a className="aside-socials__link" href="https://youtube.com/conadis2011" target="_blank"><i className="fa fa-youtube fa-2x" aria-hidden="true"></i></a></li>
                                </ul>
                                <h6 className="footer-copyright text-primary">© 2022 CONADIS <br/> Todos los Derechos Reservados</h6>
                            </div> 
                            : ''
                        }
                    </div>
                </div>
            }


            {/* MENU WEB */}
            <div className="top-bar d-none d-lg-block">
                <div className="container-fluid">
                    <div className="row align-items-end">
                        <div className="col-lg-7">
                            <a className="top-bar__link" href="tel:+180012345678">&nbsp;</a>
                            <a className="top-bar__link" href="tel:+18009756511">PLATAFORMA ÚNICA DE LA DISCAPACIDAD</a>
                            <a className="top-bar__link" href="mailto:support@helpo.org">
                            <i className="fa fa-phone" aria-hidden="true"></i> &nbsp;Línea Gratuita - 0800-00151</a>
                        </div>
                        <div className="col-lg-5 text-right">
                            <ul className="socials">
                                {/* <li className="socials__item"><a className="socials__link" href="http://www.transparencia.gob.pe/enlaces/pte_transparencia_enlaces.aspx?id_entidad=13649&id_tema=1&ver=D#.YJVPjrVKiUm" target="_blank">
                                    <i className="fa fa-search" aria-hidden="true"></i> &nbsp;Portal de Transparencia </a></li>
                                <li className="socials__item"><a className="socials__link" href="https://reclamos.servicios.gob.pe/?institution_id=45" target="_blank">
                                    <i className="fa fa-book" aria-hidden="true"></i> &nbsp;Libro de Reclamaciones </a></li> */}
                                {/* <li className="socials__item"><a className="socials__link" href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                <li className="socials__item"><a className="socials__link" href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                <li className="socials__item"><a className="socials__link" href="#"><i className="fa fa-google-plus" aria-hidden="true"></i></a></li>
                                <li className="socials__item"><a className="socials__link" href="#"><i className="fa fa-instagram" aria-hidden="true"></i></a></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <header className="header header--front_3">
                <div className="container-fluid">
                    <div className="row no-gutters justify-content-between">
                        <div className="col-auto d-flex align-items-center">
                            <div className="dropdown-trigger d-none d-sm-block"  onClick={showMenuWeb}>
                                <div className="dropdown-trigger__item"></div>
                            </div>
                            {/* <HeaderMenu/> */}
                            <div className="header-logo">
                                <a className="header-logo__link" href="https://www.conadisperu.gob.pe/" target="_blank">
                                    <img className="header-logo__img" src="img/logo_conadis.png" alt="logo"/>
                                </a>                                 
                            </div>
                        </div>
                        <div className="col-auto">
                            <nav>
                                <ul className="main-menu">  
                                    <li className={props.activeHome ? "main-menu__item main-menu__item--active" : "main-menu__item"}>
                                         <NavLink to="/" className="main-menu__link"> <span>INICIO</span></NavLink>
                                    </li>
                                    <li className={props.activeTableros ? "main-menu__item main-menu__item--active" : "main-menu__item"}> 
                                        <NavLink to="/principal" className="main-menu__link"> <span>RESULTADOS PCD</span></NavLink>
                                    </li>
                                    <li className={props.activeMapa ? "main-menu__item main-menu__item--active" : "main-menu__item"}>
                                         <NavLink to="/mapa" className="main-menu__link"> <span>MAPA</span></NavLink>
                                    </li>
                                    <li className={props.activeFisca ? "main-menu__item main-menu__item--active" : "main-menu__item"}> 
                                        <NavLink to="/fiscalizacion" className="main-menu__link"> <span>FISCALIZACIÓN</span></NavLink>
                                    </li>
                                    <li className={props.activeInstituciones ? "main-menu__item main-menu__item--active" : "main-menu__item"}> 
                                        <NavLink to="/instituciones" className="main-menu__link"> <span>INSTITUCIONES</span></NavLink>
                                    </li>
                                    {/* <li className="main-menu__item"><a className="main-menu__link" href="#"><span>INSTITUCIONES</span></a></li> */}
                                    {/* INSTITUCIONES CERTIFICADORAS DEL SECTOR SALUD Y EDUCACIÓN
                                        1. ESTABLECIMIENTOS DE SALUD
                                        2. CEBE Y PRITE (CENTRO DE EDUCACION BASICA ESPECIAL  - PROGRAMA DE INTERVENCION TEMPRANA)
                                        3. CER (CENTRO ESPECIALIZADO DE REHABILITACION PROFESIONAL Y MODULOS BASICOS DE REHABILITACION PROFESIONAL)
                                        4. INSTITUCIONES CERTIFICADORAS (INSTITUCIONES PRESTADORES DE SERVICIOS DE SALUD CERTIFICADORAS DE LA DISCAPACIDAD)
                                    */}                                   
                                    
                                </ul>
                            </nav>
                        </div>
                        <div className="col-auto d-flex align-items-center">
                            <div className="col-lg-4 text-right" id="socials">
                                {/* <ul className="socials socials__menu">
                                    <li className="socials__item"><a className="socials__link" href="https://facebook.com/mimp.conadis" target="_blank"><i className="fa fa-facebook fa-2x" aria-hidden="true"></i></a></li>
                                    <li className="socials__item"><a className="socials__link" href="https://twitter.com/Mimpconadis" target="_blank"><i className="fa fa-twitter fa-2x" aria-hidden="true"></i></a></li>
                                    <li className="socials__item"><a className="socials__link" href="https://instagram.com/mimpconadis/" target="_blank"><i className="fa fa-instagram fa-2x" aria-hidden="true"></i></a></li>
                                    <li className="socials__item"><a className="socials__link" href="https://youtube.com/conadis2011" target="_blank"><i className="fa fa-youtube fa-2x" aria-hidden="true"></i></a></li>
                                </ul> */}
                                    <div className="socials socials__menu">
                                        <input className="form-control" type="text" placeholder="BUSCAR" style={{width: '160px'}}/>
                                        <button className="btn btn-dark form-control"><i className="fa fa-search"></i></button>
									</div> 
                            </div>
                            <div className="dropdown-trigger d-block d-sm-none">
                                <div className="dropdown-trigger__item" onClick={showMenuMobile}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </> 

    )
}

export default PageHeader

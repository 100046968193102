import Control from "react-leaflet-custom-control";

export function LeyendaMapaCuota() {
    let legend = [];
    legend.push( 
        <div className="card">
            <div className="card-body">
                <table border="0">
                    <tbody>
                        <tr>
                            <td className="font-w900"><strong>LEYENDA</strong></td>
                        </tr>
                        <tr>
                            <td className="leyenda">
                                <img className="leyendaImg" src="https://maps.google.com/mapfiles/ms/micons/green-dot.png"/>
                                <strong> CUMPLIMIENTO (5%) </strong>
                            </td>
                        </tr>
                        <tr>
                            <td className="leyenda">
                                <img className="leyendaImg" src="https://maps.google.com/mapfiles/ms/micons/yellow-dot.png"/>
                                <strong> NO PRESENTARON INFORMACIÓN </strong>
                            </td>
                        </tr>
                        <tr>
                            <td className="leyenda">
                                <img className="leyendaImg" src="https://maps.google.com/mapfiles/ms/micons/red-dot.png"/>
                                <strong> NO CUMPLE (5%) </strong>
                            </td>
                        </tr>                      
                    </tbody>
                </table>
            </div>
        </div>
    );
      

    return (
        <Control position="bottomleft">
             {legend}          
        </Control>
    )
}



// import { MapControl, withLeaflet } from "react-leaflet";
// import L from "leaflet";

// class SedesMapa extends MapControl {
//   createLeafletElement(props) {
//     // console.log(props.sede);
//   }

//   componentDidMount() {
//     const legend = L.control({ position: "bottomleft" });
//     legend.onAdd = () => {
//       const div = L.DomUtil.create("div", "legend");
//       let labels = [];

//         labels.push(
//         '<div class="card">'+
//             '<div class="card-body">'+
//                 '<table>'+
//                     '<tbody>'+
//                         '<tr>'+
//                             '<td class="font-w900"><strong>LEYENDA</strong></td>'+
//                         '</tr>'+
//                         '<tr>'+
//                             '<td>'+
//                                 '<img style="width: 20px; height: 20px" src="https://maps.google.com/mapfiles/ms/micons/green-dot.png">'+
//                                 '<strong> CUMPLIMIENTO (5%) </strong>'+
//                             '</td>'+
//                         '</tr>'+
//                         '<tr>'+
//                             '<td>'+
//                                 '<img style="width: 20px; height: 20px" src="https://maps.google.com/mapfiles/ms/micons/yellow-dot.png">'+
//                                 '<strong> NO PRESENTARON INFORMACIÓN </strong>'+
//                             '</td>'+
//                         '</tr>'+
//                         '<tr>'+
//                             '<td>'+
//                                 '<img style="width: 20px; height: 20px" src="https://maps.google.com/mapfiles/ms/micons/red-dot.png">'+
//                                 '<strong> NO CUMPLE (5%) </strong>'+
//                             '</td>'+
//                         '</tr>   '+                        
//                     '</tbody>'+
//                 '</table>'+
//             '</div>'+
//         '</div>'
//         );
//     //   }
            
//       div.innerHTML = labels.join("<br>");
//       return div;
//     };

//     const { map } = this.props.leaflet;
//     legend.addTo(map);
//     // home.addTo(map);
//   }
// }

// export default withLeaflet(SedesMapa);








// import { MapControl, withLeaflet } from "react-leaflet";
// import L from "leaflet";

// class LeyendaCuotaMapa extends MapControl {
//   createLeafletElement(props) {}

//   componentDidMount() {
//     // get color depending on population density value
//     const getColor = d => {
//       return d > 1000
//         ? "#800026"
//         : d > 500
//         ? "#BD0026"
//         : d > 200
//         ? "#E31A1C"
//         : d > 100
//         ? "#FC4E2A"
//         : d > 50
//         ? "#FD8D3C"
//         : d > 20
//         ? "#FEB24C"
//         : d > 10
//         ? "#FED976"
//         : "#FFEDA0";
//     };

//     // const home = L.control({ position: "bottomleft" });
//     const legend = L.control({ position: "topleft" });

//     legend.onAdd = () => {
//       const div = L.DomUtil.create("div", "info legend");
//       const grades = [0, 10, 20, 50, 100, 200, 500, 1000];
//       let labels = [];
//       let from;
//       let to;

//       for (let i = 0; i < grades.length; i++) {
//         from = grades[i];
//         to = grades[i + 1];

//         labels.push(
//           '<i style="background:' +
//             getColor(from + 1) +
//             '"></i> ' +
//             from +
//             (to ? "&ndash;" + to : "+")
//         );
//       }

//       div.innerHTML = labels.join("<br>");
//       return div;
//     };

//     const { map } = this.props.leaflet;
//     legend.addTo(map);
//     // home.addTo(map);
//   }
// }

// export default withLeaflet(LeyendaCuotaMapa);

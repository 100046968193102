import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Avatar from "@material-ui/core/Avatar";
import {useState,useEffect} from 'react'
import axios from 'axios';
import URL from '../../url.json'
import headers from '../../headers.json'

import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import OutsideClickHandler from 'react-outside-click-handler';
import { TrainOutlined } from '@material-ui/icons';





const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: '#e6282e',
      outline: '1px solid slategrey'
    }
  },
  root: {
    display: 'flex',
  },
  appBar: {
    
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
      
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    
  },
  drawerOpen: {
    width: drawerWidth +50,
    
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) -50,
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(9) + 20,
    },
    
    
  },
  toolbar: {
      
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function BarTypeLimitation(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [datafilter, setdatafilter] = useState([])
  const [Errorfilter, setErrorFilter] = useState(null)
  
 
  
  useEffect(() => {
      getdatafilter()
    
    document.getElementsByClassName("MuiDrawer-paper")[0].style.marginTop = "75px"
    const updateSize = () => {
      const newSize = window.pageYOffset;
      //console.log(newSize)
      
      
      if(newSize===0 ){
        document.getElementsByClassName("MuiDrawer-paper")[0].style.marginTop = "75px"
        
      }else{
        document.getElementsByClassName("MuiDrawer-paper")[0].style.marginTop = "0px"
      }
      
      //console.log(x)
      
    };

    window.addEventListener('scroll', updateSize);

    return () => window.removeEventListener('scroll', updateSize) 

  }, [props.CodeUbigeo]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const clickoutside = () =>{
    if(open){
      handleDrawerClose()
    }
  }
  const getdatafilter = () =>{
    setErrorFilter(null)
    setdatafilter([])
      
      let objectRequest = {
        codubigeo:props.CodeUbigeo === '0' ?  "":props.CodeUbigeo
      }
    
      axios.post(URL.apiURL+'gettiposlimitacion', objectRequest, headers)
      .then(response =>{
        // console.log(response)
        if(response.status===201 ){
          //console.log(response.data.data)
          let data = response.data.data
          if(data.length>0){
            data.forEach(element => {
              if(element.CantitadTotal ===null){
                element.CantitadTotal = 'no hay datos'
              }
            })
            //console.log('sd')
            /*
            let total = 0
            data.forEach(element => {
                total+=element.CantitadTotal
            })
            data.forEach(element=>{
              element.porcentaje = Math.round(((element.CantitadTotal*100)/total))+"%"
            })*/
            //console.log(data)
            setdatafilter(data)
          }
          

        }
        

      })
      .catch(err=> setErrorFilter(err));
  }

  return (
    <OutsideClickHandler 
    
      onOutsideClick={() => {
        handleDrawerClose()
      }}
    >
      
      <div  className={classes.root} >
        <CssBaseline />
        <div class="d-sm-block d-block d-md-block d-lg-none d-xl-none"
          style={{background:'transparent',position:'fixed',backgroundColor:'white',zIndex:'40'}}
          
          
          
        >{!open?
            <div className={classes.toolbar}>
              <IconButton onClick={handleDrawerOpen}>
                <ChevronRightIcon />
              </IconButton>
            </div>
            :<div></div>
          }
          
        </div>
        <Drawer  
          
          variant="permanent"
          onClick={clickoutside}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >{!open?
            <div className={classes.toolbar} >
              <IconButton onClick={handleDrawerOpen}>
                <ChevronRightIcon />
              </IconButton>
            </div>
            :<div></div>
          }
        
          
          <List>
              <ListItem >
              {open?  
                  <div class="row" >
                      <label class="col-12" style={{fontWeight:'bold'}}>Filtrar por tipo de limitación</label>
                     
                  </div>
                :null
              }
              </ListItem>            
              {
                datafilter.map(data=>{
                return <ListItem >
                  <Button title={data.DescipTipoLim==='Todos'?'Todas las limitaciones':data.CantitadTotal === 'no hay datos'? data.DescipTipoLim+': '+ data.CantitadTotal:data.DescipTipoLim+': '+data.CantitadTotal.toLocaleString('en-US', { timeZone: 'UTC' })} variant="contained" style={{backgroundColor:data.DescipTipoLim==='Todos'?'#f9373c':'#fafbfd',height: '60px'}} endIcon={
                    data.DescipTipoLim==='Todos'?<Avatar src={'/img/logo-e16110054289492.png'} alt="Todas las limitaciones" style={{marginLeft:-15, height: '30px', width: '30px' }}  />
                    :data.DescipTipoLim==='De la Comunicación'?<img src={'/img/message.png'} alt="De la comunicación" style={{marginLeft:-15, height: '30px', width: '30px' }}  />
                    :data.DescipTipoLim==='De la Conducta'?<img src={'/img/icon(2).png'} alt="De la conducta" style={{marginLeft:-15, height: '33px', width: '30px' }}  />
                    :data.DescipTipoLim==='Del cuidado personal'?<img src={'/img/Group.png'} alt="Del cuidado personal" style={{marginLeft:-15, height: '30px', width: '30px' }}  />
                    :data.DescipTipoLim==='De la locomoción'?<img src={'/img/icon(1).png'} alt="De la locomoción" style={{marginLeft:-15, height: '40px', width: '30px' }}  />
                    :data.DescipTipoLim==='Disposición corporal'?<img src={'/img/Group(2).png'} alt="Disposición corporal" style={{marginLeft:-15, height: '30px', width: '30px' }}  />
                    :data.DescipTipoLim==='De la destreza'?<img src={'/img/Vector(1).png'} alt="De la destreza" style={{marginLeft:-15, height: '30px', width: '30px' }}  /> 
                    :<img src={'/img/Group(1).png'} alt="De la situación"  style={{marginLeft:-15, height: '30px', width: '20px' }}  />}>
                  </Button>
                  <div class="row" style={{marginLeft:'10px'}}>
                      <label class="col-12" style={{fontWeight:'bold'}}>{data.DescipTipoLim === 'Todos'?'Todas las limitaciones': data.DescipTipoLim}</label>
                      <label>{data.DescipTipoLim!=='Todos' ?'Total:'+ data.CantitadTotal === 'no hay datos'? data.CantitadTotal :data.CantitadTotal.toLocaleString('en-US', { timeZone: 'UTC' }) : '' }</label>
                  </div>
              </ListItem>})}
              {/*
             
              
              
              
              
              <ListItem >
                  <Button variant="outlined" style={{backgroundColor:'#fafbfd',height: '60px'}} endIcon={<img src={'/img/Vector(1).png'} style={{marginLeft:-15, height: '30px', width: '30px' }}  /> }>
                  </Button>
                  <div class="row" style={{marginLeft:'10px'}}>
                      <label class="col-12" style={{fontWeight:'bold'}}>Todas las limitaciones</label>
                      <label>Total: 1456</label>
                  </div>
              </ListItem>
              <ListItem >
                  <Button variant="outlined" style={{backgroundColor:'#fafbfd',height: '60px'}} endIcon={<img src={'/img/Group(1).png'} style={{marginLeft:-15, height: '30px', width: '30px' }}  /> }>
                  </Button>
                  <div class="row" style={{marginLeft:'10px'}}>
                      <label class="col-12" style={{fontWeight:'bold'}}>Todas las limitaciones</label>
                      <label>Total: 1456</label>
                  </div>
              </ListItem>
              */}
          </List>
          
        </Drawer>
        
      </div>
    </OutsideClickHandler>
  );
}

import React, {useState,useEffect} from 'react'
import SimplePopoverHistorial from '../../componentes/popover/popoverhistorial'
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import { Bar } from 'react-chartjs-2';
import { Doughnut} from 'react-chartjs-2';
import '../../dashboard.css';
import '../../AppStyle.css';
//graficos
import ReactHTMLTableToExcel from 'react-html-table-to-excel';




const DistributionAfiliates= (props) => {
    
    const [DistAfiliates, setDistAfiliates] = useState(props.DistAfiliates);
    const [isRegisterTotal, setIsregisterTotal] = useState('true')
    const [option, setOption] = useState(props.filtro)
    console.log(option)
   

    var DateToday = Date.now()
    var Datenow= new Date(DateToday).toLocaleDateString().split('/')
    var DateFinal =  parseInt(Datenow[0])-1 +'/'+parseInt(Datenow[1]) + '/' +parseInt(Datenow[2])    

    //Script mes para MIDIS
    let FechaActual = new Date()
    let mesActual = FechaActual.getMonth()

    const year = new Date(FechaActual.getFullYear(),mesActual-2,1)
    const mes = year.toLocaleString('en-US', { month: 'long' })

    /* Accion que carga al cambiar la eleccion en "Total de Inscritos por Entidad" */
    const ChangeRegisterType = (event)=>{
        console.log(event.target.value)
        setIsregisterTotal(event.target.value)
        //props.filtro(DistAfiliates)
    }

    var colores = ['#f9373c','#0275d8','#28d802','#ff9900','#9900cc'];
    var dLabel = [], dData = [], dColor = [];
    var dHtml = "";    
            
    for (var i =  0; i <= DistAfiliates.length - 1; i++) {
        dLabel[i] = DistAfiliates[i].Institucion;
        dData[i] = DistAfiliates[i].CantidadAfiliados;
        dColor[i] = colores[i];
        dHtml += '<div class="col-3"><span style="margin-top: 30px;height: 25px;width: 25px;border-radius: 50%;display: inline-block;background-color: '+colores[i]+';"></span></div>';
        dHtml += '<div class="col-9"><div class="font-w900">'+dLabel[i] +' </div><label> '+ dData[i].toLocaleString('en-US')+' PERSONAS CON DISCAPACIDAD</label></div>';
    }

    const datoss = {
        //labels: [DistAfiliates[2]!==undefined?DistAfiliates[2].Institucion:'', DistAfiliates[0]!==undefined?DistAfiliates[0].Institucion:'',DistAfiliates[1]!==undefined?'CONTIGO':''],
        labels: dLabel,
        datasets: [
            {
            label: '',
            //data: [DistAfiliates[2]!==undefined?DistAfiliates[2].CantidadAfiliados:0,DistAfiliates[0]!==undefined?DistAfiliates[0].CantidadAfiliados:0,DistAfiliates[1]!==undefined?DistAfiliates[1].CantidadAfiliados:0],
            data: dData,
            backgroundColor: dColor,
            borderColor: dColor,
            borderWidth: 1,
            },
        ],
        };
        const options = {
            indexAxis: 'y',
            // Elements options apply to all of the options unless overridden in a dataset
            // In this case, we are setting the border of each horizontal bar to be 2px wide
            elements: {
                bar: {
                borderWidth: 2,
                },
            },
            responsive: true,
            
            scales:{
                x: {
                    display: true
                }
            },
            plugins: {
                
                title: {
                display: false,
                text: 'Chart.js Horizontal Bar Chart',
                },
                legend: {
                    display: false,
                },
                tooltip: {
                    callbacks: {
                      label: (ttItem) => `${ttItem.label}: ${ttItem.parsed.x}`
                    }
                  }
            },
            };

    const datadistribution = {
        labels: [DistAfiliates[2]!==undefined?DistAfiliates[2].Institucion:'', DistAfiliates[0]!==undefined?DistAfiliates[0].Institucion:'',DistAfiliates[1]!==undefined?DistAfiliates[1].Institucion:''],
        datasets: [
          {
            
            data: [DistAfiliates[2]!==undefined?DistAfiliates[2].PorcentajeAfiliados:0,DistAfiliates[0]!==undefined?DistAfiliates[0].PorcentajeAfiliados:0,DistAfiliates[1]!==undefined?DistAfiliates[1].PorcentajeAfiliados:0],
            backgroundColor: [
              '#f9373c',
              '#0275d8',              
              '#00b347',
              
            ],
            borderColor: [
              '#0275d8',
              '#f9373c',
              '#00b347'
              
            ],
            borderWidth: 1,
          },
        ],
      };
    return (
       
            
            <div >
                        {/* <div class="col-7 labelubicationclassage">
                            <label>Población con discapacidad: Distribución por Entidad</label>
                        </div> */}
                        <div className="row">
                            <div className="container-fluid">
                                <div className="row align-items-end">
                                    <div className="col-lg-7">
                                        <h6 >&nbsp;</h6>
                                    </div>                                                 
                                    <div className="col-lg-5 text-right">
                                        {/* <SimplePopoverHistorial typegraphic={'afiliadosPage'}/> */}
                                        <ReactHTMLTableToExcel
                                            id="descargar-Dafiliados-mapa"
                                            className="btn btn-danger btn-xs"
                                            table="table-Dafiliados"
                                            filename="CONADIS PUD AFILIADOS"
                                            sheet="tablexls"
                                            buttonText="Descargar"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div class="col-5 popoverstylehistorial">
                            <div class="row">
                                <SimplePopoverHistorial typegraphic={'afiliadosPage'}/>
                            </div>
                        </div> */}
                        <div class="row justify-content-center" >
                            <div class="col-12" >
                                <RadioGroup aria-label="gender" name="gender1" value={isRegisterTotal} onChange={ event => {props.onChange(event.target.value);setIsregisterTotal(event.target.value);}}>
                                    <FormControlLabel value={option}  checked={option} control={<Radio />} label="REGISTROS TOTALES" />
                                {/*} <FormControlLabel value={!option} checked={!option} control={<Radio />} label="Brecha de Registros" />*/}
                                </RadioGroup>    
                            </div>
                            <div class="col-12 container-doughnut" > 
                                <Bar data={datoss} options={options} />
                            </div>
                             <div class="col-8 text-content-doughnut margin-top"  >
                                {/* <div >  */}
                                    <div class='row justify-content-center' dangerouslySetInnerHTML={{  __html: dHtml  }}></div>
                                {/* </div> */}
                            </div>
                            {/*<div className="col-12 text-center mt-5" style={{color:'#DF1A1F',marginBottom:'40px'}}>
                                <span><b>TOTAL PCD :</b>XX</span>
                                </div>*/}                            
                            
                        </div>
                    </div>
                
    )
}

export default DistributionAfiliates
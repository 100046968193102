import React, {useEffect,useRef,useState } from 'react'
import axios from 'axios';
import ResumenTablaEducacion from './resumenEducacionMapa';
import mapaPeru from './mapaperu.json' 
import { Map, TileLayer, Marker, Popup, Polygon, GeoJSON, MapControl } from 'react-leaflet'
import MapBoxGLLayer from './MapBoxGLLayer';
//import {LeyendaMapaEducacion} from './leyendaEducacionMapa'; 
import FiltroAnio from './../../componentes/filtroanio'

import 'leaflet/dist/leaflet.css'
import "./cuotaempleoPage.css"
import Spiner from '../../componentes/Spiner';
import MapEdu from './mapaEdu';

const EjeEducacionPage = () => {     
    const [listaSedesEducacion, setListaSedesEducacion] = useState([]); 
    const [educacionShow, setEducacionShow] = useState(true); 

    const [tableData, setTableData] = useState(null)
    const [anio, setAnio] = useState(2021)

    const GeoJSONEL = useRef(null);
    const MapEL = useRef(null);
    const MapBoxEl = useRef(null);

    //const [filtroAnio, setFiltroAnio] = useState(false);

    const onEachFeature = (feature, layer) => {
        layer.on({
        //   mouseover: highlightFeature,
        //   mouseout: resetHighlight,
            click: clickFeature
        });
        layer.bindTooltip(
            "<div><b>" +feature.properties.NOMBDEP +"</b></div>",
            { permanent: true, direction:'center', className: 'countryLabel'}
        ); 
    }
     //setFiltroAnio(true);
    const highlightFeature = (e) => {
        var layer = e.target;
        layer.setStyle({
            weight: 5,
            color: "#4A4C70",
            dashArray: "",
            fillOpacity: 0.7
        });

        layer.bringToFront();    
     }   
    const resetHighlight = (event) => {
        GeoJSONEL.current.leafletElement.resetStyle(event.target);
    } 
    
    const style = (feature) => {
        return ({
            weight: 4,
            opacity: 5,
            fillOpacity: 0.8,
            color:'#4A4C70',
            fillColor:'#bdbbbb'
        });
    }
    function clickFeature({ target }) {
        setTableData(null) 
        target.setStyle({
            weight: 5,
            color: "red",
            dashArray: "",
            fillOpacity: 0.7
        });

        target.bringToFront();
    }
    const MapaEducacion=()=>{
        return <MapEdu listaEdu={listaSedesEducacion} anio={anio}/>            
    }
    const TablaEducacion=()=>{
        if(listaSedesEducacion){
            return <ResumenTablaEducacion listaSedes={listaSedesEducacion}/>            
        }else{
            return <Spiner/>
        }
    }
    
    return <>
       
        <FiltroAnio anios={[2021, 2022]} onChangeAnio={(anio)=>{
            setAnio(anio)
        }} />

        <div className="row margin-top margin-bottom">
            <div className="col-12">
                <div className="row" id="cuota_empleo">
                    <div className="col-md-12 text-center margin-bottom"> 
                        <div className="card">
                            <div className="card-header ">
                                <h6 className="card-text text-primary font-w900">INSTITUCIONES EDUCATIVAS FISCALIZADAS ANTE EL CUMPLIMIENTO EN MATERIA DE DISCAPACIDAD</h6>
                            </div> 
                        </div>
                    </div> 
                        
                    <div className="col-md-12 text-center">
                        {educacionShow ?
                        <div className="card">
                            <div className="card-header ">
                                <p className="card-text text-dark font-w900">INSTITUCIONES FISCALIZADAS A NIVEL NACIONAL</p>
                               </div>
                            <div className="card-body">
                                {MapaEducacion()}                                
                            </div> 
                        </div>
                        : ''}

                    </div>
                    

                    {/* <div className="col-md-7 text-center">
                        {educacionShow ? 
                        <div className="card">
                            <div className="card-header">
                                <p className="card-text text-dark font-w900">INSTITUCIONES EDUCATIVAS FISCALIZADAS ANTE EL CUMPLIMIENTO EN MATERIA DE DISCAPACIDAD</p>
                            </div>
                            <div className="card-body">
                                {TablaEducacion()}                                                
                            </div> 
                        </div>
                        : ''}
                    </div>  */}
                </div>
            </div>
        </div>
        
    </>
}

export default EjeEducacionPage
import React from 'react'

const ReporteDGravedad = (props) => {
    var DateToday = Date.now()
    var Datenow= new Date(DateToday).toLocaleDateString().split('/')
    let DateFinal =  parseInt(Datenow[0])-1 +'/'+parseInt(Datenow[1]) + '/' +parseInt(Datenow[2])
    let numberRow = props.registros.length+1
    return (
        <div style={{display:"none"}}>
        <table className="tableMapa" id="table-Dgravedad">

                <thead>
                    <tr >
                        <th >Sexo</th>
                        <th >Gravedad</th>
                        <th >Total de la región</th>
                        <th >Porcentaje de la región</th>
                        <th >Rango</th>
                        <th rowSpan={numberRow}>{props.region+', ' +props.provincia+', ' +props.distrito}</th>

                    </tr>
                </thead>

                <tbody>

                    {
                        props.registros.map((x,index)=>{

                            if(index!==-1){
                                return <tr key={index}>
                                <td >{x.Sexo} <div className="maptable-line"></div></td>
                                <td >{x.NivelGravedad} <div className="maptable-line"></div></td>
                                <td >{x.Cantidad} <div className="maptable-line"></div></td>
                                <td >{x.porcentaje} <div className="maptable-line"></div></td>
                                <td >{props.rango} años <div className="maptable-line"></div></td>
                                </tr>
                            }
                            return

                        })


                    }

                    <td colSpan="4">Fuente: CONADIS {DateFinal}, MINSA al 31/12/2021 y CONTIGO a 31/12/2021</td>
                </tbody>

                </table>
        </div>
    )
}

export default ReporteDGravedad
import React, {useState,useEffect} from 'react'

import '../../dashboard.css';

//graficos


const DataNSEComponent= (props) => {

    const [DataNSE, setDataNSE] = useState(props.DataNSE);
    //console.log(DataNSE)
    //console.log(LevelDanger)
    return (
        <div>
            {
                DataNSE.map(data=>{
                   return <div class="row" style={{marginTop:'10px'}} >
                        <div class="col-12">
                            <label style={{marginRight:'5px'}}>{data.nivel} ({data.percentage})</label>
                        </div>
                        <div class="col-11" >
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" style={{width:data.percentage,backgroundColor:'#f9373c'}}>
                                </div>
                            </div>
                        </div>
                        <div class="col-1">
                            <label>{data.total.toLocaleString('en-US', { timeZone: 'UTC' })}</label>
                            
                        </div>
                        
                    </div>
                })
                
            }
            <label style={{marginTop:'20px'}}>Legenda: Conadis y CONTIGO</label>
            
            
                            
                            
        </div>
    )
}

export default DataNSEComponent
import React, {useEffect,useRef,useState } from 'react'
import axios from 'axios';
// import ResumenTablaEmpleo from './resumenCuotaMapa';
import mapaPeru from './mapaperu.json' 
import { Map, TileLayer, Marker, Popup, Polygon, GeoJSON, MapControl } from 'react-leaflet'
import MapBoxGLLayer from './MapBoxGLLayer';

import URL from '../../url.json'
import headers from '../../headers.json'

import 'leaflet/dist/leaflet.css'
import "./cuotaempleoPage.css"
import '../../AppStyle.css';
import Spiner from '../../componentes/Spiner';
import { LeyendaMapaPoblacion } from './leyendaPoblacion';
import ReporteTable from './reporteTable';
import InformacionMapa from './InformacionMapa';

const MapPoblacion = (props) => { 
    // TODO MAPA    
    const [listaSedesCuota, setListaSedesCuota] = useState([]); 
    // const [cuotaShow, setCuotaShow] = useState(true); 
    const [isActive, setIsActive] = useState(false);
    const [isPoblacion, setPoblacion] = useState([]);
    const GeoJSONEL = useRef(null);
    const MapEL = useRef(null);
    const MapBoxEl = useRef(null);
    // const MapInfoEl = useRef(null);

    // PARA TABLA - BUSQUEDAS
    const [IdSeleccionado, setIdSeleccionado] = useState("160000")
    const [reporteShow, setReporteShow] = useState(false);
    const [tableData, setTableData] = useState(null)
    const [tableError, setTableError] = useState(null)
    const [FiltrosA, setFiltrosA] = useState([]);
    //PARA INFORMACIÓN
    
    const [tiposFiltros, setTFiltros] = useState([
        {
            tipo:"limitacion",
            opciones:["todas","De la comunicación","De la conducta", "Del cuidado personal","De la locomoción","Disposición corporal","De la destreza","De la situación"]
        },
        {
            tipo:"edad",
            opciones:["todas", "-18","18 - 24", "25 - 34" , "35 - 44", "45 - 54", "55 - 64", "+65"]
        },
        {
            tipo:"sexo",
            opciones:["todas", "Hombres","Mujeres"]
        },
        {
            tipo:"gravedad",
            opciones:["todas","Leve", "Moderado","Severo","Sin especificar"]
        },
        {
            tipo:"certificacion",
            opciones:["todas","Certificados"]
        },
        {
            tipo:"afiliacion",
            opciones:["todas","Conadis", "Minsa","Inei"]
        },
        {
            tipo:"Estado laboral",
            opciones:["todas","Empleado", "Desempleado"]
        },
        {
            tipo:"Nivel Socieconomico",
            opciones:["todas","NSE A","NSE B","NSE C","NSE D","NSE E"]
        }
        
    ]);



    useEffect(() => {
        setListaSedesCuota(null);
        const timer = setTimeout(() => {
            getAPICuotaEmpleo();
          }, 200);
        return () => clearTimeout(timer);
      }, []);

    //   useEffect(() => { 
    //     busquedaSimple(1) 
    //   }, [IdSeleccionado]);

    // TODO FILTRO - BUSQUEDA
    const changeID=(id)=>{
        setIdSeleccionado(id.substring(0,2)+'0000')
    }
    const addFiltro= async (tipo, nombre)=>{        
        var auxiliar=FiltrosA
        var filtrosNew= auxiliar.filter(x=> x.id>0)
        
        if(nombre!=="todas"){
            
            if(FiltrosA.filter(x=>x.tipo===tipo && x.nombre===nombre).length===0 ){
                var id= FiltrosA.length===0 ? 1 : FiltrosA[FiltrosA.length-1].id+ 1
                var newFiltro={
                    id:id ,
                    tipo:tipo,
                    nombre:nombre
                }
                filtrosNew.push(newFiltro)
                setFiltrosA(filtrosNew)
            } else{                
                filtrosNew= filtrosNew.filter(x=>x.tipo===tipo && x.nombre!==nombre)
                setFiltrosA(filtrosNew)
            }
        }
        else{
            var tiposNew = tiposFiltros.filter(x=>x.tipo===tipo )[0]
            var tiposA= FiltrosA.filter(x=>x.tipo===tipo)
            var resultadoTipos
            resultadoTipos= filtrosNew.filter(x=>x.tipo!== tipo)
            if(tiposNew.opciones.length-1 === tiposA.length){
                setFiltrosA(resultadoTipos)
            }
            else{
                for (let index = 0; index < tiposNew.opciones.length; index++) {                    
                    if(tiposNew.opciones[index]!=="todas"){
                        var id= FiltrosA.length===0 ? 1 : FiltrosA[FiltrosA.length-1].id+ 1 
                        id=id +index
                        var objeto={
                            id:id,
                            tipo:tipo,
                            nombre: tiposNew.opciones[index]
                        }    
                        resultadoTipos.push(objeto)
                    }                   
                }
                setFiltrosA(resultadoTipos)
            }
        }
    }

    const deleteFiltro=(id)=>{
        var newFiltros= FiltrosA.filter(x=> x.id!==id)
        setFiltrosA(newFiltros)
    }

    const deleteAllFiltros=()=>{
        setFiltrosA([])
    }

    const busquedaSimple=(id)=>{
        setTableData(null)
        setTableError(null)
        setTableData(id);
        // var objetoRequest={
        //     codubigeo: id+'0000',//IdSeleccionado,
        //     age_from: 0,
        //     age_to:999,
        //     sexo:"",
        //     lconducta:0,//1
        //     lcomunicacion:0,//1
        //     lcuidado:0,//1
        //     llocomocion:0,//1
        //     lcorporal:0,//1
        //     ldestreza:0,//1
        //     lsituacion:0,//1
        //     gravedad:"",
        //     certificado:0    
        // }

        // axios.post(URL.apiURL+'mapa_tabla_filter', objetoRequest,headers)
        // .then(response => {
        //     setTableData(response.data);
        //     setReporteShow(false)
        // })
        // .catch(err=> { setTableError(err); });
    }

    const renderTable=()=>{
        if(tableData == null){ return <Spiner /> }
        else{ return <ReporteTable registros={tableData}></ReporteTable> }
    }

    const armarRequest=()=>{
        var conducta= FiltrosA.filter(x=>x.nombre==="De la conducta").length>0 ? 1 : 0
        var comunicación=FiltrosA.filter(x=>x.nombre==="De la comunicación").length>0 ? 1 : 0
        var cuidado=FiltrosA.filter(x=>x.nombre==="Del cuidado personal").length>0 ? 1 : 0
        var locomocion=FiltrosA.filter(x=>x.nombre==="De la locomoción").length>0 ? 1 : 0
        var corporal=FiltrosA.filter(x=>x.nombre==="Disposición corporal").length>0 ? 1 : 0
        var destreza=FiltrosA.filter(x=>x.nombre==="De la destreza").length>0 ? 1 : 0
        var situacion=FiltrosA.filter(x=>x.nombre==="De la situación").length>0 ? 1 : 0
        var from=0
        var to=0
        var sexo=""
        var gravedad=""
        var certificado=FiltrosA.filter(x=>x.nombre==="Certificados").length>0 ? 1 : 0

        var sexoarr=FiltrosA.filter(x=>x.tipo==="sexo")

        if(sexoarr.length>0){
            if(sexoarr.length>1){sexo=""}
            else {sexo= sexoarr[0].nombre==="Hombres"? "M" : "F"}
        }

        var edadarr=FiltrosA.filter(x=>x.tipo==="edad")
        if(edadarr.length>0){
            if(FiltrosA.filter(x=>x.nombre==="+65").length>0){from=65}
            if(FiltrosA.filter(x=>x.nombre==="55 - 64").length>0){from=55}
            if(FiltrosA.filter(x=>x.nombre==="45 - 54").length>0){from=45}
            if(FiltrosA.filter(x=>x.nombre==="35 - 44").length>0){from=35}
            if(FiltrosA.filter(x=>x.nombre==="25 - 34").length>0){from=25}
            if(FiltrosA.filter(x=>x.nombre==="18 - 24").length>0){from=18}
            if(FiltrosA.filter(x=>x.nombre==="-18").length>0){from=0}
            if(FiltrosA.filter(x=>x.nombre==="-18").length>0){to=18}
            if(FiltrosA.filter(x=>x.nombre==="18 - 24").length>0){to=24}
            if(FiltrosA.filter(x=>x.nombre==="25 - 34").length>0){to=34}
            if(FiltrosA.filter(x=>x.nombre==="35 - 44").length>0){to=44}
            if(FiltrosA.filter(x=>x.nombre==="45 - 54").length>0){to=54}
            if(FiltrosA.filter(x=>x.nombre==="55 - 64").length>0){to=64}
            if(FiltrosA.filter(x=>x.nombre==="+65").length>0){to=99}
        }

        var gravedadarr=FiltrosA.filter(x=>x.tipo==="gravedad")
        if(gravedadarr.length>0){
            if(FiltrosA.filter(x=>x.nombre==="Sin especificar").length>0){gravedad="0"}
            if(FiltrosA.filter(x=>x.nombre==="Leve").length>0){gravedad="1"}
            if(FiltrosA.filter(x=>x.nombre==="Moderado").length>0){gravedad="2"}
            if(FiltrosA.filter(x=>x.nombre==="Severo").length>0){gravedad="3"}
        }

        return {
            codubigeo: IdSeleccionado,
            age_from: from,
            age_to:to,
            sexo:sexo,
            lconducta:conducta,
            lcomunicacion:comunicación,
            lcuidado:cuidado,
            llocomocion:locomocion,
            lcorporal:corporal,
            ldestreza:destreza,
            lsituacion:situacion,
            gravedad:gravedad,
            certificado:certificado
        }
    }

    const busquedaCompleja=()=>{
        setTableData(null)
        setTableError(null)
        var objetoRequest=armarRequest()
        axios.post(URL.apiURL+'mapa_tabla_filter', objetoRequest,headers)
        .then(response => {
            setTableData(response.data) 
        })
        .catch(err=> { setTableError(err); console.log("este->",err)});
    }
    // TODO FILTRO - BUSQUEDA FIN

    // TODO MAPA
    const getAPICuotaEmpleo=()=>{
        axios.get(`https://fiscalizacion.conadisperu.gob.pe/api/getMonitoreoCuota/`).then(response => {
            setListaSedesCuota(response.data);
        }).catch(function(error) {
            console.log(error);
        });
    }
    const onEachFeature = (feature, layer) => {
        layer.on({
        //   mouseover: highlightFeature,
        //   mouseout: resetHighlight,
          click: clickFeature.bind(this)
        //   click: zoomToFeature
        });
        layer.bindTooltip(
            "<div><b>" +feature.properties.NOMBDEP +"</b></div>",
            { permanent: true, direction:'center', className: 'countryLabel'}
        ); 
    }
    const highlightFeature = (e) => {
        var layer = e.target;
        layer.setStyle({
            weight: 5,
            color: "red",
            dashArray: "",
            fillOpacity: 0.7
        });
        layer.bringToFront();    
        // MapInfoEl.current.setInfo(layer.feature.properties);
    }   
    const resetHighlight = (event) => {
        GeoJSONEL.current.leafletElement.resetStyle(event.target);
        // MapInfoEl.current.setInfo();
    }
    function clickFeature({ target }) {
        setTableData(null)
        setReporteShow(true)
        setPoblacion(target.feature.properties)    
        // // busquedaSimple(target.feature.properties.FIRST_IDDP);   
        setTableData(target.feature.properties.FIRST_IDDP); 
        
        target.setStyle({ 
          weight: 5,
          color: '#C60701',
          fillOpacity: 1,
        //   fillColor: getColor(target.feature.properties),
        });
        
        // busquedaSimple(target.feature.properties.FIRST_IDDP);
        target.bringToFront();
    }
    const style = (feature) => {
        return ({
            weight: 4,
            opacity: 5,
            fillOpacity: 0.8,
            color:'#4A4C70',
            fillColor:'#bdbbbb'
            // fillColor:getColor(feature.properties, listaSedesCuota),
        });
    }   
    const MapaPoblacion=()=>{
        if(listaSedesCuota){
            return( 
                <Map  
                    ref={MapEL} 
                    center={[-9.378835, -74.906473]} 
                    zoom={6} 
                    maxZoom= {10}
                    minZoom= {6}
                    doubleClickZoom={false}
                    scrollWheelZoom={false}
                    attributionControl={false}
                    // zoomControl={false}
                    // dragging={false}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
                    />
                    <MapBoxGLLayer
                        ref={MapBoxEl} 
                        accessToken={'pk.eyJ1IjoieGN1bGxvYXgiLCJhIjoiY2wyZndoeHR1MDBuNTNsbHIwMzc5MHVlciJ9.5_UMSagakftvT7JmY7hc8w'}
                    />
                    <GeoJSON ref={GeoJSONEL}  data={mapaPeru.features} style={style} onEachFeature={onEachFeature} />
                    <LeyendaMapaPoblacion/>
                </Map>
            ) 
        } else{
            return <Spiner/>
        }
    }
    // FIN TODO MAPA

    return (
        <div className="col-12">
            <div className="row">                       
                <div className="col-lg-6 text-center">
                     
                    <div className="card">
                        {/* <div className="card-header ">
                            <p className="card-text text-primary font-w900">INSTITUCIONES FISCALIZADAS A NIVEL NACIONAL</p>
                        </div> */}
                        <div className="card-body">
                            {MapaPoblacion()}                                                            
                        </div> 
                    </div>
                     
                </div>
                <div className="col-lg-6 text-center">
                    <div className="row margin-bottom">
                        <div className="col-12">
                            <div className="heading heading--primary heading--center">
                                { (isPoblacion['NOMBDEP'] != null) ? 
                                    <div>
                                        <h5 className="heading__title no-margin-bottom text-primary"><span> {isPoblacion['NOMBDEP']}</span> </h5>
                                        <h4 className="no-margin-bottom text-dark"><span> SEGÚN NIVELES DE GRAVEDAD</span></h4>
                                    </div>
                                    
                                    :  <h5 className='text-primary'> SELECCIONE UNA REGIÓN EN EL MAPA PARA VER MÁS INFORMACIÓN</h5>
                                }
                            </div> 
                            {/* { tableData ?  <InformacionMapa registros={tableData} sede={isPoblacion['NOMBDEP']}></InformacionMapa> : '' } */}
                        </div>
                         { tableData ?  <InformacionMapa registros={tableData+'0000'} sede={isPoblacion['NOMBDEP']}></InformacionMapa> : '' }
                    </div>

                    { tableData ?
                        <div className="row"> 
                            <div className="col-12 ">
                                {/* <div className="heading heading--primary heading--center">
                                    <h5 className="heading__title no-margin-bottom"><span>POBLACIÓN DE</span> <span> {isPoblacion['NOMBDEP']}</span></h5>
                                </div> */}
                                <div className="card">
                                    <div className="card-header text-center">
                                        <h4 className="card-text text-primary font-w900"> RESULTADOS SEGÚN REGIÓN</h4>
                                    </div>
                                    <div className="card-body">
                                        {/* { renderTable() }*/}
                                        {<ReporteTable registros={tableData}></ReporteTable>}
                                    </div> 
                                </div>
                            </div>
                        </div>                 
                        : ''
                    }
                </div>
            </div>
        </div>
    )
}

export default MapPoblacion
import React, {useState,useEffect} from 'react'
import SimplePopoverHistorial from '../../componentes/popover/popoverhistorial'
import '../../dashboard.css';

//graficos


const DistributionLevelGravity= (props) => {

    const [LevelDanger, setLevelDanger] = useState(props.LevelDanger);
    var DateToday = Date.now()
    var Datenow= new Date(DateToday).toLocaleDateString().split('/')
    var DateFinal =  parseInt(Datenow[0])-1 +'/'+parseInt(Datenow[1]) + '/' +parseInt(Datenow[2])
    //console.log(LevelDanger)
    return (
        <div>
            {LevelDanger.map((value, key) => {
                    return <div key={key} class="row" style={{marginTop:'10px'}} >
                        <div class="col-12">
                            <label style={{marginRight:'5px'}}>{value.NivelGravedad + ": "+value.Cantidad.toLocaleString('en-US', { timeZone: 'UTC' }) +' ('+value.porcentaje+')'}</label>
                        </div>
                        <div class="col-12" >
                            <div class="progress" title={value.NivelGravedad + ": "+value.Cantidad.toLocaleString('en-US', { timeZone: 'UTC' }) +' ('+value.porcentaje+')'}>
                                <div class="progress-bar" role="progressbar" style={{width:value.porcentaje == "0%" ? "1%" : value.porcentaje,backgroundColor:'#f9373c'}}>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                })
            }              
                            
        </div>
    )
}

export default DistributionLevelGravity
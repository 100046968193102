 
import React, {useEffect,useRef,useState } from 'react'
import axios from 'axios';
import '../AppStyle.css';
import PageHeader from '../componentes/header';
import MapPoblacion from './mapaPoblacion/mapaPoblacion';
import SliderPrincipal from '../componentes/SliderPrincipal';

const PageMapa = (props) => {
    const [cuotaShow, setCuotaShow] = useState(true); 
    const [isActive, setIsActive] = useState(false);

    return (
        <>
         <PageHeader activeMapa={true}/>
             <SliderPrincipal/>

             <div className="margin-top"> 
                <div className="container_extra">
                    <div className="row margin-bottom ">
                        <div className="col-12"> 
                            <div className="heading heading--primary heading--center"><span className="heading__pre-title"></span>
                                <h2 className="heading__title no-margin-bottom text-primary"><span>MAPA DE LA POBLACIÓN PCD DEL PERÚ</span></h2>
                            </div>
                        </div>
                    </div> 
                    <div className="row margin-bottom">
                        {cuotaShow ? <MapPoblacion/> : ''}
                    </div>
                </div>
            </div>
            {/* </section> */}
        </>
    )
}

export default PageMapa
    
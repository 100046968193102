
import React, {useState,useEffect,useRef} from 'react'
import ReactHtmlTableToExcel from 'react-html-table-to-excel' 
import CurrencyFormat from 'react-currency-format';

import "./cuotaempleoPage.css"

// const ResumenTablaEmpleo = (props) => {
 function ResumenTablaEducacion (props) {
    const [dataEducacion, setDataEducacion] = useState(props.listaSedes);
    const [showTable, setShowTable] = useState(false);
    const tableRefHide = useRef(null);
    useEffect(() => {
        setDataEducacion(props.listaSedes);
        setShowTable(false)
    }, [props.listaSedes]);

    return(
        <div className="table-responsive">
            <div className="col text-right" style={{marginBottom: '5px'}}> 
                <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-danger"
                    table="tblCuota"
                    filename="CONADIS - EJE EDUCACIÓN"
                    sheet="EJE EDUCACIÓN"
                    buttonText="Descargar Información"
                /> 
            </div> 
            <table id="tblCuota" border="1" className="table table-striped table-bordered">
                <thead>
                    <tr className='tablita'>
                        <th colSpan= "8"> INSTITUCIONES EDUCATIVAS FISCALIZADAS ANTE EL CUMPLIMIENTO EN MATERIA DE DISCAPACIDAD</th> 
                    </tr>
                    <tr className='tablita'> 
                        <th rowSpan= "2">ENTIDADES</th>
                        <th rowSpan= "2">TOTAL DE INSTITUCIONES EDUCATIVAS FISCALIZADAS</th>
                        <th colSpan= "6">INSTITUCIONES EDUCATIVAS FISCALIZADAS</th>
                    </tr>
                    <tr className='tablita'>  
                        <th>INSTITUCIÓN EDUCATIVA</th>
                      {/*   <th>%</th> */}
                        <th>CETPRO</th>
                        {/* <th>%</th> */}
                        <th>UNIVERSIDADES</th>
                      {/*   <th>%</th> */}
                    </tr>
                </thead>
                <tbody style={{fontSize: '14px'}}>
                    {
                        dataEducacion.map((x,index)=>{                           
                            return( 
                                <tr key={index}>
                                    <td class="colorNegrita" >{x.entidad}</td>
                                    <td class="colorNegrita ">{x.tot_ef || 0}</td>
                                    <td class="colorNegrita ">{x.tot_inst || 0}</td>
                                  {/*   <td class="colorNegrita ">{parseFloat(x.porc_inst || 0).toFixed(2)}</td> */}
                                    <td class="colorNegrita ">{x.tot_cetpro || 0}</td> 
                                   {/*  <td class="colorNegrita ">{parseFloat(x.porc_cetpro || 0).toFixed(2)}</td>  */}
                                    <td class="colorNegrita ">{x.tot_univ || 0}</td>
                                   {/*  <td class="colorNegrita ">{parseFloat(x.porc_univ || 0).toFixed(2)}</td> */}
                                </tr>
                            )
                        })
                    }
                </tbody>
                <tfoot>
                    <tr className='tablita'>
                        <th colSpan= "8" className="colorNegritaLeft">
                            1/ Comprende los 43 Distritos de la provincia de Lima.<br/>
                            Nota: En los Ministerios de Energía y Minas, Defensa y Ministerio d ela Mujer y Poblaciones Vulnerbles se  procederá a realizar una fiscalización de gabinete, considerando la informacion del año  2021. <br/>
                            Fuente: Sub Dirección de Fiscalización - CONADIS<br/>
                           {/*  Fecha de Corte: 31/12/2021 */}
                        </th> 
                    </tr>
                </tfoot>
            </table>
        </div>     
    );
    
}
export default ResumenTablaEducacion;
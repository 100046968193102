import React, {useEffect,useRef,useState } from 'react'
// import '../AppStyle.css';
import PageHeader from '../componentes/header';
import SliderPrincipal from '../componentes/SliderPrincipal';
import {
    LoadScript, 
    useJsApiLoader,
    GoogleMap,
    Marker,
    StreetViewPanorama, 
    Autocomplete,
    DirectionsRenderer
} from '@react-google-maps/api';

const PageContacto = (props) => {
    const [map, setMap] = useState(null)
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyD5ES8GFHrarPhIVpDhFDea6fPtga0Wy4Y"
    })
    // const onLoad = React.useCallback(function callback(map) {
    //     const bounds = new window.google.maps.LatLngBounds(center);
    //     map.fitBounds(bounds);
    //     setMap(map)
    //   }, [])
    
    //   const onUnmount = React.useCallback(function callback(map) {
    //     setMap(null)
    //   }, [])


      const containerStyle = {
        width: '100%',
        height: '60vh'
      };
      
      const center = {
        lat: -12.067540006131976,
        lng: -77.03678931750915
      };
    return (
        <>
            <PageHeader activeContacto={true}/>
            {/* <SliderPrincipal/>        */}
            <section className="section contacts">
                <div className="container">
                    <div className="row offset-margin">
                        <div className="col-sm-6 col-lg-3">
                            <div className="icon-item">
                                <div className="icon-item__img"><img className="img--layout" src="img/icon_1-1.png" alt="img"/>
                                    <svg className="icon icon-item__icon icon--red">
                                        <use xlinkHref="#location-pin"></use>
                                    </svg>
                                </div>
                                <div className="icon-item__text">
                                    <p>Sede Central<br/> 
                                        <a className="icon-item__link text-primary" href="#">Av. Arequipa 375 – Lima, Perú</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="icon-item">
                                <div className="icon-item__img"><img className="img--layout" src="img/icon_2-2.png" alt="img"/>
                                    <svg className="icon icon-item__icon icon--orange">
                                        <use xlinkHref="#phone-call"></use>
                                    </svg>
                                </div>
                                <div className="icon-item__text">
                                    <p>Linea Gratuita<br/> 
                                        <a className="icon-item__link text-primary" href="tel:+31859644725">0800-00151</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="icon-item">
                                <div className="icon-item__img"><img className="img--layout" src="img/icon_3-3.png" alt="img"/>
                                    <svg className="icon icon-item__icon icon--green">
                                        <use xlinkHref="#envelope"></use>
                                    </svg>
                                </div>
                                <div className="icon-item__text">
                                    <p>Central Telefónica<br/>
                                        <a className="icon-item__link text-primary" href="tel:0800-00151">(01) 630 5170</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="icon-item">
                                <div className="icon-item__img"><img className="img--layout" src="img/icon_4-4.png" alt="img"/>
                                    <svg className="icon icon-item__icon icon--blue">
                                        <use xlinkHref="#share"></use>
                                    </svg>
                                </div>
                                <div className="icon-item__text" id="socials">
                                     <ul className="socials">
                                        <li className="socials__item"><a className="socials__link" href="https://facebook.com/mimp.conadis" target="_blank"><i className="fa fa-facebook fa-2x" aria-hidden="true"></i></a></li>
                                        <li className="socials__item"><a className="socials__link" href="https://twitter.com/Mimpconadis" target="_blank"><i className="fa fa-twitter fa-2x" aria-hidden="true"></i></a></li>
                                        <li className="socials__item"><a className="socials__link" href="https://instagram.com/mimpconadis/" target="_blank"><i className="fa fa-instagram fa-2x" aria-hidden="true"></i></a></li>
                                        <li className="socials__item"><a className="socials__link" href="https://youtube.com/conadis2011" target="_blank"><i className="fa fa-youtube fa-2x" aria-hidden="true"></i></a></li>
                                    </ul>
                                 </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="section contacts margin-bottom"> 
                <div className="container_extra"> 
                              
                    {/* <div id="map"
                        data-api-key="AIzaSyD5ES8GFHrarPhIVpDhFDea6fPtga0Wy4Y" 
                        data-longitude="-12.067540006131976" data-latitude="-77.03678931750915" 
                        data-marker="img/marker.png" style={{
                            width: '100%',
                            height: '100vh',
                            position:'sticky !important'
                        }}>
                        
                    </div> */}
                    <LoadScript googleMapsApiKey={'AIzaSyD5ES8GFHrarPhIVpDhFDea6fPtga0Wy4Y'}>

                            <GoogleMap
                                center={center}
                                zoom={15}
                                mapContainerStyle={containerStyle}
                                options={{
                                    zoomControl: true,
                                    streetViewControl: true,
                                    mapTypeControl: true,
                                    fullscreenControl: true,
                                }}
                                onLoad={map => setMap(map)}
                                >
                                <Marker position={center} /> 
                                {/* <StreetViewPanorama position={center} visible={true}/> */}
                                {/* <StreetViewControl visible={true}/> */}
                            </GoogleMap>
                        </LoadScript>
                       
                                						    
					     
                </div>
            </div>
        </>
    )
}
export default React.memo(PageContacto)

// export default PageContacto
import React, {useState,useEffect} from 'react'
import axios from 'axios';
import URL from '../../url.json'
import headers from '../../headers.json'

import '../../AppStyle.css';
import Spiner from '../../componentes/Spiner';
const ReporteTable = (props) => {
    // let listResgistros = props.registros;
    const [tableData, setTableData] = useState([])
    const [reporteShow, setReporteShow] = useState(false);
    // const [registros, setRegistros] = useState(listResgistros.lenght>0 ? listResgistros : []);
    // const [windowsStatus, setWindowsStatus] = useState([]);
    var DateToday = Date.now()
    var Datenow= new Date(DateToday).toLocaleDateString().split('/')
    let DateFinal =  (parseInt(Datenow[0])-1 === 0 ? 1 : parseInt(Datenow[0])-1)+'/'+parseInt(Datenow[1]) + '/' +parseInt(Datenow[2])
    // useEffect(() => {
    //     setRegistros(listResgistros.lenght>0 ? listResgistros : [])
    // }, [props]);

    useEffect(() => { 
        const timer = setTimeout(() => {
            getInfoPoblacion(props.registros);
          }, 100);
        return () => clearTimeout(timer);
    }, []);

    function getInfoPoblacion(id){ 
        var objetoRequest={
            codubigeo: id+'0000',//IdSeleccionado,
            age_from: 0,
            age_to:999,
            sexo:"",
            lconducta:0,//1
            lcomunicacion:0,//1
            lcuidado:0,//1
            llocomocion:0,//1
            lcorporal:0,//1
            ldestreza:0,//1
            lsituacion:0,//1
            gravedad:"",
            certificado:0    
        }

        axios.post(URL.apiURL+'mapa_tabla_filter', objetoRequest,headers)
        .then(response => {
            setTableData(response.data);
            setReporteShow(true)
        }).catch(err=> {  });
    }

     return (
        <>
            {reporteShow ?
            <div className="table-responsive">
                <table border="1" className="table table-striped table-bordered " >
                    <thead>
                        <tr className='tablita'> 
                            <th>REGIÓN</th>
                            <th>PROVINCIA</th>
                            <th>DISTRITO</th>
                            <th>TOTAL DE PCD</th>
                            <th>EDAD PROMEDIO</th> 
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tableData.map((x,index)=>{                           
                                return( 
                                    <tr key={index}>                                         
                                        <td className="colorNegrita" >{x.REGION}</td>  
                                        <td className="colorNegrita" >{x.PROVINCIA}</td>
                                        <td className="colorNegrita" >{x.DISTRITO}</td>
                                        <td className="colorNegrita" >{x.TOTAL}</td>
                                        <td className="colorNegrita" >{x.EDAD}</td> 
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                    <tfoot>
                        <tr className='tablita'>
                            <td  colSpan="5">* Fuente: Registros Únicos de la Base de Datos de CONADIS, MINSA al {DateFinal} y MIDIS a Junio 2021</td>
                        </tr>
                    </tfoot>
                </table>
            </div> : <Spiner/>}  
        </>   
    )
}

export default ReporteTable
import React, {useState,useEffect} from 'react'
import axios from 'axios';
import headers from '../../headers.json'
import URL from '../../url.json'

//estilos
// import './informacionMapa.css'

//graficas
// import SimpleVariado from '../../graficos/simpleVariado/simpleVariado'
// import TipoLimitacion from '../../graficos/tipoLimitacion/tipoLimitacion'
import Spiner from '../../componentes/Spiner';

//loading
// import LoadingComponent from '../../componentes/loadingComponent/loadingComponent'

const InformacionMapa = (props) => {
    const [reporteShow, setReporteShow] = useState(false);
    const [NivelGravedad, setNivelGravedad] = useState([]);

    useEffect(() => {
        cargarNivelGravedad();
    }, []);

    function cargarNivelGravedad(){
        var id= props.registros 
        var objetoRequest={
            codubigeo: id,
            edadmin: 0,
            edadmax: 130,
            sexo: ""
        } 
        axios.post(URL.apiURL+'getcantpernivelgravedadsexorangoedadesxubigeoxedades', objetoRequest,headers)
        .then(response => {
            convertNgravedadDS(response.data) 
            setReporteShow(true);
        })
        .catch(err=> {  });
    }

    const convertNgravedadDS= (data)=>{
        var datos= data.data
        var resultado=[]
        var colores=["#0AFF19","#FAFC00","#E6282E","#343a40"]
        var colori=0
        for (let index = 0; index < datos.length; index++) {
            var canAsumar= datos[index].Cantidad
            var objeto={
                id:colori,
                color:colores[colori],
                porcentaje:0,
                nombre:datos[index].NivelGravedad,
                labels:"",
                cantidad:datos[index].Cantidad+ canAsumar,
                barras:datos[index].Cantidad+ canAsumar
            }
            colori=colori+1
            resultado.push(objeto)
        }
        resultado= armarPorcentajes(resultado) 
        var objetosResultado= ordenarObjetos(indexArray(resultado))
        objetosResultado=indexArray(objetosResultado)
        setNivelGravedad(objetosResultado)
        // setNivelGravedad(resultado)
        // props.changeGravedad(resultado)
        
    }

    const armarPorcentajes=(objeto)=>{
        var resultado= objeto
        var total=0
        for (let index = 0; index < resultado.length; index++) {
            total=resultado[index].cantidad+total
        }
        resultado= resultado.map(x=>{x.porcentaje=Math.round(((x.cantidad*100)/(total))*100)/100; return x})
        return resultado
    }
    
    function ordenarObjetos(nobjetos){
        var aOrdenar= nobjetos
        var aResultado=[]        
        while(aOrdenar.length>0){
            let oMayor=aOrdenar[0]
            for (let index = 0; index < aOrdenar.length; index++) {
                if(aOrdenar[index].porcentaje> oMayor.porcentaje ){
                    oMayor=aOrdenar[index]
                }
            }
            aResultado.push(oMayor)
            aOrdenar=aOrdenar.filter(x=>x.id!==oMayor.id)
        }
        return aResultado
    }
    
    function indexArray(a){
        var newObjetos= a
        for (let index = 1; index <= newObjetos.length; index++) {
            newObjetos[index-1].id=index;            
        }
        return newObjetos
    }

    var DateToday = Date.now()
    var Datenow= new Date(DateToday).toLocaleDateString().split('/')
    var DateFinal =  parseInt(Datenow[0])-1 +'/'+parseInt(Datenow[1]) + '/' +parseInt(Datenow[2])

    function rangoBarras(porcentaje){
        var total= 0
        var porce = parseInt(porcentaje)
        if(porce>=0 && porce<=25){
            total = 25
        }else  if(porce>=26 && porce<=50){
            total = 50
        }else  if(porce>=51 && porce<=75){
            total = 75
        }else  if(porce>=76 && porce<=100){
            total = 100
        }
        return total
    }

    return (
        <>
         {reporteShow ?
            <div className="col-12">
                {/* <div className="heading heading--primary heading--center">
                    <h5 className="heading__title no-margin-bottom text-primary"><span> {props.sede}</span></h5>
                </div> */}
                <div className="card">
                    <div className="card-header"> </div>
                    <div className="card-body">	

                        {
                            NivelGravedad.map((x,index)=>{                           
                                return(
                                    <div key={index}>
                                        <div className="progress-bar mt-4">
                                            <div className="progress-bar progress-animated" role="progressbar" style={{width: rangoBarras(x.porcentaje), background: x.color}}>
                                                <span className="sr-only">{x.cantidad}</span>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-end justify-content-between">
                                            <span className="text-dark font-w900">{x.nombre}</span>
                                            {/* <span className="text-dark font-w900">{x.cantidad}</span> */}
                                            <span className="font-w900"><span className="text-black font-w900">{x.cantidad}</span> ({x.porcentaje} %)</span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div> 
                    <div className="card-footer">
                        <div className='text-left '>
                            <h6 className="card-title">FUENTE:</h6>
                            <span style={{fontSize: '13px'}}>Registro Nacional de la Persona con Discapacidad - CONADIS [Actualizado al 29/08/2022]</span><br/>
                            <span style={{fontSize: '13px'}}>His Discap Web - Ministerio de Salud [Actualizado al 31/07/2022]</span><br/>
                            <span style={{fontSize: '13px'}}>Programa Contigo - Ministerio de Inclusión y Desarrollo Social  [Correspondiente al Padrón III-2022]</span>
                        </div> 
                    </div>
                </div>

            </div>
            : <Spiner/>}
        </>
    )
}

export default InformacionMapa

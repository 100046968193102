import React, {useEffect,useRef,useState } from 'react'
import axios from 'axios';
import ResumenTablaRubro from './resumenRubro';
import mapaPeru from '../mapaperu.json' 
import { Map, TileLayer, Marker, Popup, Polygon, GeoJSON, MapControl } from 'react-leaflet'
import MapBoxGLLayer from './MapBoxGLLayer';
import {LeyendaMapaRubro} from './leyendaRubro'; 

import 'leaflet/dist/leaflet.css'
import "../cuotaempleoPage.css"
import Spiner from '../../../componentes/Spiner';

const RubroEmpleoPage = () => {     
    const [listaSedesRubro, setListaSedesRubro] = useState([]); 
    const [cuotaShow, setCuotaShow] = useState(true); 

    const [map, setMap] = useState(null);

    const GeoJSONEL = useRef(null);
    const MapEL = useRef(null);
    const MapBoxEl = useRef(null);
    const MapInfoEl = useRef(null);

    useEffect(() => {
        setListaSedesRubro(null);
        const timer = setTimeout(() => {
            getAPIRubroEmpleo();
          }, 200);
        return () => clearTimeout(timer);
      }, []);

    const getAPIRubroEmpleo=()=>{
        axios.get(`https://fiscalizacion.conadisperu.gob.pe/api/getMonitoreoRubro/`).then(response => {
            setListaSedesRubro(response.data);
        }).catch(function(error) {
            console.log(error);
        });
    }

    const onEachFeature = (feature, layer) => {
        layer.on({
        //   mouseover: highlightFeature,
        //   mouseout: resetHighlight,
        //   click: zoomToFeature
        });
        layer.bindTooltip(
            "<div><b>" +feature.properties.NOMBDEP +"</b></div>",
            { permanent: true, direction:'center', className: 'countryLabel'}
        ); 
    }
    const highlightFeature = (e) => {
        var layer = e.target;
        layer.setStyle({
            weight: 5,
            color: "#4A4C70",
            dashArray: "",
            fillOpacity: 0.7
        });

        layer.bringToFront();    
        // MapInfoEl.current.setInfo(layer.feature.properties);
    }   
    const resetHighlight = (event) => {
        GeoJSONEL.current.leafletElement.resetStyle(event.target);
        // MapInfoEl.current.setInfo();
    }

    const style = (feature) => {
        return ({
            weight: 5,
            opacity: 1,
            fillOpacity: 1,
            color:'#000000',
            fillColor:getColor(feature.properties, listaSedesRubro),
        });
    }
    const getColor = (mapa, sedes) =>{
        for (let index = 0; index < sedes.length; index++) {
            if(sedes[index].cod_sede == mapa.FIRST_IDDP){ 
                if(cuotaShow){       
                    if(parseFloat(sedes[index].totef_ninfo || 0).toFixed(2) >0){
                        return '#F56273'
                    }else {
                        return '#03E366' 
                    }
                }
                
            }
        }
    };    

    const MapaRubroEmpleo=()=>{
        if(listaSedesRubro){
            // return <MapCuotaEmpleo listaSedes={listaSedesRubro} className="leaflet-container"/>
            return( 
                <Map  
                    ref={MapEL} 
                    center={[-9.378835, -74.906473]} 
                    zoom={6} 
                    maxZoom= {10}
                    minZoom= {6}
                    doubleClickZoom={false}
                    scrollWheelZoom={false}
                    attributionControl={false}
                    // zoomControl={false}
                    // dragging={false}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        // url="/img/blanco.png"
                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
                    />
                    {/* <MapInfo ref={MapInfoEl}/> */}
                    <MapBoxGLLayer
                        ref={MapBoxEl} 
                        accessToken={'pk.eyJ1IjoieGN1bGxvYXgiLCJhIjoiY2wyZndoeHR1MDBuNTNsbHIwMzc5MHVlciJ9.5_UMSagakftvT7JmY7hc8w'}
                        // accessToken={"pk.eyJ1IjoiZmxvcnZhbmRla2VyY2tob3ZlIiwiYSI6ImNqdGZyMmtrejAxYWw0M3A2OGtwdTMxNWEifQ.5U-KSDZfyKNC_Z74fEWj6g"}
                        // style="/img/blanco.png"
                    />
                    <GeoJSON ref={GeoJSONEL}  data={mapaPeru.features} style={style} onEachFeature={onEachFeature} />
                    <LeyendaMapaRubro/>
                </Map>
            ) 
        } else{
            return <Spiner/>
        }
    }
    const TablaRubroEmpleo=()=>{
        if(listaSedesRubro){
            return <ResumenTablaRubro listaSedes={listaSedesRubro}/>            
        }else{
            return <Spiner/>
        }
    }

    return (
        <div className="row margin-bottom">
            <div className="col-12">
                <div className="row" id="cuota_empleo"> 
                        
                    <div className="col-md-5 text-center">
                        {cuotaShow ?
                        <div className="card">
                            <div className="card-header ">
                                <p className="card-text text-dark font-w900">INSTITUCIONES FISCALIZADAS A NIVEL NACIONAL</p>
                            </div>
                            <div className="card-body">
                                {MapaRubroEmpleo()}                                
                            </div> 
                        </div>
                        : ''}

                    </div>
                    <div className="col-md-7 text-center">
                        {cuotaShow ? 
                        <div className="card">
                            <div className="card-header">
                                <p className="card-text text-dark font-w900">CUMPLIMIENTO DEL RUBRO DE DISCAPACIDAD PARA OTORGAR LA BONIFICACIÓN DEL 15% SEGÚN REGIONES, PERIODO 2021</p>
                            </div>
                            <div className="card-body">
                                {TablaRubroEmpleo()}                                                
                            </div> 
                        </div>
                        : ''}
                    </div> 
                </div>
            </div>
        </div>
    )
}

export default RubroEmpleoPage
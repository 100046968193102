import React, {useState,useEffect} from 'react'
import URL from '../../url.json'
import PieChart, {
  Legend,
  Export,
  Series,
  Label,
  Title,
  SmallValuesGrouping,
  Font,
  Connector,
  Tooltip,
} from 'devextreme-react/pie-chart';
import axios from 'axios';
import headers from '../../headers.json';
import LoadingComponent from '../../componentes/loadingComponent/loadingComponent'
import '../../dashboard.css';
import 'devextreme/dist/css/dx.light.css';
import piura from '../BrechaProgramaPension65/ProgramaPension65_Data';

/*
const datar =  axios.post(URL.apiURL+'getPension65_resumen',{ubigeo:'0'},headers)
	  .then(response => {
	  	return response.json;
	      //console.log('response Brecha Sin Certificado', response)
	      //convertBrechaPcd(response.data)
	})
	  .catch(err=> {
	      console.log('error al obtener la informacion', err)
	      //setErrorBrechaPcd(err);
	})
	  */

/*
const GetBrechaPension65= () =>{
	const [dataSource, setdataSource] = useState([]);
	const [ErrorBrechaPcd, setErrorBrechaPcd] = useState(null);

	const [dataBrecha, setdataBrecha] = useState('--');
	const [dataToolTip, setdataToolTip] = useState([{'pcd':0,'total_usuarios':0,'total':0 }]);
	setdataSource([])
	setdataToolTip([{'pcd':0,'total_usuarios':0,'total':0 }])
	setErrorBrechaPcd(null)

	axios.post(URL.apiURL+'getPension65_resumen',{ubigeo:'0'},headers)
	  .then(response => {
	  	fff = response.data;
	      //console.log('response Brecha Sin Certificado', response)
	      //convertBrechaPcd(response.data)
	})
	  .catch(err=> {
	      console.log('error al obtener la informacion', err)
	      //setErrorBrechaPcd(err);
	})
	//return dataSource;
}*/

//console.log("logooog riveraaa",datar);
var dData;
const Pension65_Cobertura=(props) => {
  
  useEffect(() => {
    getDataPension65()
  },[])

  const [dataSource, setdataSource] = useState([]);
  const [ErrorBrechaPcd, setErrorBrechaPcd] = useState(null);

  const [dataBrecha, setdataBrecha] = useState('--');

  const getDataPension65=(cod)=>{
    
    setdataSource([])
    setErrorBrechaPcd(null)

      axios.post(URL.apiURL+'getPension65_resumen',{ubigeo:cod},headers)
      .then(response => {
          setDataPension65(response.data)
      })
      .catch(err=> {
          console.log('error al obtener la informacion', err)
          setErrorBrechaPcd(err);
      })
  }

  const setDataPension65= (data)=>{
    let datos = data.data;
    dData = datos;

    if(datos.length>0){
    	if(props.tipoData == "cobertura"){
		    let newData = [];
		    newData.push({type: 'SIN DISCAPACIDAD',num: datos[0].Total-datos[0].Total_usuarios})
		    newData.push({type: 'CON DISCAPACIDAD',num: datos[0].Total_usuarios})
		    setdataSource(newData)
		
		    setdataBrecha((datos[0].Total_usuarios / datos[0].Total ) * 100) // % de usuarios con discapacidad
    	}else if(props.tipoData == "brecha"){
		    let newData = [];
		    newData.push({type: 'USUARIOS',num: datos[0].Total_usuarios})
		    newData.push({type: 'POTENCIALES USUARIOS',num: datos[0].brecha_abs})
		    		    
		    setdataSource(newData)
		
		    setdataBrecha(datos[0].brecha_por) // % de usuarios con discapacidad
    	}


    }else{
      setdataSource([])
      setErrorBrechaPcd({error:'no hay datos'})
    }

  }

	const BrechaPcdComponent=()=>{
        
	    if(dataSource.length>0){   
	    	if(props.tipoData=="cobertura"){     

		        return  <> 
		            <PieChart id="pie"
		            palette={['#ff9900','rgb(255, 0, 0)']}
		            dataSource={dataSource}
					title=""
		            // title="Programa Pensión 65: usuarios con discapacidad "
		          >
		          <Title >
		              <Font color="black" size="12" />
		          </Title>
		          <Legend
		            orientation="horizontal"
		            itemTextPosition="right"
		            horizontalAlignment="center"
		            verticalAlignment="top"
		            columnCount={4} />
		          <Export enabled={true} fileName="brecha_por_certificar" />
		          <Series argumentField="type" valueField="num">
		            <Label
		              visible={true}
		              position="columns"
		              customizeText={customizeText}
		              format="fixedPoint"
		              >
		                
		              <Font size={12} />
		              <Connector visible={true} width={0.5} />
		            </Label>
		            <SmallValuesGrouping threshold={4.5} mode="smallValueThreshold" />
		          </Series>
		          <Tooltip
		            enabled={true}
		            contentRender={TooltipTemplate}
		          />
		        </PieChart>
		        
		        <div className="title"> <span className="BrechaPension65"></span> TOTAL (100%): {formatNumber(dataSource[0].num+dataSource[1].num) } USUARIOS </div>
		        <div className="title"> <span className="legendBrecha BrechaInscripcion"></span> El {Number(dataBrecha).toFixed(2)} % ({formatNumber(dataSource[1].num)}) USUARIOS CON DISCAPACIDAD</div>
		        </> 
		    }
		    else if(props.tipoData=="brecha"){     

		        return  <> 
		            <PieChart id="pie"
		            palette={['rgb(153, 153, 153)','#ff9900' ]}
		            dataSource={dataSource}
					title=" "
		            // title="Programa Pensión 65: potenciales usuarios respecto a personas con discapacidad de 65 años a más"
		          >
		          <Title >
		              <Font color="black" size="12" />
		          </Title>
		          <Legend
		            orientation="horizontal"
		            itemTextPosition="right"
		            horizontalAlignment="center"
		            verticalAlignment="top"
		            columnCount={4} />
		          <Export enabled={true} fileName="brecha_por_certificar" />
		          <Series argumentField="type" valueField="num">
		            <Label
		              visible={true}
		              position="columns"
		              customizeText={customizeText}
		              format="fixedPoint"
		              >
		                
		              <Font size={12} />
		              <Connector visible={true} width={0.5} />
		            </Label>
		            <SmallValuesGrouping threshold={4.5} mode="smallValueThreshold" />
		          </Series>
		          <Tooltip
		            enabled={true}
		            contentRender={TooltipTemplate}
		          />
		        </PieChart>
		        
		        <div className="title"> <span className="legendBrecha"></span> TOTAL (100%): {formatNumber(dataSource[0].num+dataSource[1].num) } USUARIOS </div>
		        <div className="title"> <span className="BrechaPension65"></span> EL {Number(dataBrecha).toFixed(2)} % ({formatNumber(dataSource[0].num)}) POTENCIALES USUARIOS</div>
		        </> 
		    }
		    else
		    	return <div className='de--title row' style={{marginLeft:'8%'}}><LoadingComponent configuracion="" error={ErrorBrechaPcd} funcionrefresh={getDataPension65}  ></LoadingComponent></div>
	    }
	    else{
	        return <div className='de--title row' style={{marginLeft:'8%'}}><LoadingComponent configuracion="" error={ErrorBrechaPcd} funcionrefresh={getDataPension65}  ></LoadingComponent></div>
	    }
	}  

  return(

    BrechaPcdComponent()
  
  )
}

const Pension65_Brecha=(props) => {
  
  useEffect(() => {
     getDataPension65()
  },[])

  const [dataSource, setdataSource] = useState([]);
  const [ErrorBrechaPcd, setErrorBrechaPcd] = useState(null);

  const [dataBrecha, setdataBrecha] = useState('--');

  const getDataPension65=(cod)=>{
    
    setdataSource([])
    setErrorBrechaPcd(null)
	//setDataPension65([0,1])
      axios.post(URL.apiURL+'getPension65_resumen',{ubigeo:cod},headers)
      .then(response => {
          setDataPension65(response.data)
      })
      .catch(err=> {
          console.log('error al obtener la informacion', err)
          setErrorBrechaPcd(err);
      })
  }

  const setDataPension65= (data)=>{
    let datos = data.data;

    if(datos.length>0){

	    let newData = [];
	    newData.push({type: 'SIN DISCAPACIDAD',num: datos[0].Total-datos[0].Total_usuarios})
	    newData.push({type: 'CON DISCAPACIDAD',num: datos[0].Total_usuarios})
	    setdataSource(newData)
	
	    setdataBrecha((datos[0].Total_usuarios / datos[0].Total ) * 100) // % de usuarios con discapacidad

    }else{
      setdataSource([])
      setErrorBrechaPcd({error:'no hay datos'})
    }

  }

	const BrechaPcdComponent=()=>{
        
	    if(dataSource.length>0){        

	        return <> 
	            <PieChart id="pie"
	            palette={['rgb(0, 69, 97)', 'rgb(255, 111, 49)']}
	            dataSource={dataSource}
				title=" "
	            // title="Usuarios con discapacidad del Programa Pensión 65"
	          >
	          <Title >
	              <Font color="black" size="12" />
	          </Title>
	          <Legend
	            orientation="horizontal"
	            itemTextPosition="right"
	            horizontalAlignment="center"
	            verticalAlignment="top"
	            columnCount={4} />
	          <Export enabled={true} fileName="brecha_por_certificar" />
	          <Series argumentField="type" valueField="num">
	            <Label
	              visible={true}
	              position="columns"
	              customizeText={customizeText}
	              format="fixedPoint"
	              >
	                
	              <Font size={12} />
	              <Connector visible={true} width={0.5} />
	            </Label>
	            <SmallValuesGrouping threshold={4.5} mode="smallValueThreshold" />
	          </Series>
	          <Tooltip
	            enabled={true}
	            contentRender={TooltipTemplate}
	          />
	        </PieChart>
	        
	        <div className="title"> <span className="legendBrecha"></span> TOTAL (100%): {formatNumber(dataSource[0].num+dataSource[1].num) } USUARIOS </div>
	        <div className="title"> <span className="legendBrecha BrechaSinCertificar"></span> El {Number(dataBrecha).toFixed(2)} % ({formatNumber(dataSource[1].num)}) USUARIOS CON DISCAPACIDAD</div>
	        </>
	    }
	    else{
	        return <div className='de--title row' style={{marginLeft:'8%'}}><LoadingComponent configuracion="" error={ErrorBrechaPcd} funcionrefresh={getDataPension65}  ></LoadingComponent></div>
	    }
	}  

  return(

    BrechaPcdComponent()
  
  )
}

function customizeText(arg) {
  return `<b>${arg.argumentText}:</b> ${(arg.percent *100).toFixed(2)}%`;
}

const customizeText4 = (props) => {
  return (<><b>Hola mundo:</b></>)
}


const formatNumber = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
}).format;

function TooltipTemplate(info){
  return (
    <div className="state-tooltip">
      <div className="capital">
        <span className="caption"><b>CANTIDAD</b></span>
      </div>
      <div className="population">
        {formatNumber(info.value)} PERSONAS
      </div>
    </div>
  );
}

export {Pension65_Brecha, Pension65_Cobertura};
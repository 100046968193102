import React from 'react'

const ReporteDEdad = (props) => {
    var DateToday = Date.now()
    var Datenow= new Date(DateToday).toLocaleDateString().split('/')
    let DateFinal =  (parseInt(Datenow[0])-1 === 0 ? 1 : parseInt(Datenow[0])-1)+'/'+parseInt(Datenow[1]) + '/' +parseInt(Datenow[2])

    return (
        <div style={{display:"none"}}>
        <table className="tableMapa" id="table-Dedad">

                <thead>
                    <tr >
                        <th >Edad</th>
                        <th >Cantidad Mujeres</th>
                        <th >Porcentaje Mujeres</th>
                        <th >Cantidad Hombre</th>
                        <th >Porcentaje Hombres</th>
                        <th rowSpan="8">{props.region+', ' +props.provincia+', ' +props.distrito}</th>

                    </tr>
                </thead>

                <tbody>

                    {
                        props.registros.map((x,index)=>{


                            return <tr key={index}>
                                    <td >{x.nombre} <div className="maptable-line"></div></td>
                                    <td >{x.PACantidad} <div className="maptable-line"></div></td>
                                    <td >{x.PA}% <div className="maptable-line"></div></td>
                                    <td >{x.POCantidad} <div className="maptable-line"></div></td>
                                    <td >{x.PO}% <div className="maptable-line"></div></td>
                                    </tr>
                        })


                    }

                <td colSpan="5">Fuente: CONADIS {DateFinal}, MINSA al 31/12/2021 y CONTIGO a 31/12/2021</td>
                </tbody>

                </table>
        </div>
    )
}

export default ReporteDEdad
// import React,{Fragment} from 'react' 
import React, {useState,useEffect} from 'react'
import { BrowserRouter as Router, Route , Redirect, Switch } from 'react-router-dom';
import PageFooter from './componentes/footer';
import PageMainBody from './pages/InicioPage';
import PageMapa from './pages/MapaPage';
import PageFiscalizacion from './pages/FiscalizacionPage';
import ScrollToTop from './componentes/ScrollToTop';
import PageDashboard from './pages/DashboardPage';
import PageContacto from './pages/ContactoPage';

//Paginas PUD Anterior
// import HistoricDistributionage from './public/historicdistributionage/historicdistributionage'
// import HistoricDistributionsex from './public/historicdistributionsex/historicdistributionsex'
// import Historiclevelgravity from './public/historiclevelgravity/historiclevelgravity'
// import MapaPage from './masterPage/mapaPage/mapaPage'
// import PoblacionStadististPages from './masterPage/PoblacionStadististPages/poblacionStadististPages'
// import SocioEconomicPage from './masterPage/socioEconomicPage/socioEconomicPage'
// import CerificadoPage from './masterPage/certificadoPage/cerificadoPage'
// import AfiliadosPage from './masterPage/afiliadosPage/afiliadosPage'
// import LaboralPage from './masterPage/laboralPage/laboralPage' 


import './AppStyle.css';
import PageInstituciones from './pages/InstitucionesPage';

function App() {

  // const [mapStatus, setMapStatus] = useState(false);
  // const [mapSelected, setMapSelected] = useState("");
  // let [DepartamentoSelected,setDepartamentoSelected] = useState("0")
  // let [ProvinciasSelected,setProvinciasSelected] = useState("0")
  // let [DistritoSelected,setDistritoSelected] = useState("0")
  // let [ubiGeo,setUbiGeo] = useState("")  
  
  // useEffect(() => {
  //   changeubiGeo()
  // }, [DepartamentoSelected,ProvinciasSelected,DistritoSelected]);


  // const changeubiGeo=()=>{
  //   var dominante=""
  //   if(DepartamentoSelected!=="0"){
  //     dominante=DepartamentoSelected
  //   }

  //   if(ProvinciasSelected!=="0"){
  //     dominante=ProvinciasSelected
  //   }
  //   if(DistritoSelected!=="0"){
  //     dominante=DistritoSelected
  //   }
  //   setUbiGeo(dominante)
  // }

  // function changeDepartamento(id){
  //   setDepartamentoSelected(id)
  // }

  // const changeProvincias=(id)=>{
  //   setProvinciasSelected(id)
  // }

  // const changeDistrito=(id)=>{ 
  //   setDistritoSelected(id)
  // }

  // const openMap=()=>{
  //   setMapStatus(!mapStatus)
  // }

  // const changeSelected=(selected)=>{
  //   setMapSelected(selected) 
  // }

  return (
     
      <Router>
        {/* <PageHeader/> */}
        {/* <PageFiscalizacion/> */}
        <Switch>
            <Route exact path="/"><Redirect to="/inicio" /> </Route>
            <Route exact path="/inicio"><PageMainBody /></Route>
            <Route exact path="/principal"><PageDashboard/></Route> 
            <Route exact path="/mapa"><PageMapa /></Route>
            <Route exact path="/fiscalizacion"><PageFiscalizacion /></Route>
            <Route exact path="/instituciones"><PageInstituciones/></Route>
            <Route exact path="/contacto"><PageContacto /></Route>


            {/* <Route exact path="/historicage">
              <HistoricDistributionage 
                openMap={openMap} 
                DepartamentoSelected={DepartamentoSelected} 
                ubiGeo={ubiGeo}
                ProvinciasSelected={ProvinciasSelected} 
                DistritoSelected={DistritoSelected}
                changeDepartamento={changeDepartamento} 
                changeProvincias={changeProvincias} 
                changeDistrito={changeDistrito}>
              </HistoricDistributionage>
            </Route>



          <Route exact path="/historicsex">
            <HistoricDistributionsex 
              openMap={openMap} 
              DepartamentoSelected={DepartamentoSelected} 
              ubiGeo={ubiGeo}
              ProvinciasSelected={ProvinciasSelected} 
              DistritoSelected={DistritoSelected}
              changeDepartamento={changeDepartamento} 
              changeProvincias={changeProvincias} 
              changeDistrito={changeDistrito}>
            </HistoricDistributionsex>
          </Route>

          <Route exact path="/historicgravity">
            <Historiclevelgravity 
              openMap={openMap} 
              DepartamentoSelected={DepartamentoSelected} 
              ubiGeo={ubiGeo}
              ProvinciasSelected={ProvinciasSelected} 
              DistritoSelected={DistritoSelected}
              changeDepartamento={changeDepartamento} 
              changeProvincias={changeProvincias} 
              changeDistrito={changeDistrito}>
            </Historiclevelgravity>
          </Route>

          
          <Route exact path="/socioEconomicPage" > 
            <SocioEconomicPage 
              openMap={openMap} 
              DepartamentoSelected={DepartamentoSelected}                                               
              ProvinciasSelected={ProvinciasSelected} 
              DistritoSelected={DistritoSelected}
              changeDepartamento={changeDepartamento} 
              changeProvincias={changeProvincias} 
              changeDistrito={changeDistrito} >
            </SocioEconomicPage> 
          </Route>


          <Route exact path="/afiliadosPage" > 
            <AfiliadosPage 
              openMap={openMap} 
              DepartamentoSelected={DepartamentoSelected} 
              ubiGeo={ubiGeo}
              ProvinciasSelected={ProvinciasSelected} 
              DistritoSelected={DistritoSelected}
              changeDepartamento={changeDepartamento} 
              changeProvincias={changeProvincias} 
              changeDistrito={changeDistrito}>
            </AfiliadosPage> 
          </Route>

          <Route exact path="/CertificadoPage" > 
            <CerificadoPage 
              openMap={openMap} 
              DepartamentoSelected={DepartamentoSelected} 
              ProvinciasSelected={ProvinciasSelected} 
              DistritoSelected={DistritoSelected}
              changeDepartamento={changeDepartamento} 
              changeProvincias={changeProvincias} 
              changeDistrito={changeDistrito}>
            </CerificadoPage>  
          </Route>

          <Route exact path="/LaboralPage" > 
            <LaboralPage 
              openMap={openMap} 
              DepartamentoSelected={DepartamentoSelected} 
              ProvinciasSelected={ProvinciasSelected} 
              DistritoSelected={DistritoSelected}
              changeDepartamento={changeDepartamento} 
              changeProvincias={changeProvincias} 
              changeDistrito={changeDistrito}>
            </LaboralPage>   
          </Route>*/}

        </Switch>
        <PageFooter/>
        <ScrollToTop/>
      </Router>
     
  );
}

export default App;


{/* <div className="page-wrapper"> */}
  {/* <header className="App-header">
    <img src={logo} className="App-logo" alt="logo" />
    <p>
      Edit <code>src/App.js</code> and save to reload.
    </p>
    <a
      className="App-link"
      href="https://reactjs.org"
      target="_blank"
      rel="noopener noreferrer"
    >
      Learn React
    </a>
  
  </header> */}
import React, {useState,useEffect} from 'react'
import URL from '../../url.json'
import PieChart, {
  Legend,
  Export,
  Series,
  Label,
  Title,
  SmallValuesGrouping,
  Font,
  Connector,
  Tooltip,
} from 'devextreme-react/pie-chart';
import axios from 'axios';
import headers from '../../headers.json';
import LoadingComponent from '../../componentes/loadingComponent/loadingComponent'
import '../../dashboard.css';
//import 'devextreme/dist/css/dx.light.css';

const BrechaInscripcionCONADIS=(props) => {
  
  useEffect(() => {
    getBrechaInscripcionCONADIS()
  },[])

  var DateToday = Date.now()
  var Datenow= new Date(DateToday).toLocaleDateString().split('/')
  let DateFinal =  parseInt(Datenow[0])-1 +'/'+parseInt(Datenow[1]) + '/' +parseInt(Datenow[2]);

  const [dataSourceInscripcionCONADIS, setdataSourceInscripcionCONADIS] = useState([]);
  const [ErrorIncripcionCONADIS, setErrorIncripcionCONADIS] = useState(null);

  const [dataBrecha, setdataBrecha] = useState('--');
  const [dataToolTip, setdataToolTip] = useState([{'minsa':0,'minsa_por_registrar':0,'total':0 }]);

  const getBrechaInscripcionCONADIS=()=>{

    
    setdataSourceInscripcionCONADIS([])
    setdataToolTip([{'minsa':0,'minsa_por_registrar':0,'total':0 }])
    setErrorIncripcionCONADIS(null)

      axios.post(URL.apiURL+'getPersonasCertificadasEnHISDISCAPWEBNoInscritasEnRNPCD',{},headers)
      .then(response => {
          // console.log('response m2', response)
          convertBrechaIncripcionCONADIS(response.data)
      })
      .catch(err=> {
          // console.log('error m2', err)
          setErrorIncripcionCONADIS(err);
      })
  }

  const convertBrechaIncripcionCONADIS= (data)=>{
    let datos = data.data;

    // console.log('Convirtiendo CONADIS datos', datos, datos[0].conadis)


    if(datos.length>0){

      let newData = [];
      /*newData.push({type: 'CONADIS',num: datos[0].conadis})*/
      newData.push({type: 'Inscritas',num: datos[0].minsa_conadis})
      newData.push({type: 'No inscritas',num: datos[0].minsa_por_registrar})
      setdataSourceInscripcionCONADIS(newData);
      setsetdataBrecha(datos);

    }else{
      setdataSourceInscripcionCONADIS([])
      setErrorIncripcionCONADIS({error:'no hay datos'})
    }


  }

  const setsetdataBrecha = (datos)=>{
    let total = datos[0].minsa + datos[0].minsa_por_registrar;/*datos[0].conadis +*/
    let porcentaje = (datos[0].minsa_por_registrar / total)*100;

    let newdataTooltip = [];
    newdataTooltip.push({'minsa':datos[0].minsa,'minsa_por_registrar':datos[0].minsa_por_registrar,'total':total })
    setdataToolTip(newdataTooltip)

    setdataBrecha(porcentaje)
  }

  const BrechaIncripcionCONADISComponent=()=>{
        
    if(dataSourceInscripcionCONADIS.length>0){        

        return <> 
            <PieChart id="pie"
            palette={['rgb(153, 153, 153)','rgb(255, 0, 0)',  'rgb(2, 102, 166)']}
            dataSource={dataSourceInscripcionCONADIS}
            title="RNPCD: brecha de la población no inscrita respecto a la certificada a través el His Discap Web"
          >
          <Title >
              <Font color="black" size="12" />
          </Title>
          <Legend
            orientation="horizontal"
            itemTextPosition="right"
            horizontalAlignment="center"
            verticalAlignment="top"
            columnCount={4} />
          <Export enabled={true} fileName="brecha_por_inscripcion_CONADIS" />
          <Series argumentField="type" valueField="num">
            <Label
              visible={true}
              position="columns"
              customizeText={customizeText}
              format="fixedPoint"
              >
                
              <Font size={12} />
              <Connector visible={true} width={0.5} />
            </Label>
            <SmallValuesGrouping threshold={4.5} mode="smallValueThreshold" />
          </Series>
          <Tooltip
            enabled={true}
            contentRender={TooltipTemplate}
          />
        </PieChart>
        <div className="title"> <span className="legendBrecha"></span> Total (100%): {formatNumber(dataToolTip[0].minsa)} personas certificadas</div>
        <div className="title"> <span className="legendBrecha BrechaInscripcion"></span> El {Number(dataBrecha).toFixed()} %  ({formatNumber(dataToolTip[0].minsa_por_registrar)}) no se encuentran inscritas en el RNPCD.</div>
        </>
    }
    else{
        return <div className='de--title row' style={{marginLeft:'8%'}}><LoadingComponent configuracion="" error={ErrorIncripcionCONADIS} funcionrefresh={getBrechaInscripcionCONADIS}  ></LoadingComponent></div>
    }
}

 const data = {
    labels: [
      'PCD',
      'PCD SIN CERTIFICADO',
    ],
    datasets: [{
      label: 'Brecha de pcd estimada por ENEDIS que no cuentan con certificado de discapacidad',
      data: [400630, 1174772],
      backgroundColor: [
        'rgb(0, 69, 97)',
        'rgb(255, 111, 49)',
      ],
      hoverOffset: 4,
    }]
  };

 /* const dataSource = [
    {
      type: 'PCD',
      num: 400630,
    }, 
    {
      type: 'SIN CERTIFICADO',
      num: 1174772,
    }, 

];*/
  

  return(

    BrechaIncripcionCONADISComponent()
  
  )
}

function customizeText(arg) {
  return `<b>${arg.argumentText}:</b> ${arg.percentText}`;
}

const formatNumber = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
}).format;

function TooltipTemplate(info){
  return (
    <div className="state-tooltip">
      <div className="capital">
        <span className="caption"><b>Cantidad</b></span>
      </div>
      <div className="population">
        {formatNumber(info.value)} personas
      </div>
    </div>
  );
}

export default BrechaInscripcionCONADIS;
import React, { useEffect, useRef, useState } from 'react'
import { Map, TileLayer, Marker, Popup, Polygon, GeoJSON, MapControl } from 'react-leaflet'
import MapBoxGLLayer from './../../componentes/MapBoxGLLayer';
import 'leaflet/dist/leaflet.css'
import axios from 'axios';
import URL from '../../url.json'
import "./cuotaempleoPage.css"
import Spiner from '../../componentes/Spiner';
import ResumenAccesibilidad from './ResumenAccesibilidad';
import ResumenAccesibilidadTra from './ResumenAccesibilidadTra';
import ResumenAccesibilidadTic from './ResumenAccesibilidadTic';
import ResumenAccesibilidadFis from './ResumenAccesibilidadFis';
import FiltroAnio from './../../componentes/filtroanio'
import FiltroOpcion from './../../componentes/filtroopcion'
import { AppConstant } from '../../constants';
import mapaPeru from './../../jsons/mapaperu.json' 

const EjeAccesibilidadPage = () => {
    const opciones = [
        { label: 'Accesibilidad 2021', code: 'RES', url: 'getAcceSubmaterias' },
        { label: 'Accesibilidad Transporte', code: 'TRA', url: 'getRegionTra' },
        { label: 'Accesibilidad Tics', code: 'TIC', url: 'getRegionTic' },
        { label: 'Accesibilidad Medio Fisico', code: 'FIS', url: 'getRegionFis' }
    ];

    const [listaSedesAccesibilidad, setListaSedesAccesibilidad] = useState([]);
    const [anio, setAnio] = useState(2021)
    const [opcion, setOpcion] = useState(opciones[0]);
    const GeoJSONEL = useRef(null);
    const MapEL = useRef(null);
    const MapBoxEl = useRef(null);

    useEffect(() => {
        setListaSedesAccesibilidad(null);
        const timer = setTimeout(() => {
            getAPIAccesibilidad();
        }, 200);
        return () => clearTimeout(timer);
    }, [opcion]);

    const getAPIAccesibilidad = () => {
        axios.get(`${AppConstant.apiUrl}/${opcion.url}`, { params: { anio: opcion.code == 'RES'? 2021: 2022  } }).then(response => {
            setListaSedesAccesibilidad(response.data);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const TablaAccesibilidad = () => {
        if (listaSedesAccesibilidad) {
            if(opcion.code == 'TRA') {
                return <ResumenAccesibilidadTra listaSedes={listaSedesAccesibilidad} />
            } else  if(opcion.code == 'TIC') {
                return <ResumenAccesibilidadTic listaSedes={listaSedesAccesibilidad} />
            }
            else  if(opcion.code == 'FIS') {
                return <ResumenAccesibilidadFis listaSedes={listaSedesAccesibilidad} />
            } else {
                return <ResumenAccesibilidad listaSedes={listaSedesAccesibilidad} />
            }
            // return <p className="card-text text-dark font-w900">aaaa     FISCALIZADAS A NIVEL NACIONAL</p>          
        } else {
            return <Spiner />
        }
    }

    const style = (feature) => {
        return ({
            weight: 4,
            opacity: 5,
            fillOpacity: 0.8,
            color:'#4A4C70',
            fillColor:getColor(feature.properties, listaSedesAccesibilidad),
        });
    }  

    const getColor = (mapa, sedes) =>{
        for (let index = 0; index < sedes.length; index++) {
            if(sedes[index].cod_sede == mapa.FIRST_IDDP){ 
               /*  if(opcion.code == 'TRA') {
                    
                } else  if(opcion.code == 'TIC') {
                    
                }
                else  if(opcion.code == 'FIS') {
                    
                } else {
                    
                } */
                if(opcion.code == 'RES') {
                    if((sedes[index].tot_ef || 0) > 0 ){
                        return '#03E366' //Verde
                    }else if((sedes[index].tot_ef || 0) <= 0 ){
                        return '#F56273' //Amarillo
                        }
                } else  if(opcion.code == 'TRA'){
                    if((sedes[index].tot_enti || 0) > 0 ){
                        return '#03E366' //Verde
                    }else if((sedes[index].tot_enti || 0) <= 0 ){
                        return '#F56273' //Rojo
                        }
                } else  if(opcion.code == 'TIC'){
                    if((sedes[index].to_fis || 0) > 0 ){
                        return '#03E366' //Verde
                    }else if((sedes[index].to_fis || 0) <= 0 ){
                        return '#F56273' //Rojo
                        }
                } else  if(opcion.code == 'FIS'){
                    if((sedes[index].tot_fis || 0) > 0 ){
                        return '#03E366' //Verde
                    }else if((sedes[index].tot_fis || 0) <= 0 ){
                        return '#F56273' //Rojo
                        }
                }
            }
        }
    }; 

    const onEachFeature = (feature, layer) => {
        layer.on({
        //   mouseover: highlightFeature,
        //   mouseout: resetHighlight,
          click: clickFeature.bind(this)
        //   click: zoomToFeature
        });
        layer.bindTooltip(
            "<div><b>" +feature.properties.NOMBDEP +"</b></div>",
            { permanent: true, direction:'center', className: 'countryLabel'}
        ); 
    }

    function clickFeature({ target }) {

        target.setStyle({ 
          weight: 5,
          color: '#C60701',
          fillOpacity: 1,
        });
        target.bringToFront();

    }

    const MapaAccesibilidad=()=>{
        if(listaSedesAccesibilidad){
            return( 
                <Map  
                    ref={MapEL} 
                    center={[-9.378835, -74.906473]} 
                    zoom={6} 
                    maxZoom= {10}
                    minZoom= {6}
                    doubleClickZoom={false}
                    scrollWheelZoom={false}
                    attributionControl={false}
                    // zoomControl={false}
                    // dragging={false}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
                    />
                    <MapBoxGLLayer
                        ref={MapBoxEl} 
                        accessToken={'pk.eyJ1IjoieGN1bGxvYXgiLCJhIjoiY2wyZndoeHR1MDBuNTNsbHIwMzc5MHVlciJ9.5_UMSagakftvT7JmY7hc8w'}
                    />
                    <GeoJSON ref={GeoJSONEL}  data={mapaPeru.features} style={style} onEachFeature={onEachFeature} />
                    {/* <LeyendaMapaPoblacion/> */}
                </Map>
            ) 
        } else{
            return <Spiner/>
        }
    }


    return <>
        <FiltroOpcion label="SELECCIONE EL TIPO DE ACCESIBILIDAD" opciones={opciones} onChangeOpcion={(opcion) => {
            setOpcion(opcion)
        }} />
        {/* <FiltroAnio anios={[2021, 2022]} onChangeAnio={(anio) => {
            setAnio(anio)
        }} /> */}
        <div className="row margin-top margin-bottom">
            <div className="col-12">
                <div className="row">
                    <div className="col-md-12 text-center margin-bottom">
                        <div className="card">
                            <div className="card-header ">
                                <h6 className="card-text text-primary font-w900">FISCALIZACIONES SEGÚN SUB MATERIAS DE ACCESIBILIDAD</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 text-center">
                        <div className="card">
                            <div className="card-header ">
                                <p className="card-text text-dark font-w900">INSTITUCIONES FISCALIZADAS A NIVEL NACIONAL</p>
                            </div>
                            <div className="card-body">
                            {MapaAccesibilidad()}     
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7 text-center">
                        <div className="card">
                            <div className="card-header ">
                                <p className="card-text text-dark font-w900">INSTITUCIONES FISCALIZADAS A NIVEL NACIONAL</p>
                            </div>
                            <div className="card-body">
                                {TablaAccesibilidad()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default EjeAccesibilidadPage
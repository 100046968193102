import React, {useState,useEffect} from 'react'
import URL from '../../url.json'
import PieChart, {
  Legend,
  Export,
  Series,
  Label,
  Title,
  SmallValuesGrouping,
  Font,
  Connector,
  Tooltip,
} from 'devextreme-react/pie-chart';
import axios from 'axios';
import headers from '../../headers.json';
import LoadingComponent from '../../componentes/loadingComponent/loadingComponent'
import '../../dashboard.css';
//import 'devextreme/dist/css/dx.light.css';

const BrechaProgramaSocialCONTIGO=()=>{

  useEffect(() => {
    getBrechaProgramaSocialCONTIGO()
  },[])

  var DateToday = Date.now()
  var Datenow= new Date(DateToday).toLocaleDateString().split('/')
  let DateFinal =  parseInt(Datenow[0])-1 +'/'+parseInt(Datenow[1]) + '/' +parseInt(Datenow[2]);

  const [dataSource, setdataSource] = useState([]);
  const [ErrorProgramaSocialCONTIGO, setErrorProgramaSocialCONTIGO] = useState(null);

  const [dataBrecha, setdataBrecha] = useState('--');
  const [dataToolTip, setdataToolTip] = useState([{'PContigo':0,'severo_no_PContigo':0,'total':0 }]);

  const getBrechaProgramaSocialCONTIGO=()=>{
    console.log('Iniciando programa CONTIGO jajaj')
    
    setdataSource([])
    setdataToolTip([{'PContigo':0,'severo_no_PContigo':0,'total':0 }])
    setErrorProgramaSocialCONTIGO(null)

      axios.post(URL.apiURL+'getBrechaDeProgramaSocialCONTIGO',{},headers)
      .then(response => {
          console.log('response Brecha Sin Certificado', response)
          convertBrechaProgramaSocialCONTIGO(response.data)
      })
      .catch(err=> {
          console.log('error Brecha Sin Certificado', err)
          setErrorProgramaSocialCONTIGO(err);
      })
  }

  const convertBrechaProgramaSocialCONTIGO= (data)=>{
    let datos = data.data;

    //console.log('Convirtiendo CONTIGO datos', datos, datos[0].pcd)

    if(datos.length>0){

      let newData = [];
      newData.push({type: 'USUARIOS',num: datos[0].PContigo})
      newData.push({type: 'POTENCIALES USUARIOS',num: datos[0].severo_no_PContigo})
      setdataSource(newData)
      setsetdataBrecha(datos)

    }else{
      setdataSource([])
      setErrorProgramaSocialCONTIGO({error:'no hay datos'})
    }


  }

  const setsetdataBrecha = (datos)=>{
    let total = datos[0].PContigo + datos[0].severo_no_PContigo;
    let porcentaje = (datos[0].severo_no_PContigo / total)*100;

    let newdataTooltip = [];
    newdataTooltip.push({'PContigo':datos[0].PContigo,'severo_no_PContigo':datos[0].severo_no_PContigo,'total':total })
    setdataToolTip(newdataTooltip)
    setdataBrecha(porcentaje)
  }

  const BrechaProgramaSocialCONTIGO=()=>{
        
    if(dataSource.length>0){        

        return <> 
            <PieChart id="pie"
            palette={['rgb(153, 153, 153)','rgb(106, 168, 79)' ]}
            dataSource={dataSource}
            title=" "
            // title="Programa Contigo: potenciales usuarios respecto a la población con discapacidad severa"
          >
          <Title >
              <Font color="black" size="12" />
          </Title>
          <Legend
            orientation="horizontal"
            itemTextPosition="right"
            horizontalAlignment="center"
            verticalAlignment="top"
            columnCount={4} />
          <Export enabled={true} fileName="brecha_por_certificar" />
          <Series argumentField="type" valueField="num">
            <Label
              visible={true}
              position="columns"
              customizeText={customizeText}
              format="fixedPoint"
              >
                
              <Font size={12} />
              <Connector visible={true} width={0.5} />
            </Label>
            <SmallValuesGrouping threshold={4.5} mode="smallValueThreshold" />
          </Series>
          <Tooltip
            enabled={true}
            contentRender={TooltipTemplate}
          />
        </PieChart>

        <div className="title"> <span className="legendBrecha"></span> TOTAL (100%): {formatNumber(dataToolTip[0].total)} PERSONAS CON DISCAPACIDAD SEVERA</div>
        <div className="title"> <span className="legendBrecha BrechaContigo"></span> El {Number(dataBrecha).toFixed()} % ({formatNumber(dataToolTip[0].severo_no_PContigo)}) POTENCIALES USUARIOS DEL PROGRAMA CONTIGO.</div>
        </>
    }
    else{
        return <div className='de--title row' style={{marginLeft:'8%'}}><LoadingComponent configuracion="" error={ErrorProgramaSocialCONTIGO} funcionrefresh={getBrechaProgramaSocialCONTIGO}  ></LoadingComponent></div>
    }
  }

  return(

    BrechaProgramaSocialCONTIGO()
  
  )


}

function customizeText(arg) {
  return `<b>${arg.argumentText}:</b> ${arg.percentText}`;
}

const formatNumber = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
}).format;

function TooltipTemplate(info){
  return (
    <div className="state-tooltip">
      <div className="capital">
        <span className="caption"><b>CANTIDAD</b></span>
      </div>
      <div className="population">
        {formatNumber(info.value)} PERSONAS
      </div>
    </div>
  );
}

export default BrechaProgramaSocialCONTIGO;
import React, {useState,useEffect} from 'react'
import { NavLink } from 'react-router-dom';

import SliderPrincipal from '../componentes/SliderPrincipal';
import '../AppStyle.css';
import SliderTestimonio from '../componentes/Testimonios';
import PageHeader from '../componentes/header';

const PageMainBody = (props) => {
    return (
        <>
            <PageHeader activeHome={true}/>
            <main className="main">
                {/* Carrusel Nav */}
                <SliderPrincipal/>
                
                {/* Descripcion PUD */}
                <div className="section elements margin-top">
                    <div className="container margin-bottom"> 
                        <div className="row">
                            <div className="col-auto">
                                <div className="tabs horizontal-tabs"> 
                                    <div className="row margin-bottom ">
                                        <div className="col-12">
                                            {/* <div className="heading heading--primary heading--center">
                                                <h1 className="heading__title no-margin-bottom"><span>PLATAFORMA ÚNICA DE LA</span> <span>DISCAPACIDAD</span></h1>
                                            </div> */}
                                            <div className="heading heading--primary heading--center text-primary">
                                                <span className="heading__pre-title text-primary"></span>
                                                <h1 className="heading__title no-margin-bottom text-primary"><span>PLATAFORMA ÚNICA DE LA DISCAPACIDAD</span></h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="horizontal-tabs__content">
                                        <div className="horizontal-tabs__item" id="horizontal-tabs__item-1">
                                            
                                            {/* <p><strong>El Consejo Nacional para la Integración de la Persona con Discapacidad (CONADIS) </strong> 
                                                es el ente rector en materia de discapacidad en el Perú. Entre sus funciones destaca la formulación, ejecución, supervisión y evaluación de las políticas públicas, 
                                                así como la producción de conocimiento y la difusión de información relacionada con la discapacidad, que incluye información actualizada sobre los programas y servicios disponibles para las personas con discapacidad, 
                                                sus familias y las organizaciones que integran (artículo 64 de la Ley 29973).
                                            </p>
                                            <p>Las personas con discapacidad tienen los mismos derechos que el resto de la población: derechos civiles y políticos; derechos sociales, económicos y culturales, 
                                                así como derechos a la accesibilidad. Corresponde al Estado garantizar el pleno ejercicio de estos derechos y procurarles un entorno propicio, accesible y equitativo. 
                                                Para tal efecto, resulta importante contar con información actualizada, sistematizada y automatizada sobre este sector vulnerable, así como también de aquellas organizaciones que lo representan o están vinculadas con esta temática.
                                            </p> */}

                                            <p>La mejor decisión es la decisión informada, la que cuenta con evidencia para fundamentarse. 
                                            Resulta aún más relevante esta afirmación en el sector público, donde nuestras decisiones afectan la vida de millones. En el<strong> Consejo Nacional para la Integración de la Persona con Discapacidad - CONADIS</strong>, atentos a esa necesidad, se ha gestionado con 
                                            diversas entidades públicas, a fin de poner en marcha una Plataforma que no solo compile información, si no que la acerque a la ciudadanía y al tomador de decisiones, a la academia y a la sociedad civil, 
                                            para mostrar que las brechas de acceso a derechos no son sólo un discurso, si no, una realidad palpable y transformable.
                                            </p>
                                            <p>Así, la <strong>Plataforma Única de la Discapacidad</strong> permite interoperar, cotejar, procesar, sistematizar y generar información estadística geográfica y demográfica sobre las personas con discapacidad, resultados de fiscalización e instituciones prestadoras de servicio en esta materia; 
                                            constituyéndose en una herramienta fundamental para la toma de decisiones y la implementación de políticas públicas.
                                            </p>
                                            {/* <p>En esa línea, el Consejo Nacional para la Integración de la Persona con Discapacidad (CONADIS) propuso y desarrolló la Plataforma Única de la Discapacidad, 
                                                que explota la data consignada en el Registro de Personas con Discapacidad – CONADIS, His Discap Web - Ministerio de Salud, Programa Contigo - Ministerio de Inclusión y Desarrollo Social, 
                                                Programa Pensión 65 - Ministerio de Inclusión y Desarrollo Social y la fusiona con la data de la Primera Encuesta Nacional Especializada sobre Discapacidad (ENEDIS 2012) – INEI.
                                            </p>
                                            <p><strong>La Plataforma Única de la Discapacidad,</strong> permite interoperar, cotejar, procesar y generar información estadística referida a las personas con discapacidad en el Perú.
                                                El propósito de dicha plataforma es generar mayor información sobre este grupo vulnerable, que coadyuvé a las políticas públicas, toma de decisiones y producción de conocimiento en esta materia.
                                            </p>
                                            <p>Particularmente la <strong>Plataforma Única de la Discapacidad</strong> cuenta con tableros estadísticos que permiten caracterizar a la población con discapacidad geográficamente (departamento, provincia y distrito) y demográficamente (edad, sexo, nivel de gravedad). 
                                                Adicionalmente, se ha incluido una sección de fiscalización, el cual contiene información del cumplimiento de la cuota de empleo (5%) según región periodo 2021, asimismo la información de las instituciones certificadores del Sector Salud y Educación.    
                                            </p>   */}
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* SIS FISCALIZACIÓN */}


                <section class="section about-us">
					<div class="container">
						<div class="row align-items-center">
							<div class="col-lg-7">
								<div class="heading heading--primary">
                                    <span className="heading__pre-title text-primary">FISCALIZACIÓN</span>
                                    <h2 className="heading__title"><span>RESULTADOS DE FISCALIZACIÓN</span></h2>
								</div>
								{/* <p><strong>Thresher shark rudd African lungfish silverside, Red salmon rockfish grunion, garpike zebra danio king-of-the-salmon banjo catfish."</strong></p>
								<p>Sea chub demoiselle whalefish zebra lionfish mud cat pelican eel. Minnow snoek icefish velvet-belly shark, California halibut round stingray northern sea robin. Southern grayling trout-perch</p>
								<p>Sharksucker sea toad candiru rocket danio tilefish stingray deepwater stingray Sacramento splittail, Canthigaster rostrata. Midshipman dartfish Modoc sucker, yellowtail kingfish basslet. Buri chimaera triplespine northern sea robin zingel lancetfish galjoen fish, catla wolffish, mosshead warbonnet</p>
                                <a class="button button--primary" href="about.html">More About</a> */}
                                <p><strong>La Dirección de Fiscalización y Sanciones del CONADIS</strong> a través de la Sub Dirección de Fiscalización, 
                                    es el órgano facultado para desarrollar actividades de fiscalización, y en atención a ello, fiscaliza el cumplimiento de los artículos de la Ley N° 29973, Ley General de la Persona con Discapacidad.   
                                </p>
                                <p>Es así que para una correcta fiscalización de la acotada Ley, se emitió la Resolución N° 136-2021 -CONADIS/DFS, 
                                    de fecha 19 de noviembre de 2021, que aprueba equipos de fiscalización de la Subdirección de Fiscalización, contándose con los siguiente ejes:
                                </p>
                                <div className="team-item__description font-w900">
                                    <div className="team-item__position">* Eje de empleo. </div>
                                    <div className="team-item__position">* Eje de presupuesto público.</div>
                                    <div className="team-item__position">* Eje de educación.</div>
                                    <div className="team-item__position">* Eje de accesibilidad al medio físico.</div>
                                    <div className="team-item__position">* Eje de accesibilidad en la comunicación y tecnologías de la información y comunicación.</div>
                                    <div className="team-item__position">* Eje de la accesibilidad en los medios de transporte.</div>
                                    <div className="team-item__position">* Unidad de atención de denuncias.</div>
                                 </div>
                                <p>Con la finalidad de brindar información de forma oportuna y confiable, ponemos a su disposición nuestros resultados de Fiscalización.
                                </p>
                                <NavLink to="/fiscalizacion" className="button button--primary"> 
                                    <span>VISUALIZAR TABLERO DE SEGUIMIENTO</span>
                                </NavLink>
                            
							</div>
							<div class="col-lg-6 col-xl-5 offset-xl-12">
								<div class="info-box" >
                                    {/* <img class="img--layout" src="img/about_layout.png" alt="img"/> */}
                                    <img class="img--bg" src="img/separador_01_.png" alt="img"/>
									<h4 class="info-box__title text-transparente">We are Awesome Volounteer Team</h4>
									<p className='text-transparente'>Gray eel-catfish longnose whiptail catfish smalleye squaretail queen danio unicorn 
                                        fish shortnose greeneye fusilier fish silver carp nibbler sharksucker tench lookdown catfish
                                    </p>
                                    {/* <a class="info-box__link text-transparente">Become a volounteer</a> */}
                                    
								</div> 
							</div>
						</div>
					</div>
				</section>

                {/* PRINCIPALES CARACTERISTICAS */}

                <div className="container margin-top"> 
                    <div className="row margin-bottom">
                        <div className="col-12">
                            <div className="heading heading--primary heading--center">
                                <span className="heading__pre-title text-primary"></span>
                                <h2 className="heading__title no-margin-bottom"><span>PRINCIPALES CARACTERÍSTICAS DE LA PLATAFORMA ÚNICA DE LA DISCAPACIDAD</span></h2>
                            </div>
                        </div>
                    </div>
                    <div className="row margin-bottom">
                        <div className="col-6 col-md-4 col-lg-4">
                            <div className="icon-item">
                                <div className="icon-item__img">
                                    <img src="img/escala.png" alt="icon"/>
                                </div>
                                <div className="icon-item__text">
                                    <h6 className='text-primary'>TRANSPARENCIA</h6>
                                    <h6 className='text-dark'>Aumenta la transparencia de información y reduce tiempo en la generación 
                                    de información estadística en materia de discapacidad.</h6>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-6 col-md-4 col-lg-4">
                            <div className="icon-item">
                                <div className="icon-item__img"><img src="img/integracion.png" alt="icon"/></div>
                                <div className="icon-item__text">
                                    <h6 className='text-primary'>INTEGRACIÓN</h6>
                                    <h6 className='text-dark'>Integración con bases de datos que cuenten con información de personas con discapacidad.</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-4">
                            <div className="icon-item">
                                <div className="icon-item__img"><img src="img/reporte.png" alt="icon"/></div>
                                <div className="icon-item__text">
                                    <h6 className='text-primary'>REPORTES</h6>
                                    <h6 className='text-dark'>Reportes digitales a tiempo real con los principales indicadores de la materia de discapacidad.</h6>
                                </div>
                            </div>
                        </div> 
                        <div className="col-6 col-md-4 col-lg-4">
                            <div className="icon-item">
                                <div className="icon-item__img"><img src="img/seguimiento.png" alt="icon"/></div>
                                <div className="icon-item__text">
                                    <h6 className='text-primary'>SEGUIMIENTO - MONITOREO</h6>
                                    <h6 className='text-dark'>Seguimiento y monitoreo del cumplimiento de la Ley 29973 “Ley General de la Persona con Discapacidad.</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-4">
                            <div className="icon-item">
                                <div className="icon-item__img"><img src="img/escalabilidad.png" alt="icon"/></div>
                                <div className="icon-item__text">
                                    <h6 className='text-primary'>ESCALABILIDAD</h6>
                                    <h6 className='text-dark'>Escalabilidad de información al incorporar datos progresivos en materia de discapacidad de los diferentes sectores.</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-4">
                            <div className="icon-item">
                                <div className="icon-item__img"><img src="img/confiabilidad.png" alt="icon"/></div>
                                <div className="icon-item__text">
                                    <h6 className='text-primary'>CONFIABILIDAD</h6>
                                    <h6 className='text-dark'>Confiabilidad de la información tras ser sistematizada y automatizada.</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 


                {/* TABLEROS INSTITUCIONALES */}
                {/* <section className="section elements">
                    <div className="container">
                        <div className="row margin-bottom ">
                            <div className="col-12"> 
                                <div className="heading heading--primary heading--center ">
                                    <span className="heading__pre-title text-primary">TABLEROS</span>
                                    <h1 className="heading__title no-margin-bottom text-primary"><span>TABLEROS INSTITUCIONALES DE SEGUIMIENTO</span></h1>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-lg-4 ">
                                <div className="event-item sistemas">
                                    <div className="event-item__img">
                                        <img className="img--bg" src="img/icono_01.png" alt="REGISTRO"/>
                                    </div>
                                    <div className="event-item__content">
                                        <h6 className="event-item__title text-center">
                                            <a href="#">REGISTRO</a>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 ">
                                <div className="event-item sistemas">
                                    <div className="event-item__img"><img className="img--bg" src="img/icono_02.png" alt="FISCALIZACIÓN"/></div>
                                    <div className="event-item__content">
                                        <h6 className="event-item__title text-center">
                                             <NavLink to="/fiscalizacion" className="main-menu__link"> <span>FISCALIZACIÓN</span></NavLink>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 ">
                                <div className="event-item sistemas">
                                    <div className="event-item__img"><img className="img--bg" src="img/icono_03.png" alt="EJECUCIÓN PRESUPUESTAL 1%"/></div>
                                    <div className="event-item__content">
                                        <h6 className="event-item__title text-center">
                                            <a href="#">EJECUCIÓN PRESUPUESTAL 1%</a>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                
                {/* INFORME ANUAL */}
                {/* <div className="section about-us background--brown2">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4 "> 
                            </div>
                            <div className="col-lg-8">
                                <div className="heading heading--primary margin-top">
                                    <span className="heading__pre-title text-primary">FORMULARIO</span>
                                    <h2 className="heading__title"><span>INFORME ANUAL SOBRE EL USO DEL PRESUPUESTO PÚBLICO EN</span> <span> MATERIA DE DISCAPACIDAD</span></h2>
                                </div>
                                <p><strong>Thresher shark rudd African lungfish silverside, Red salmon rockfish grunion, garpike zebra danio king-of-the-salmon banjo catfish."</strong></p>
                                <p>Sea chub demoiselle whalefish zebra lionfish mud cat pelican eel. Minnow snoek icefish velvet-belly shark, California halibut round stingray northern sea robin. Southern grayling trout-perch</p>
                                <p>Sharksucker sea toad candiru rocket danio tilefish stingray deepwater stingray Sacramento splittail, Canthigaster rostrata. Midshipman dartfish Modoc sucker, yellowtail kingfish basslet. Buri chimaera triplespine northern sea robin zingel lancetfish galjoen fish, catla wolffish, mosshead warbonnet</p>
                                <a className="button button--primary margin-bottom" href="#">VISUALIZAR TABLERO DE SEGUIMIENTO</a>
                            </div>                        
                        </div>
                    </div>
                </div> */}
                
                {/* SISTEMAS */}
                {/* <div className="section events events--front_2">
                     <div className="container">
                        <div className="row margin-bottom">
                            <div className="col-12">
                                <div className="heading heading--primary heading--center">
                                    <span className="heading__pre-title text-primary">INFORMATICA</span>
                                    <h1 className="heading__title text-primary">
                                        <span>SISTEMAS</span>
                                    </h1>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="event-item sistemas">
                                    <div className="event-item__img"><img className="img--bg" src="img/icono_04.png" alt="SISTEMA DE SEGUIMIENTO"/></div>
                                    <div className="event-item__content">
                                        <h6 className="event-item__title text-center">
                                            <a href="#">SISTEMA DE SEGUIMIENTO Y EVALUACIÓN DEL PRESUPUESTO EN MATERIA DE DISCAPACIDAD.</a>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="event-item sistemas">
                                    <div className="event-item__img"><img className="img--bg" src="img/icono_05.png" alt="SISTEMA DE INFORMACIÓN"/></div>
                                    <div className="event-item__content">
                                        <h6 className="event-item__title text-center">
                                            <NavLink to="/fiscalizacion" className="main-menu__link"> <span>SISTEMA DE INFORMACIÓN DE FISCALIZACIÓN Y SANCIONES.</span></NavLink>
                                        </h6> 
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 ">
                                <div className="event-item sistemas">
                                    <div className="event-item__img"><img className="img--bg" src="img/icono_06.png" alt="SISTEMA DE INFORMACIÓN REGIONAL"/></div>
                                    <div className="event-item__content">
                                        <h6 className="event-item__title text-center">
                                            <a href="#">SISTEMA DE INFORMACIÓN REGIONAL</a>
                                        </h6> 
                                        <p><br/></p>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div> */}
                
                {/* CONTACTO */}
                <div className="container margin-top"> 
                    <div className="row margin-bottom">
                        <div className="col-12">
                            <div className="heading heading--primary heading--center">
                                <span className="heading__pre-title text-primary">NUESTROS CANALES</span>
                                <h2 className="heading__title no-margin-bottom"><span>¿CÓMO PODEMOS AYUDARTE?</span></h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 col-md-4 col-lg-3">
                            <div className="icon-item">
                                <div className="icon-item__img"><img src="img/icon_phone.png" alt="icon"/></div>
                                <div className="icon-item__text">
                                    <h6>Linea Gratuita</h6>
                                    <h6 className='text-primary'>0800-00151</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3">
                            <div className="icon-item">
                                <div className="icon-item__img"><img src="img/icon_phone2.png" alt="icon"/></div>
                                <div className="icon-item__text">
                                    <h6>Central Telefónica</h6>
                                    <h6 className='text-primary'>630-5170</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3">
                            <div className="icon-item">
                                <div className="icon-item__img"><img src="img/icon_whatsapp.png" alt="icon"/></div>
                                <div className="icon-item__text">
                                    <h6>WhatsApp Te Informa</h6>
                                    <h6 className='text-primary'>938202248 / 938719927</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3">
                            <div className="icon-item">
                                <div className="icon-item__img"><img src="img/icon_lenguaje.png" alt="icon"/></div>
                                <div className="icon-item__text">
                                    <h6>Intérpretes en Consulta</h6>
                                    <h6 className='text-primary'>DE 8 AM A 5 PM</h6>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div> 
                 
                
            </main>
        </>
    )
}

export default PageMainBody
    
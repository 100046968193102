
import React, {useState,useEffect, Component} from 'react' 
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
// import "../cuotaempleoPage.css"
import axios from 'axios';
import '../../../AppStyle.css'
import Spiner from '../../../componentes/Spiner';

function Certificadoras (props) {
    const [listaCertifica, setListaCertifica] = useState([]); 
    const [dataShow, setDataShow] = useState(false); 

    useEffect(() => {
        getAPICertifica([]);
        const timer = setTimeout(() => {
            getAPICertifica();
          }, 200);
        return () => clearTimeout(timer);
      }, []);

    const getAPICertifica=()=>{
        axios.get(`https://fiscalizacion.conadisperu.gob.pe/api/getInstitucionesCertifica/`).then(response => {
            setListaCertifica(response.data);
            setDataShow(true);
        }).catch(function(error) {
            console.log(error);
        });
    }


    return(
        <div className="col-12">
            <div className="row">
                <div className="col-md-12 text-center"> 
                    <div className="card">
                        <div className="card-header ">
                            <h5 className="card-text font-w900">INSTITUCIONES PRESTADORES DE SERVICIOS DE SALUD CERTIFICADORAS 
                            DE LA DISCAPACIDAD SEGÚN INSTITUCIONES Y POR REGIONES AL 28 DE FEBRERO, 2022</h5>
                        </div> 
                    </div>
                </div> 
                
                <div className="col-md-12 text-center"> 
                    <div className="card"> 
                        <div className="card-body">
                        {  
                            dataShow ?
                            <div className="table-responsive">
                                <div className="col text-right" style={{marginBottom: '5px'}}> 
                                    <ReactHtmlTableToExcel
                                        id="test-table-xls-button"
                                        className="btn btn-danger"
                                        table="tblcertifica"
                                        filename="CONADIS - INSTITUCIONES CERTIFICADORAS"
                                        sheet="INST.CERTIFICADORAS"
                                        buttonText="Descargar Información"
                                    /> 
                                </div>
                                <table id='tblcertifica' border="1" className="table table-striped table-bordered "  width="100%">
                                    <thead>
                                        <tr className='tablita' >
                                            <th colSpan= "10">
                                                INSTITUCIONES PRESTADORES DE SERVICIOS DE SALUD CERTIFICADORAS 
                                                DE LA DISCAPACIDAD SEGÚN INSTITUCIONES Y POR REGIONES AL 28 DE FEBRERO, 2022
                                            </th> 
                                        </tr>
                                        <tr className='tablita' style={{fontSize: '14px'}}> 
                                            <th>REGIONES</th>
                                            <th>TOTAL</th>
                                            <th>ESSALUD</th>
                                            <th>GORES</th>  
                                            <th>MINSA</th>
                                            <th>MUNICIPALIDAD PROVINCIAL</th>  
                                            <th>PRIVADO</th>
                                            <th>SANIDAD DE LA PNP</th>  
                                            <th>SANIDAD DE LA MARINA DE GUERRA DEL PERÚ</th>
                                            <th>SANIDAD DEL EJERCITO DEL PERÚ</th>                                            
                                        </tr> 
                                    </thead>
                                    <tbody style={{fontSize: '15px'}}>
                                        {
                                            listaCertifica.map((x,index)=>{                           
                                                return( 
                                                    <tr key={index}>                                         
                                                        <td className="colorNegrita" >{x.region}</td>  
                                                        <td className="colorNegrita" >{x.info1 || 0}</td>
                                                        <td className="colorNegrita" >{x.info2 || 0}</td>
                                                        <td className="colorNegrita" >{x.info3 || 0}</td>
                                                        <td className="colorNegrita" >{x.info4 || 0}</td>
                                                        <td className="colorNegrita" >{x.info5 || 0}</td>
                                                        <td className="colorNegrita" >{x.info6 || 0}</td>
                                                        <td className="colorNegrita" >{x.info7 || 0}</td>
                                                        <td className="colorNegrita" >{x.info8 || 0}</td>
                                                        <td className="colorNegrita" >{x.info9 || 0}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr className='tablita'>
                                            <th colSpan= "10" className="colorNegritaLeft">
                                                1/ Comprende los 43 distritos que conforman la provincia de Lima.<br/>
                                                2/ Comprende a las provincias de: Barranca, Cajatambo, Canta, Cañete, Huaral, Huarochiri, Huaura, Oyón y Yauyos.
                                                <br/>
                                                <a className="aside-socials__link text-primary" href="https://conadisperu.gob.pe/observatorio/servicios/establecimientos-certificadores-de-la-discapacidad/" target="_blank">
                                                    IR A FUENTE
                                                </a>
                                            </th> 
                                        </tr>
                                    </tfoot>
                                </table>   
                            </div>   
                            : <Spiner/>  
                        }                                         
                        </div> 
                    </div> 
                </div> 
                <div className="col-md-12 text-center">
                    <div className="card">
                        <div className="card-body"> 
                        {  
                            dataShow ?
                            <div className="donation-item">
                                <div className="col text-right"> 
                                    <ReactHtmlTableToExcel
                                        id="test-table-xls-button"
                                        className="btn btn-danger"
                                        table="tblcertifica"
                                        filename="CONADIS - INSTITUCIONES CERTIFICADORAS"
                                        sheet="INST.CERTIFICADORAS"
                                        buttonText="Descargar Información"
                                    /> 
                                </div>
                                <div className="donation-item__img2">
                                    <img className="img--bg" style={{position: 'initial'}} src="img/grafico02.png" alt="img"/>
                                </div>
                            </div>
                            : <Spiner/>  
                        } 
                        </div> 
                    </div>
                </div>
                
            </div>
        </div>
    );
}

export default Certificadoras;
import React, {useState,useEffect} from 'react'
import URL from '../../url.json'
import PieChart, {
  Legend,
  Export,
  Series,
  Label,
  Title,
  SmallValuesGrouping,
  Font,
  Connector,
  Tooltip,
} from 'devextreme-react/pie-chart';
import axios from 'axios';
import headers from '../../headers.json';
import LoadingComponent from '../../componentes/loadingComponent/loadingComponent'
import '../../dashboard.css';
import 'devextreme/dist/css/dx.light.css';

const BrechaPorCertificar=(props) => {
  
  useEffect(() => {
    getBrechaPcdENEDISSinCertificado()
  },[])
  var DateToday = Date.now()
  var Datenow= new Date(DateToday).toLocaleDateString().split('/')
  let DateFinal =  parseInt(Datenow[0])-1 +'/'+parseInt(Datenow[1]) + '/' +parseInt(Datenow[2]);

  const [dataSource, setdataSource] = useState([]);
  const [ErrorBrechaPcd, setErrorBrechaPcd] = useState(null);

  const [dataBrecha, setdataBrecha] = useState('--');
  const [dataToolTip, setdataToolTip] = useState([{'pcd':0,'pcd_sin_certificado':0,'total':0 }]);

  const getBrechaPcdENEDISSinCertificado=(cod)=>{
    
    setdataSource([])
    setdataToolTip([{'pcd':0,'pcd_sin_certificado':0,'total':0 }])
    setErrorBrechaPcd(null)

      axios.post(URL.apiURL+'getBrechaPCDENEDISSinCertificadoDiscapacidad',{codubigeo:cod},headers)
      .then(response => {
          //console.log('response Brecha Sin Certificado', response)
          convertBrechaPcd(response.data)
      })
      .catch(err=> {
          // console.log('error Brecha Sin Certificado', err)
          setErrorBrechaPcd(err);
      })
  }

  const convertBrechaPcd= (data)=>{
    let datos = data.data;

    //console.log('Convirtiendo datos', datos, datos[0].pcd)

    if(datos.length>0){

      let newData = [];
      newData.push({type: 'CON CERTIFICADO',num: datos[0].pcd})
      newData.push({type: 'SIN CERTIFICADO',num: datos[0].pcd_sin_certificado})
      setdataSource(newData)
      setsetdataBrecha(datos)

    }else{
      setdataSource([])
      setErrorBrechaPcd({error:'NO HAY DATOS'})
    }


  }

  const setsetdataBrecha = (datos)=>{
    let total = datos[0].pcd + datos[0].pcd_sin_certificado;
    let porcentaje = (datos[0].pcd_sin_certificado / total)*100;
    
    let newdataTooltip = [];
    newdataTooltip.push({'pcd':datos[0].pcd,'pcd_sin_certificado':datos[0].pcd_sin_certificado,'total':total })
    setdataToolTip(newdataTooltip)
    setdataBrecha(porcentaje)
  }

  const BrechaPcdComponent=()=>{
        
    if(dataSource.length>0){        

        return <> 
            <PieChart id="pie"
            palette={['rgb(0, 69, 97)', 'rgb(255, 111, 49)']}
            dataSource={dataSource}
            title=""
            // title="ENEDIS: brecha de la población por certificar"
          >
          <Title >
              <Font color="black" size="12" />
          </Title>
          <Legend
            orientation="horizontal"
            itemTextPosition="right"
            horizontalAlignment="center"
            verticalAlignment="top"
            columnCount={4} />
          <Export enabled={true} fileName="brecha_por_certificar" />
          <Series argumentField="type" valueField="num">
            <Label
              visible={true}
              position="columns"
              customizeText={customizeText}
              format="fixedPoint"
              >
                
              <Font size={12} />
              <Connector visible={true} width={0.5} />
            </Label>
            <SmallValuesGrouping threshold={4.5} mode="smallValueThreshold" />
          </Series>
          <Tooltip
            enabled={true}
            contentRender={TooltipTemplate}
          />
        </PieChart>
        
        <div className="title"> <span className="legendBrecha"></span> TOTAL (100%): {formatNumber(dataToolTip[0].total)} PERSONAS CON DISCAPACIDAD</div>
        <div className="title"> <span className="legendBrecha BrechaSinCertificar"></span> El {Number(dataBrecha).toFixed()} % ({formatNumber(dataToolTip[0].pcd_sin_certificado)}), SEGÚN LA ENEDIS 2012, NO CUENTAN CON CERTIFICADO DE DISCAPACIDAD.</div>
        </>
    }
    else{
        return <div className='de--title row' style={{marginLeft:'8%'}}><LoadingComponent configuracion="" error={ErrorBrechaPcd} funcionrefresh={getBrechaPcdENEDISSinCertificado}  ></LoadingComponent></div>
    }
  }


 /* const dataSource = [
    {
      type: 'PCD',
      num: 400630,
    }, 
    {
      type: 'SIN CERTIFICADO',
      num: 1174772,
    }, 

];*/
  

  return(

    BrechaPcdComponent()
  
  )
}

function customizeText(arg) {
  return `<b>${arg.argumentText}:</b> ${arg.percentText}`;
}

const formatNumber = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
}).format;

function TooltipTemplate(info){
  return (
    <div className="state-tooltip">
      <div className="capital">
        <span className="caption"><b>CANTIDAD</b></span>
      </div>
      <div className="population">
        {formatNumber(info.value)} PERSONAS
      </div>
    </div>
  );
}
export default BrechaPorCertificar;
import React, {useEffect,useRef,useState } from 'react'
import axios from 'axios';
import ResumenTablaGastos from './resumenGastosMapa';
import mapaPeru from '../mapaperu.json' 
import { Map, TileLayer, Marker, Popup, Polygon, GeoJSON, MapControl } from 'react-leaflet'
import MapBoxGLLayer from './MapBoxGLLayer';
import {LeyendaMapaGastos} from './leyendaGastosMapa'; 
import {AppConstant} from "./../../../constants";

import 'leaflet/dist/leaflet.css'
import "../cuotaempleoPage.css"
import Spiner from '../../../componentes/Spiner';

const GastosPresupuestoPage = (props) => {     
    const { filtroAnio } = props;
    const [listaSedesGastos, setListaSedesGastos] = useState([]); 
    const [gastosShow, setGastosShow] = useState(true); 

    const [map, setMap] = useState(null);

    const GeoJSONEL = useRef(null);
    const MapEL = useRef(null);
    const MapBoxEl = useRef(null);
    const MapInfoEl = useRef(null);

    useEffect(() => {
        setListaSedesGastos(null);
        const timer = setTimeout(() => {
            getAPIGastosPresupuesto();
          }, 200);
        return () => clearTimeout(timer);
      }, [filtroAnio]);

    const getAPIGastosPresupuesto=()=>{debugger;
        let url = 'getNuevoRegion';
        if(filtroAnio == 2021) {
            url = 'getMonitoreoPresupuestoGastos';
        }
        axios.get(`${AppConstant.apiUrl}/${url}`, { params: {anio: filtroAnio}}).then(response => {
            setListaSedesGastos(response.data);
        }).catch(function(error) {
            console.log(error);
        });
    }

    const onEachFeature = (feature, layer) => {
        layer.on({
        //   mouseover: highlightFeature,
        //   mouseout: resetHighlight,
            // click: clickFeature.bind(this)
        });
        layer.bindTooltip(
            "<div><b>" +feature.properties.NOMBDEP +"</b></div>",
            { permanent: true, direction:'center', className: 'countryLabel'}
        ); 
    }
    const highlightFeature = (e) => {
        var layer = e.target;
        layer.setStyle({
            weight: 5,
            color: "#4A4C70",
            dashArray: "",
            fillOpacity: 0.7
        });

        layer.bringToFront();    
        // MapInfoEl.current.setInfo(layer.feature.properties);
    }   
    const resetHighlight = (event) => {
        GeoJSONEL.current.leafletElement.resetStyle(event.target);
        // MapInfoEl.current.setInfo();
    } 
    
    const style = (feature) => {
        return ({
            weight: 5,
            opacity: 1,
            fillOpacity: 1,
            color:'#000000',
            fillColor:getColor(feature.properties, listaSedesGastos),
        });
    }
    const getColor = (mapa, sedes) =>{
        for (let index = 0; index < sedes.length; index++) {
            if(sedes[index].cod_sede == mapa.FIRST_IDDP){ 
                if(gastosShow){
                    if(filtroAnio == 2021) {
                        if(parseInt(sedes[index].tr_cumple01 || 0) > 0 && parseInt(sedes[index].tr_cumple02 || 0) == 0){
                            return '#03E366' //Verde
                        }else if(parseInt(sedes[index].tr_cumple01 || 0) > 0 && parseInt(sedes[index].tr_cumple02 || 0) > 0){
                        return '#FDF669' //Amarillo
                        }else if(parseInt(sedes[index].tr_cumple01 || 0) == 0 && parseInt(sedes[index].tr_cumple02 || 0) > 0){
                        return '#F56273' //Rojo
                        }else if(parseInt(sedes[index].tr_cumple01 || 0) == 0 && parseInt(sedes[index].tr_cumple02 || 0) == 0){
                        return '#f0e967' //Dorado
                        }
                    } else {
                        if(!sedes[index].cumplimiento_capi) {
                            return '#FDF669' //Amarillo
                        }
                       let arr = sedes[index].cumplimiento_capi.split('%');
                       if(arr.length == 2) {
                           let cumple = parseFloat(arr[0]);
                           if(cumple >= 0.5) {
                            return '#03E366' //Verde
                           } else if(cumple > 0 && cumple < 0.5) {
                            return '#F56273' //Rojo
                           } else {
                            return '#FDF669' //Amarillo
                           }
                       }
                    }
                }
            }
        }
    };    

    const MapaGastosPresupuesto=()=>{
        if(listaSedesGastos){
            return( 
                <Map  
                    ref={MapEL} 
                    center={[-9.378835, -74.906473]} 
                    zoom={6} 
                    maxZoom= {10}
                    minZoom= {6}
                    doubleClickZoom={false}
                    scrollWheelZoom={false}
                    attributionControl={false}
                    // zoomControl={false}
                    // dragging={false}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        // url="/img/blanco.png"
                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
                    />
                    {/* <MapInfo ref={MapInfoEl}/> */}
                    <MapBoxGLLayer
                        ref={MapBoxEl} 
                        accessToken={'pk.eyJ1IjoieGN1bGxvYXgiLCJhIjoiY2wyZndoeHR1MDBuNTNsbHIwMzc5MHVlciJ9.5_UMSagakftvT7JmY7hc8w'}
                        // accessToken={"pk.eyJ1IjoiZmxvcnZhbmRla2VyY2tob3ZlIiwiYSI6ImNqdGZyMmtrejAxYWw0M3A2OGtwdTMxNWEifQ.5U-KSDZfyKNC_Z74fEWj6g"}
                        // style="/img/blanco.png"
                    />
                    <GeoJSON ref={GeoJSONEL}  data={mapaPeru.features} style={style} onEachFeature={onEachFeature} />
                    <LeyendaMapaGastos/>
                </Map>
            ) 
        } else{
            return <Spiner/>
        }
    }
    const TablaGastosPresupuesto=()=>{
        if(listaSedesGastos){
            return <ResumenTablaGastos listaSedes={listaSedesGastos} filtroAnio={filtroAnio}/>            
        }else{
            return <Spiner/>
        }
    }
    
    return (
        <div className="row margin-bottom">
            <div className="col-12">
                <div className="row" id="cuota_empleo">
                    <div className="col-md-12 text-center margin-bottom"> 
                        <div className="card">
                            <div className="card-header ">
                                <h6 className="card-text text-primary font-w900">CUMPLIMIENTO DEL PRESUPUESTO PÚBLICO (0.5% PARA GASTOS OPERATIVOS - OMAPED/OREDIS)</h6>
                            </div> 
                        </div>
                    </div> 
                        
                    <div className="col-md-5 text-center">
                        {gastosShow ?
                        <div className="card">
                            <div className="card-header ">
                                <p className="card-text text-dark font-w900">INSTITUCIONES FISCALIZADAS A NIVEL NACIONAL</p>
                            </div>
                            <div className="card-body">
                                {MapaGastosPresupuesto()}                                
                            </div> 
                        </div>
                        : ''}

                    </div>
                    <div className="col-md-7 text-center">
                        {gastosShow ? 
                        <div className="card">
                            <div className="card-header">
                                <p className="card-text text-dark font-w900">CUMPLIMIENTO DEL PRESUPUESTO PÚBLICO (0.5% PARA GASTOS OPERATIVOS - OMAPED/OREDIS)</p>
                            </div>
                            <div className="card-body">
                                {TablaGastosPresupuesto()}                                                
                            </div> 
                        </div>
                        : ''}
                    </div> 
                </div>
            </div>
        </div>
    )
}

export default GastosPresupuestoPage
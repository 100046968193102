import React from 'react'

const ReporteDLaboral = (props) => {
    var DateToday = Date.now()
    var Datenow= new Date(DateToday).toLocaleDateString().split('/')
    let DateFinal =  (parseInt(Datenow[0])-1 === 0 ? 1 : parseInt(Datenow[0])-1)+'/'+parseInt(Datenow[1]) + '/' +parseInt(Datenow[2])
    return (
        <div style={{display:"none"}}>
        <table className="tableMapa" id="table-Dlaboral">

                <thead>
                    <tr >
                        <th >Tipo</th>
                        <th >Total de la región</th>
                        <th >Porcentaje de la región</th>


                    </tr>
                </thead>

                <tbody>

                    {
                        props.registros.map((x,index)=>{

                          
                                return <tr key={index}>
                                    <td >{x.nombre} <div className="maptable-line"></div></td>
                                    <td >{x.cantidad} <div className="maptable-line"></div></td>
                                    <td >{x.porcentaje} <div className="maptable-line"></div></td>
                                </tr>
            
                        })


                    }

                    <td colSpan="3">Fuente: CONADIS {DateFinal}, MINSA al 31/12/2021 y CONTIGO a 31/12/2021</td>
                </tbody>

                </table>
        </div>
    )
}

export default ReporteDLaboral
import React, { useState, useEffect } from 'react'

//estilos
import distribucionEdad from './distribucionEdad.css'
import '../../dashboard.css';

//graficos
import Distribucion from '../../graficos/distribucion/distribucion'
import SimplePopoverHistorial from '../../componentes/popover/popoverhistorial'


//herramienta reporte
import ReactHTMLTableToExcel from 'react-html-table-to-excel';


const DistribucionEdad = (props) => {
    const [DistEdad, setDistEdad] = useState(props.DistEdad);
    const [isDashboard, setisDashboard] = useState(true)
    var DateToday = Date.now()
    var Datenow= new Date(DateToday).toLocaleDateString().split('/')
    var DateFinal =  parseInt(Datenow[0])-1 +'/'+parseInt(Datenow[1]) + '/' +parseInt(Datenow[2])

    useEffect(() => {
        let pathname = window.location.pathname
        //console.log(pathname)
        // setisDashboard(!(pathname.indexOf("mapa") > -1))
        // setisDashboard(true)
        setDistEdad(props.DistEdad);
    }, [props])

    const descargarExcel=()=>{
        document.getElementById("descargar-edad-mapa").click()
    }

    const descargarExcel2=()=>{
        document.getElementById("descargar-Dedad-mapa").click()
    }

    return (
        <div>
            {!isDashboard &&
                <>
                    {/* <div className="hidden--buton"></div>                    
                    <div className="excel-icon" >
                        <ReactHTMLTableToExcel
                                id="descargar-edad-mapa"
                                className="buton-excel red--button"
                                table="table-edad"
                                filename="CONADIS PUD Edad "
                                sheet="tablexls"
                                buttonText="Descargar"/>
                    </div>               */}
                </>
            }

            { isDashboard &&
                <>
                    {/* <div className="hidden--buton"></div>                
                    <div className="excel-icon-dash" >
                    <ReactHTMLTableToExcel
                            id="descargar-Dedad-mapa"
                            className="buton-excel red--button"
                            table="table-Dedad"
                            filename="CONADIS PUD Edad "
                            sheet="tablexls"
                            buttonText="Descargar"/>                    
                    </div>                 */}
                </>
            }
            <div className="card">
                <div className="card-header "> 
                    <p className="card-text text-primary font-w900">POBLACIÓN CON DISCAPACIDAD: DISTRIBUCIÓN POR GRUPO DE EDAD</p>
                </div>
                <div className="card-body">
                
                    { isDashboard ?                     
                        <div class="col text-right">
                            {/* <SimplePopoverHistorial typegraphic={'historicage'}/> */} 
                            <ReactHTMLTableToExcel
                                id="descargar-Dedad-mapa"
                                className="btn btn-danger btn-xs"
                                table="table-Dedad"
                                filename="CONADIS PUD EDAD "
                                sheet="CONADIS PUD EDAD"
                                buttonText="Descargar"
                            />
                        </div> 
                        : <ReactHTMLTableToExcel
                            id="descargar-edad-mapa"
                            className="btn btn-danger btn-xs"
                            table="table-edad"
                            filename="CONADIS PUD EDAD "
                            sheet="CONADIS PUD EDAD"
                            buttonText="Descargar"
                        />
                    }
                    <Distribucion objetos={DistEdad}></Distribucion>
                </div>
            </div> 
        </div>
    )
}

export default DistribucionEdad

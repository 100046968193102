 
import React, {useEffect,useRef,useState } from 'react'
import axios from 'axios';
import '../AppStyle.css';
import PageHeader from '../componentes/header';
import CuotaempleoPage from './ejeEmpleo/empleo/cuotaEmpleoPage';
import RubroEmpleoPage from './ejeEmpleo/rubro/rubroEmpleoPage';
import SliderPrincipal from '../componentes/SliderPrincipal';
import GastosPresupuestoPage from './ejePresupuesto/gastos/gastosPresupuestoPage';
import InversionPresupuestoPage from './ejePresupuesto/inversion/inversionPresupuestoPage';
import EjeEducacionPage from './ejeEducacion/ejeEducacionPage';
import EjeAccesibilidadPage from './ejeAccesibilidad/ejeAccesibilidad';


const PageFiscalizacion = (props) => {
    const [cuotaShow, setCuotaShow] = useState(true); 
    const [rubroShow, setRubroShow] = useState(false); 
    const [ejeEmpleo, setEJEEmpleo] = useState(true);
    const [filtroEmpleo, setFiltroEmpleo] = useState(true);

    const [ejeEducacion, setEjeEducacion] = useState(false);
    const [educacionShow, setEduacionShow] = useState(false);

    const [ejeAccesibilidad, setEjeAccesibilidad] = useState(false);
    const [accesibilidadShow, setAccesibilidadShow] = useState(false);
    

    const [ejePresupuesto, setEJEPresupuesto] = useState(false);
    const [gastosShow, setGastosShow] = useState(false);
    const [inversionShow, setInversionShow] = useState(false);
    const [filtroPresupuesto, setFiltroPresupuesto] = useState(false);
    //AGREGADO
    const [filtroEducacion, setFiltroeEducacion] = useState(true);
    const [filtroAnio, setFiltroAnio] = useState(2021);

    const [filtroAnioPresupuesto, setFiltroAnioPresupuesto] = useState(2021);

    

    const clickEJEEmpleo = (e) => {
        e.preventDefault();
        setCuotaShow(true); 
        setEJEEmpleo(true);
        setFiltroEmpleo(true);
        //setFiltroAnio(true);
        setEjeAccesibilidad(false);
        setAccesibilidadShow(false);
        setEjeEducacion(false);
        setEduacionShow(false);
        setEJEPresupuesto(false);
        setFiltroPresupuesto(false);
        //AGREGADO
        setFiltroeEducacion(false);
        setGastosShow(false);
        setInversionShow(false); 
    }
    
    const clickEJEAccesibilidad = (e) => {
        e.preventDefault();
        setEJEEmpleo(false);
        setCuotaShow(false); 
        setRubroShow(false);  
        setFiltroEmpleo(false);
        setEjeAccesibilidad(true);
        setAccesibilidadShow(true);
        setEjeEducacion(false);
        setEduacionShow(false);
        setEJEPresupuesto(false);
        setFiltroPresupuesto(false);
         //AGREGADO
         setFiltroeEducacion(false);
        setGastosShow(false);   
        setInversionShow(false);  
    }
    const clickEJEEducacion = (e) => {
        e.preventDefault();
        setEJEEmpleo(false);
        setCuotaShow(false); 
        setRubroShow(false);    
        setFiltroEmpleo(false); 
        setEjeAccesibilidad(false);
        setAccesibilidadShow(false);
        setEjeEducacion(true);
        setEduacionShow(true);
        setEJEPresupuesto(false);
        setFiltroPresupuesto(false);
         //AGREGADO
         setFiltroeEducacion(false);
        setGastosShow(false);
        setInversionShow(false);
    }
    const clickEJEPresupuesto = (e) => {
        e.preventDefault();
        setEJEEmpleo(false);
        setCuotaShow(false); 
        setRubroShow(false);
        setFiltroEmpleo(false); 
        setEjeAccesibilidad(false);
        setAccesibilidadShow(false);
        setEjeEducacion(false);
        setEduacionShow(false);
        setEJEPresupuesto(true); 
        setFiltroPresupuesto(true);
         //AGREGADO
         setFiltroeEducacion(false);
        setGastosShow(true);  
        setInversionShow(false);    
    }

    const clickCUOTAEMPLEO = (e) => {
        e.preventDefault();
        setEJEEmpleo(true);
        setCuotaShow(true);
        setRubroShow(false);
        setFiltroEmpleo(true);
        setEjeAccesibilidad(false);
        setAccesibilidadShow(false);
        setEjeEducacion(false);
        setEduacionShow(false);
        setEJEPresupuesto(false);
        setFiltroPresupuesto(false);
         //AGREGADO
         setFiltroeEducacion(false);
        setGastosShow(false);
        setInversionShow(false);
    }
    const clickRUBROEMPLEO = (e) => {
        e.preventDefault();
        setEJEEmpleo(true);
        setCuotaShow(false);
        setRubroShow(true);
        setFiltroEmpleo(true);
        setEjeAccesibilidad(false);
        setAccesibilidadShow(false);
        setEjeEducacion(false);
        setEduacionShow(false);
        setEJEPresupuesto(false);
        setFiltroPresupuesto(false);
         //AGREGADO
         setFiltroeEducacion(false);
        setGastosShow(false);
        setInversionShow(false);
    }

    const clickPresGastos = (e) => {
        e.preventDefault();
        setEJEEmpleo(false);
        setCuotaShow(false);
        setRubroShow(false);
        setFiltroEmpleo(false);
        setEjeAccesibilidad(false);
        setAccesibilidadShow(false);
        setEjeEducacion(false);
        setEduacionShow(false);
        setEJEPresupuesto(true);
        setFiltroPresupuesto(true);
         //AGREGADO
         setFiltroeEducacion(false);
        setGastosShow(true);
        setInversionShow(false);
    }
    const clickPresInversion = (e) => {
        e.preventDefault();
        setEJEEmpleo(false);
        setCuotaShow(false);
        setRubroShow(false);
        setFiltroEmpleo(false);
        setEjeAccesibilidad(false);
        setAccesibilidadShow(false);
        setEjeEducacion(false);
        setEduacionShow(false);        
        setEJEPresupuesto(true);
        setFiltroPresupuesto(true);
         //AGREGADO
         setFiltroeEducacion(false);
        setGastosShow(false);
        setInversionShow(true);
    }

    return (
        <>
         <PageHeader activeFisca={true}/>
            {/* SIS FISCALIZACIÓN */}
            <SliderPrincipal/>
            {/* <section className="section shop"> */}
            <div className="section shop margin-top"> 
                <div className="container_extra">
                    <div className="row margin-bottom ">
                        <div className="col-12"> 
                            <div className="heading heading--primary heading--center">
                                <div>
                                    <h2 className="heading__title no-margin-bottom text-primary"><span>RESULTADOS DE FISCALIZACIÓN</span></h2>
                                    {/* <h4 className="no-margin-bottom text-primary"><span> SEGUIMIENTO DEL CUMPLIMIENTO DE LA CUOTA DE EMPLEO</span></h4> */}
                                </div>                                
                                {/* <h2 className="heading__title margin-bottom"><span>RESULTADOS</span> <span> DE FISCALIZACIÓN</span></h2>
                                <span className="heading__pre-title">SEGUIMEMIENTO DEL CUMPLIMIENTO DE LA CUOTA DE EMPLEO / ESTE EJE SE ENCUENTRA EN PROCESO DE IMPLEMENTACIÓN</span> */}
                            </div>
                        </div>
                    </div> 
                    <div className="row">                        
                        <div className="col" id='idEmpleo' onClick={clickEJEEmpleo}>
                            <div className={`sistemas team-item team-item--rounded${ejeEmpleo ? " fisca_activo" : ""}`}>
                                <div className="team-item__img-holder"> 
                                    <div className="team-item__img">
                                        <img className="img--bg" src="img/eje_empleo.png" alt="EJE EMPLEO"/>
                                    </div>
                                </div>
                                <div className="team-item__description">
                                    <div className="team-item__name"><h5>EJE DE EMPLEO</h5></div>
                                    <div className="team-item__position text-dark">FISCALIZACIÓN</div>
                                </div>
                            </div>
                        </div>
                        <div className="col" id='idAccesibilidad' onClick={clickEJEAccesibilidad}>
                            <div className={`sistemas team-item team-item--rounded${ejeAccesibilidad ? " fisca_activo" : ""}`}>
                                <div className="team-item__img-holder">
                                    <div className="team-item__img">
                                        <img className="img--bg" src="img/eje_accesibilidad.png" alt="EJE ACCESIBILIDAD"/>
                                    </div>
                                </div>
                                <div className="team-item__description">
                                    <div className="team-item__name"><h5>EJE DE ACCESIBILIDAD</h5></div>
                                    <div className="team-item__position text-dark">FISCALIZACIÓN</div>
                                </div>
                            </div>
                        </div>
                        <div className="col" id="idEducacion" onClick={clickEJEEducacion}>
                            <div className={`sistemas team-item team-item--rounded${ejeEducacion ? " fisca_activo" : ""}`}>
                                {/* <ul className="team-item__socials">
                                    <li className="item--active"><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                    <li><a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    <li><a href="#"><i className="fa fa-youtube-play" aria-hidden="true"></i></a></li>
                                </ul> */}
                                <div className="team-item__img-holder">
                                    {/* <img className="img--layout" src="img/team_5.png" alt="BASE EJE EDUCACION"/> */}
                                    <div className="team-item__img">
                                        <img className="img--bg" src="img/eje_educacion.png" alt="EJE EDUCACION"/>
                                    </div>
                                </div>
                                <div className="team-item__description">
                                    <div className="team-item__name"><h5>EJE DE EDUCACIÓN</h5></div>
                                    <div className="team-item__position text-dark">FISCALIZACIÓN</div>
                                </div>
                            </div>
                        </div>
                        <div className="col" id="idPresupuesto" onClick={clickEJEPresupuesto}>
                            <div className={`sistemas team-item team-item--rounded${ejePresupuesto ? " fisca_activo" : ""}`}>
                                <div className="team-item__img-holder">
                                    <div className="team-item__img">
                                        <img className="img--bg" src="img/eje_presupuesto.png" alt="EJE PRESUPUESTO"/>
                                    </div>
                                </div>
                                <div className="team-item__description">
                                    <div className="team-item__name"><h5>EJE DE PRESUPUESTO</h5></div>
                                    <div className="team-item__position text-dark">FISCALIZACIÓN</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {filtroEmpleo ?
                        <div className="row align-items-baseline">
                            <div className="col-12 text-right">
                                <ul className="shop-filter">
                                    <li className="shop-filter__item shop-filter__item--active text-primary">
                                        <span><h6 className='text-primary'><i className="fa fa-info-circle" aria-hidden="true"></i>&nbsp;SEGÚN RUBRO DE FISCALIZACIÓN</h6></span>
                                        <ul className="shop-filter__sub-list">
                                            <li><a href="#" onClick={clickCUOTAEMPLEO}>* CUOTA DE EMPLEO (5%)</a></li>
                                            <li><a href="#" onClick={clickRUBROEMPLEO}>* RUBRO DE DISCAPACIDAD (15%)</a></li> 
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div> : ''
                    }
                    {filtroEmpleo ?
                        <div className="row align-items-baseline">
                            <div className="col-12 text-right">
                                <ul className="shop-filter">
                                    <li className="shop-filter__item shop-filter__item--active text-primary">
                                        <span><h6 className='text-primary'><i className="fa fa-info-circle" aria-hidden="true"></i>&nbsp;SELECCIONE EL AÑO</h6></span>
                                        <ul className="shop-filter__sub-list">
                                            <li><a href="#" onClick={(e)=>{ 
                                                clickCUOTAEMPLEO(e);
                                                setFiltroAnio(2021);
                                            }}>* AÑO 2021</a></li>
                                            <li><a href="#" onClick={(e) => {
                                                clickCUOTAEMPLEO(e);
                                                setFiltroAnio(2022);
                                            }}>* AÑO 2022</a></li> 
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div> : ''
                    } 
                    
                    {cuotaShow ? <CuotaempleoPage filtroAnio={filtroAnio} /> : ''}
                    {rubroShow ? <RubroEmpleoPage /> : ''}
                    {accesibilidadShow ? <EjeAccesibilidadPage /> : ''} 
                    {educacionShow ? <EjeEducacionPage /> : ''} 
                     {filtroPresupuesto ?
                        <div className="row align-items-baseline margin-top">
                            <div className="col-12 text-right">
                                <ul className="shop-filter">
                                    <li className="shop-filter__item shop-filter__item--active text-primary">
                                        <span><h6 className='text-primary'><i className="fa fa-info-circle" aria-hidden="true"></i>&nbsp;SEGÚN RUBRO DE FISCALIZACIÓN</h6></span>
                                        <ul className="shop-filter__sub-list">
                                            <li><a href="#" onClick={clickPresGastos}>* GASTOS OPERATIVOS</a></li>
                                            <li><a href="#" onClick={clickPresInversion}>* INVERSIONES</a></li> 
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div> : ''
                    }
                     {filtroPresupuesto ?
                        <div className="row align-items-baseline">
                            <div className="col-12 text-right">
                                <ul className="shop-filter">
                                    <li className="shop-filter__item shop-filter__item--active text-primary">
                                        <span><h6 className='text-primary'><i className="fa fa-info-circle" aria-hidden="true"></i>&nbsp;SELECCIONE EL AÑO</h6></span>
                                        <ul className="shop-filter__sub-list">
                                            <li><a href="#" onClick={(e)=> {
                                                setFiltroAnioPresupuesto(2021);
                                                clickPresGastos(e);
                                            }}>* AÑO 2021</a></li>
                                            <li><a href="#" onClick={(e)=>{
                                                 setFiltroAnioPresupuesto(2022);
                                                 clickPresGastos(e);
                                            }}>* AÑO 2022</a></li> 
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div> : ''
                    } 
                    {gastosShow ? <GastosPresupuestoPage filtroAnio={filtroAnioPresupuesto} /> : ''} 
                    {inversionShow ? <InversionPresupuestoPage /> : ''}  

              </div>
            </div>
            {/* </section> */}
        </>
    )
}

export default PageFiscalizacion
    
import React, {useState,useEffect} from 'react'
import { Popover } from 'react-tiny-popover'
import PieChart, {
  Legend,
  Export,
  Series,
  Label,
  Title,
  SmallValuesGrouping,
  Font,
  Connector,
  Tooltip,
} from 'devextreme-react/pie-chart';
import '../../dashboard.css';
import './DistributionGender.css'

//graficos


function customizeText(arg) {
  return `<b>${arg.argumentText}:</b> ${(arg.percent *100).toFixed(2)}%`;
}

function TooltipTemplate(info){
  return (
    <div className="state-tooltip">
      <div className="capital">
        <span className="caption"><b>Cantidad</b></span>
      </div>
      <div className="population">
        {formatNumber(info.value)} personas
      </div>
    </div>
  );
}

const formatNumber = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
}).format;

const imgSexo = () => {
    return (

            <div class="row justify-content-between d-flex" style={{marginBottom:'13px'}}>
                            
                <div class="col-6 d-flex">
                    <i class="fa fa-male" style={{fontSize:'30px',color:'blue'}}></i>
                    <label style={{marginLeft:'5px'}}>&nbsp;MASCULINO</label>
                </div>
                
                <div class="col-4 d-flex" >
                    <label style={{marginLeft:'18%'}}>FEMENINO&nbsp;</label>
                    <i class="fa fa-female" style={{fontSize:'30px',color:'#ff00ff'}}></i>
                </div>
            </div>

        )
}

const convertData = (dt) => {
    var hombre = 0;
    var mujer = 0;
    for (var i = 1; i < dt.length; i++) {
        hombre += dt[i].Cantidad != undefined ? dt[i].Cantidad : 0;
        mujer += dt[i].CantidadMujer != undefined ? dt[i].CantidadMujer : 0;
    }

    let newData = [];

    newData.push({type: 'Hombres',num: hombre})
    newData.push({type: 'Mujeres',num: mujer})
    
    return newData
}


const DistributionGender= (props) => {
console.log("miercoles",props);
    const [DistGender, setDistGender] = useState(props.DistGender);
    const [isPopoverOpen,setIsPopoverOpen] = useState(false)
    var DateToday = Date.now()
    var Datenow= new Date(DateToday).toLocaleDateString().split('/')
    var DateFinal =  parseInt(Datenow[0])-1 +'/'+parseInt(Datenow[1]) + '/' +parseInt(Datenow[2])
    let filtro = DistGender.filter(valor=>{
        return valor.RangoEdad !== 'Total'
    })


    if(props.tipoGrafico=="barras"){
        return (
            <div >
                {imgSexo()}
                <div class="row justify-content-center distributionageclass scrolltype mt-4 py-2" style={{marginBottom:'15px'}}>
                    
                    {                    
                        filtro.map((value, key) => {
                            return <div key={key} class="row justify-content-center" >
                                     
                                    <div class="col-12 text-center">
                                        <label style={{marginLeft:'10px'}}>{value.RangoEdad}</label>
                                    </div>
                                    <div class="col-2 d-flex" >
                                        <label style={{marginRight:'15px'}}>{Math.round((value.Cantidad*100)/(value.Cantidad+value.CantidadMujer))+'%'}</label>
                                        
                                    </div>
                                   
                                    <div class="col-9" >
                                        <div class="row de-male" >
                                       
                                            <div title={"Hombres: "+value.Cantidad.toLocaleString('en-US', { timeZone: 'UTC' }) +' ('+Math.round((value.Cantidad*100)/(value.Cantidad+value.CantidadMujer))+' %)'} class="progress progressmale" style={{width:'50%'}}>

                                                <div class="progress-bar progress-bar-success " role="progressbar" style={{width:Math.round((value.Cantidad*100)/(value.Cantidad+value.CantidadMujer))}}>
                                                </div>
                                            </div>
                                            <div title={"Mujeres: "+value.CantidadMujer.toLocaleString('en-US', { timeZone: 'UTC' }) +' ('+Math.round((value.CantidadMujer*100)/(value.Cantidad+value.CantidadMujer))+' %)'} class="progress" style={{width:'50%',position:'relative',right:'25px'}}>
                                                
                                                <div class="progress-bar progress-bar-danger" role="progressbar" style={{width:Math.round((value.CantidadMujer*100)/(value.Cantidad+value.CantidadMujer)), backgroundColor:'#ff00ff',}}>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-1 d-flex">
                                        <label style={{marginRight:'5px'}}>{Math.round((value.CantidadMujer*100)/(value.Cantidad+value.CantidadMujer))+'%'}</label>
                                        
                                    </div>
                                </div>
                        })
                    }
                    
                    
                    
                </div>
                            
                                          
            </div>
        )
    }
    else if(props.tipoGrafico=="pie"){
        return (
            <div >
                {imgSexo()}
                <div class="row justify-content-center distributionageclass scrolltype mt-4 py-2" style={{marginBottom:'15px'}}>

                    <PieChart id="pie"
                        palette={['blue','rgb(255, 0, 255)']}
                        dataSource={convertData(props.DistGender)}
                        
                          >
                          <Title >
                              <Font color="black" size="12" />
                          </Title>
                          <Legend
                            orientation="horizontal"
                            itemTextPosition="right"
                            horizontalAlignment="center"
                            verticalAlignment="top"
                            columnCount={4} />
                          <Export enabled={true} fileName="brecha_por_inscripcion_CONADIS" />
                          <Series argumentField="type" valueField="num">
                            <Label
                              visible={true}
                              position="columns"
                              customizeText={customizeText}
                              format="fixedPoint"
                              >
                                
                              <Font size={12} />
                              <Connector visible={true} width={0.5} />
                            </Label>
                            <SmallValuesGrouping threshold={4.5} mode="smallValueThreshold" />
                          </Series>
                          <Tooltip
                            enabled={true}
                            contentRender={TooltipTemplate}
                          />
                    </PieChart>


                </div>
                            
                                          
            </div>
        )
    }
    else{
        return <p>nothing to show</p>
    }
}

export default DistributionGender
import React from 'react'

const ReporteDSexo = (props) => {
    var DateToday = Date.now()
    var Datenow= new Date(DateToday).toLocaleDateString().split('/')
    let DateFinal =  (parseInt(Datenow[0])-1 === 0 ? 1 : parseInt(Datenow[0])-1)+'/'+parseInt(Datenow[1]) + '/' +parseInt(Datenow[2])
    let numberRow = props.registros.length+1
    return (
        <div style={{display:"none"}}>
        <table className="tableMapa" id="table-Dsexo">
                <thead>
                    <tr >
                        <th >Edad</th>
                        <th >Total de la región</th>
                        <th >Cantidad Mujeres</th>
                        <th >Porcentaje Mujeres</th>
                        <th >Cantidad Hombre</th>
                        <th >Porcentaje Hombres</th>
                        <th >Rango</th>
                        <th rowSpan={numberRow}>{props.region+', ' +props.provincia+', ' +props.distrito}</th>
                    </tr>
                </thead>

                <tbody>

                    {
                        props.registros.map((x,index)=>{

                            if(index!==0){
                                return <tr key={index}>
                                <td >{x.RangoEdad} <div className="maptable-line"></div></td>
                                <td >{x.Cantidad+ x.CantidadMujer} <div className="maptable-line"></div></td>
                                <td >{x.CantidadMujer} <div className="maptable-line"></div></td>
                                <td >{x.porcentajemujer}% <div className="maptable-line"></div></td>
                                <td >{x.Cantidad } <div className="maptable-line"></div></td>
                                <td >{x.porcentajehombre}% <div className="maptable-line"></div></td>
                                <td >{props.rango} años <div className="maptable-line"></div></td>
                                </tr>
                            }
                            return

                        })


                    }

                <td colSpan="6">Fuente: CONADIS {DateFinal}, MINSA al 31/12/2021 y CONTIGO a 31/12/2021</td>
                </tbody>

                </table>
        </div>
    )
}

export default ReporteDSexo
import React, {useEffect,useState } from 'react'
import SimplePopoverHistorial from '../componentes/popover/popoverhistorial'
// import Footer from '../componentes/footer/Footer'

import { Slider, CircularProgress} from '@material-ui/core';
import { withStyles} from '@material-ui/core/styles';
import { } from 'react-chartjs-2';
//import Chart from "react-google-charts";
import { } from 'recharts';
//import GraphicDistAge from '../../componentes/GraphicDistAge/GraphicDistAge'
import  DistributionGender from '../componentes/DistributionGender/DistributionGender'
import DistributionLevelGravity from '../componentes/DistLevelGravity/DistLevelGravity'
import DistributionAfiliates from '../componentes/DistAfiliates/DistAfiliates'
import axios from 'axios';
import URL from '../url.json'
import headers from '../headers.json'
import LoadingComponent from '../componentes/loadingComponent/loadingComponent'
import BarTypeLimitation from '../componentes/BarTypeLimitation/BarTypeLimitation'
import DistribucionEdad from '../componentes/distribucionEdad/distribucionEdad'
import LinealGraphicAfiliates  from '../graficos/LineGraphicAfiliates/LineGraphicAfiliates'

import DataNSEComponent from '../componentes/DataNSE/DataNSE'

import Ubigeoperu from 'peru-ubigeo';

//herramienta reporte
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
// import { DownloadTableExcel } from 'react-export-table-to-excel';


//reportes

import ReporteDEdad from '../reportes/reporteDEdad'
import ReporteDSexo from '../reportes/reporteDSexo'
import ReporteDGravedad from '../reportes/reporteDgravedad'
import ReporteDAfiliados from '../reportes/reporteDAfiliados'
import ReporteDLaboral from '../reportes/reporteDlaboral'
import ReporteDSECO from '../reportes/reporteDSECO'

import BrechaPorCertificar from '../componentes/BrechaPorCertificar/BrechaPorCertificar';
import getBrechaPcdENEDISSinCertificado from '../componentes/BrechaPorCertificar/BrechaPorCertificar';
import BrechaInscripcionCONADIS from '../componentes/BrechaInscripcionCONADIS/BrechaIncripcionCONADIS';
import BrechaProgramaSocialCONTIGO from '../componentes/BrechaProgramaSocialCONTIGO/BrechaProgramaSocialCONTIGO';
import {Pension65_Cobertura, Pension65_Brecha} from '../componentes/BrechaProgramaPension65/ProgramaPension65';

import '../AppStyle.css';
import PageHeader from '../componentes/header';
import Spiner from '../componentes/spiner/spiner';
import SliderPrincipal from '../componentes/SliderPrincipal';
//estilos
// import "./dashboard.css"
// import '../AppStyle.css';

const PageDashboard = (props) => {

    var pruebaExcel2=[{nombre:"Empleados",porcentaje:70,color:"#2F80ED",cantidad:1456},{nombre:"Desempleados",porcentaje:30,color:"#E6282E",cantidad:1456}]
    var pruebaExcel=[{nombre:"NSE A",porcentaje:20,color:"#FAFC00",cantidad:1456},{nombre:"NSE B",porcentaje:20,color:"#27AE60",cantidad:1456},{nombre:"NSE C",porcentaje:40,color:"#E6282E",cantidad:1456},{nombre:"NSE D",porcentaje:5,color:"#2FE2ED",cantidad:1456},{nombre:"NSE E",porcentaje:15,color:"#6F00FC",cantidad:1456}]
    let [edadMin, setEdadMin]= useState()
    let [edadMax, setEdadMax] = useState()
    var DateToday = Date.now()
        var Datenow= new Date(DateToday).toLocaleDateString().split('/')
        var DateFinal =  parseInt(Datenow[0])-1 +'/'+parseInt(Datenow[1]) + '/' +parseInt(Datenow[2])
    var prueba=[{nombre:"Conadis",color:"#3871E5", objetos:[{nombre:"2010",cantidad:1500},{nombre:"2011",cantidad:5000},{nombre:"2011",cantidad:5000},{nombre:"2011",cantidad:100000},{nombre:"2011",cantidad:70000},{nombre:"2011",cantidad:25000},{nombre:"2011",cantidad:0},{nombre:"2011",cantidad:3000},{nombre:"2011",cantidad:3000},{nombre:"2011",cantidad:7400},{nombre:"2011",cantidad:5000},{nombre:"2011",cantidad:1000},{nombre:"2011",cantidad:1000},{nombre:"2011",cantidad:100000}]},
    {nombre:"Minsa",color:"#E6282E", objetos:[{nombre:"2010",cantidad:71500},{nombre:"2011",cantidad:55000},{nombre:"2011",cantidad:500},{nombre:"2011",cantidad:10000},{nombre:"2011",cantidad:700},{nombre:"2011",cantidad:2500},{nombre:"2011",cantidad:10000},{nombre:"2011",cantidad:30000},{nombre:"2011",cantidad:30000},{nombre:"2011",cantidad:74000},{nombre:"2011",cantidad:50000},{nombre:"2011",cantidad:75000},{nombre:"2011",cantidad:75000},{nombre:"2011",cantidad:75000}]}]
    useEffect(/*async*/() => {
        // Add and configure Series
        //console.log(list)
        var date = new Date();
        var age = date.getFullYear();
        var month = date.getMonth()+1;
        
        var daysmonth = new Date(age, month, 0).getDate();
        let days = []
        for (var i = 1; i <= daysmonth; i++) {
            // Ojo, hay que restarle 1 para obtener el mes correcto
            //console.log(i)
            days.push({
                description: i === 1?'Solo 1 día': 'Últimos ' + i + ' días',
                value: i
            })
        }
        //setDays(days)
        //console.log(days.length) 
        setDayLast(days.length)
        DayLast = days.length
        setDayToday(days.length)
        //DayToday = days.length
        setMonth(month)
        Month = month-1
        setAge(age)
        Age = age
        
        //console.log(days)
        
        //Departaments = array
        //console.log(Departaments)
        getdepartaments()
        var id=CodeUbigeo
        getDistEdad(id)
        getdistributiongender(id)
        getdistributionsexgravity(id)
        getdistributionafiliates(id)
        getafiliateshistoriclines(id)
        getdataNSE(id)
        document.getElementById("male").checked = true;
        document.getElementById("female").checked = true;
        getNotasFuentes(1);
               
    }, []);
    //var ubigeo = new Ubigeoperu()
    const [anchorEl, setAnchorEl] = React.useState(null);
    let [Departaments,setDepartaments] = useState([])
    let [Provinces,setProvinces] = useState([{CodUbigeo:'0',Provincia:'Todas las provincias'}])
    let [Districts,setDistricts] = useState([{CodeUbigeo:'0',Distrito:'Todas los distritos'}])
    let [IndexDepartaments, setIndexDepartaments] = useState('0')
    let [IndexProvinces, setIndexProvinces] = useState('0')
    let [IndexDistricts, setIndexDistricts] = useState('0')
    let [CodeUbigeo, setCodeUbigeo] = useState('0')
    let [CodeUbigeoSearch, setCodeUbigeoSearch] = useState('0')

    //let [Days, setDays] = useState([])
    let [Age, setAge] = useState(0)
    let [Month, setMonth] = useState(0)
    let [DayLast, setDayLast] = useState(0)
    let [DayToday, setDayToday] = useState(30)


    
    
    
    const [DistEdad, setDistEdad] = useState(null);
    const [DistSex, setDistSex] = useState(null);
    const [DistGender, setDistGender] = useState(null);
    const [LevelDanger, setLevelDanger] = useState(null);
    const [Certificates, setCertificates] = useState(null);
    const [DistAfiliates, setDistAfiliates] = useState(null);
    const [DataNSE, setDataNSE] = useState([])
    let [DistLineAfiliates, setDistLineAfiliates] = useState([])
    let [DistLineAfiliatesReplace, setDistLineAfiliatesReplace] = useState([])
    let [DistLineAfiliateFinal, setDistLineAfiliateFinal] = useState(null)




    //variables de error
    const [ErrorDistEdad, setErrorDistEdad] = useState(null);
    const [ErrorDistSex, setErrorDistSex] = useState(null);
    const [ErrorDistGender, setErrorDistGender] = useState(null);
    const [ErrorLevelDanger, setErrorLevelDanger] = useState(null);
    const [ErrorCertificates, setErrorCertificates] = useState(null);
    const [ErrorAfiliates, setErrorAfiliates] = useState(null);
    const [ErrorLineAfiliates, setErrorLineAfiliates] = useState(null)
    const [ErrordataNSE, setErrordataNSE] = useState(null)

    const[contador, setContador]=useState(0)

    let [agedist, setAgedist] = useState([15,45]); 
    //let [agemax, setAgemax] = useState(45);
    let [agegravity, setAgemgravity] = useState([15,45]);
    let [yearNSE, setYearNSE] = useState([15,45])
    //let [agemaxgravity, setAgemaxgravity] = useState(45);
    let [ismaleState, setisMale] = useState(true);
    let [isfemaleState, setisFemale] = useState(false);
    let [MinsaSelected, setMinsaSelected] = useState(true);
    let [ConadisSelected, setConadisSelected] = useState(true);
    let [MidisSelected, setMidisSelected] = useState(true);
    let [INEISelected, setINEISelected] = useState(true);
    let [typeFilterDate, setTypefilterDate] = useState('DAY')
    let [filter, setFilter] = useState('true')

    /* Vartiables para la seleccion de provincioas */
    let [region,setRegion]=useState('Todas las regiones')
    let [provincia, setProvincia]=useState('Todas las provincias')
    let [distrito, setDistrito] = useState('Todos los distritos')

    /* variables para distribucion por sexo */
    let [edadMinR, setEdadMinR] = useState([15])
    let [edadMaxR, setEdadMaxR] = useState([45])

    /* variables para Nivel de gravedad */
    let [edadMinRGravity, setEdadMinRGravity] = useState([15])
    let [edadMaxRGravity, setEdadMaxRGravity] = useState([45])


    /*  nueva función para distribución por sexo*/
    const handleRange = ()=>{
        //console.log(edadMinR+' '+edadMaxR)
        agedist[0] = edadMinR === '' ? 0 : Number(edadMinR)
        agedist[1] = edadMaxR === '' ? 0 : Number(edadMaxR)
        var id=CodeUbigeo
        //console.log(id)
        getdistributiongender(id)

    }

    /* Evento para distribucion por sexo */
    const ChangeValueagemin = (event, newValue) => {
        
        //setAgemin(event.target.value === '' ? 0 : Number(event.target.value))
        agedist[0] = event.target.value === '' ? 0 : Number(event.target.value)
        setEdadMinR(event.target.value === '' ? 0 : Number(event.target.value))

    };
    const ChangeValueagemax = (event, newValue) => {
        //setAgemax(event.target.value === '' ? 0 : Number(event.target.value))
        agedist[1] = event.target.value === '' ? 0 : Number(event.target.value)
        setEdadMaxR(event.target.value === '' ? 0 : Number(event.target.value))
    };

    const ChangeValueage = (event, newValue)=>{
        agedist[0] = newValue[0]
        agedist[1] = newValue[1]
        
    }

    const ClickonChangevaluegender = ()=>{
        var id=CodeUbigeo
        setEdadMinR(agedist[0])
        setEdadMaxR(agedist[1])
        getdistributiongender(id)
    }

    /*  nueva función para nivel gravedad*/
    const handleRangeGravity = ()=>{
        //console.log(edadMinR+' '+edadMaxR)
        agegravity[0] = edadMinRGravity === '' ? 0 : Number(edadMinRGravity)
        agegravity[1] = edadMaxRGravity === '' ? 0 : Number(edadMaxRGravity)
        var id=CodeUbigeo
        getdistributionsexgravity(id)
    }

    const ChangeValueagemingravity = (event, newValue) => {
        
        //setAgemingravity(event.target.value === '' ? 0 : Number(event.target.value))
        agegravity[0] = event.target.value === '' ? 0 : Number(event.target.value)
        setEdadMinRGravity(event.target.value === '' ? 0 : Number(event.target.value))

    };
    const ChangeValueagemaxgravity = (event, newValue) => {
        //setAgemaxgravity(event.target.value === '' ? 0 : Number(event.target.value))
        agegravity[1] = event.target.value === '' ? 0 : Number(event.target.value)
        setEdadMaxRGravity(event.target.value === '' ? 0 : Number(event.target.value))
        
    };
    const ChangeValueagegravity = (event, newValue)=>{
        //setAgemingravity(newValue[0])
        //setAgemaxgravity(newValue[1])
        agegravity[0] = newValue[0]
        agegravity[1] = newValue[1]
        
    }
    
    const ChangeValueageminNSE = (event, newValue) => {
        
        //setAgemingravity(event.target.value === '' ? 0 : Number(event.target.value))
        yearNSE[0] = event.target.value === '' ? 0 : Number(event.target.value)
        var id=CodeUbigeo
        getdataNSE(id)

    };
    
    const ChangeValueagemaxNSE = (event, newValue) => {
        //setAgemaxgravity(event.target.value === '' ? 0 : Number(event.target.value))
        yearNSE[1] = event.target.value === '' ? 0 : Number(event.target.value)
        var id=CodeUbigeo
        getdataNSE(id)
    };
    
    const ChangeValueyearNSE = (event, newValue)=>{
        //setAgemingravity(newValue[0])
        //setAgemaxgravity(newValue[1])
        yearNSE[0] = newValue[0]
        yearNSE[1] = newValue[1]
        
    }

    const ClickonChangevaluegravity = ()=>{
        var id=CodeUbigeo
        setEdadMinRGravity(agegravity[0])
        setEdadMaxRGravity(agegravity[1])
        getdistributionsexgravity(id)
    }
    
    const ClickonChangevalueNSE = ()=>{
        var id=CodeUbigeo
        getdataNSE(id)
    }

    const RenderBarLateral = ()=>{
        
        if(CodeUbigeo!== ''){
            return <BarTypeLimitation CodeUbigeo={CodeUbigeoSearch} />
        }    
    }


    const selectMINSA = async (event)=>{
        setMinsaSelected(event.target.checked)
        MinsaSelected = event.target.checked
        
        let checked = event.target.checked
        getafiliateshistoriclines(CodeUbigeo)
        
    }
    const selectCONADIS = async (event)=>{
        setConadisSelected(event.target.checked)
        ConadisSelected = event.target.checked
        
        let checked = event.target.checked
        //console.log('conadis')
        getafiliateshistoriclines(CodeUbigeo)
        
    }

    const selectMIDIS = async (event)=>{
        setMidisSelected(event.target.checked)
        MidisSelected = event.target.checked
        let checked = event.target.checked
        getafiliateshistoriclines(CodeUbigeo)
       
    }
    const selectINEI = (event)=>{
        setINEISelected(event.target.checked)
        let checked = event.target.checked
        if(!checked){
            let data = DistLineAfiliates.filter(data=>data.name !=='Inei')
                
                setDistLineAfiliates(data)
            //console.log(event.target.checked)
        }else{
            let filterdata= DistLineAfiliatesReplace.filter(data=>data.name ==='Inei')
            let data = DistLineAfiliates
            data.push(filterdata[0])
                
            setDistLineAfiliates(data)
        }
    }

    let Changegender = (gender)=>{
        var vmale = document.getElementById("male").checked;
        var vfemale = document.getElementById("female").checked;
        let ismale = gender === 'male'
        if(vmale === false && vfemale === false){
            document.getElementById("male").checked = true;
            document.getElementById("female").checked = true;
        }
        getdistributionsexgravity(CodeUbigeo)
        
    }


    const validateagelimit = (min,max)=>{
        let islessmin = min < 0
        let ismajormin = min>100
        let islessmax = max< 0
        let ismajormax = max>100
        if(islessmin){
            //setAgemin(0)
            agedist[0] = 0
        } else if (ismajormin) {
            //setAgemin(100)
            agedist[0] = 100
        }
        if(islessmax){
            //setAgemax(0)
            agedist[1] = 0
        } else if (ismajormax) {
            //setAgemax(100)
            agedist[1] = 100
        }
    } 

    const DataFormater = (number) => {
        if(number > 1000000000){
          return (number/1000000000).toString() + 'B';
        }else if(number > 1000000){
          return (number/1000000).toString() + 'M';
        }else if(number > 1000){
          return (number/1000).toString() + 'K';
        }else{
          return number.toString();
        }
      }

    const validateagelimitgravity = (min,max)=>{
        let islessmin = min < 0
        let ismajormin = min>100
        let islessmax = max< 0
        let ismajormax = max>100
        if(islessmin){
            //setAgemingravity(0)
            agegravity[0] = 0
        } else if (ismajormin) {
            //setAgemingravity(100)
            agegravity[0] = 100 
        }
        if(islessmax){
            //setAgemaxgravity(0)
            agegravity[1] = 0
        } else if (ismajormax) {
            //setAgemaxgravity(100)
            agegravity[1] = 100
        }
    }
    
    const validateagelimitNSE = (min,max)=>{
        let islessmin = min < 0
        let ismajormin = min>100
        let islessmax = max< 0
        let ismajormax = max>100
        if(islessmin){
            //setAgemingravity(0)
            yearNSE[0] = 0
        } else if (ismajormin) {
            //setAgemingravity(100)
            yearNSE[0] = 100 
        }
        if(islessmax){
            //setAgemaxgravity(0)
            yearNSE[1] = 0
        } else if (ismajormax) {
            //setAgemaxgravity(100)
            yearNSE[1] = 100
        }
    } 
    
    
    const series = [
        {
          name: 'Series 1',
          color:'blue',
          data: [
            { category: 'A', value: Math.random() },
            { category: 'B', value: Math.random() },
            { category: 'C', value: Math.random() },
          ],
        },
        {
          name: 'Series 2',
          color:'red',
          data: [
            { category: 'B', value: Math.random() },
            { category: 'C', value: Math.random() },
            { category: 'D', value: Math.random() },
          ],
        },
        {
          name: 'Series 3',
          color:'#008080',
          data: [
            { category: 'C', value: Math.random() },
            { category: 'D', value: Math.random() },
            { category: 'E', value: Math.random() },
          ],
        },
      ];
    const statechart = {
        top: 0,
        left: 0,
        showTooltip: true,
        date: null,
        valueNew: null,
        valueOld: null,
      };
      const dataaffiliates = [
          
        ['ID', 'Life Expectancy', 'Fertility Rate', 'Region', 'Population',{ role: "tooltip", type: "string", p: { html: true } }],
      ['CAN', 80.66, 1.67, 'North America', 33739900,"<h2> Anything can go here </h2>"],
      ['DEU', 79.84, 1.36, 'Europe', 81902307,"<h2> Anything can go here </h2>"],
      ['DNK', 78.6, 1.84, 'Europe', 5523095,"<h2> Anything can go here </h2>"],
      ['EGY', 72.73, 2.78, 'Middle East', 79716203,"<h2> Anything can go here </h2>"],
      ['GBR', 80.05, 2, 'Europe', 61801570,"<h2> Anything can go here </h2>"],
      ['IRN', 72.49, 1.7, 'Middle East', 73137148,"<h2> Anything can go here </h2>"],
      ['IRQ', 68.09, 4.77, 'Middle East', 31090763,"<h2> Anything can go here </h2>"],
      ['ISR', 81.55, 2.96, 'Middle East', 7485600,"<h2> Anything can go here </h2>"],
      ['RUS', 68.6, 1.54, 'Europe', 141850000],
      ['USA', 78.09, 2.05, 'North America', 307007000],// CSS-style declaration
      ];

    //INICIO set fuentes y notas (By Jhonatan Rivera Carbajal)
    const [objetoError, setObjetoError] = useState(null);
    const [arrNotasFuentes,setArrNotasFuentes] = useState(null);
    
    const getNotasFuentes=(id)=>{
        axios.post(URL.apiURL+'getGeneralNotasFuentes', null,headers)
        .then(response => {var arrr = []; for (var i = 0; i < response.data.data.length; i++) {arrr[response.data.data[i].IdInstitucion] = '<div style="font-size: 15px;"> '+response.data.data[i].Nota+response.data.data[i].FechaReporte+'</div>';} setArrNotasFuentes(arrr);            
        })
        .catch(err=> {setObjetoError(err)});
    }

    function setFuentes(fuentes){
        var t = "";
        if(arrNotasFuentes !== null){
            t = '<div><b>FUENTE:</b></div>';
            for (var i = 0; i < fuentes.length; i++) {
                t += arrNotasFuentes[fuentes[i]];
            }         
        }
        return t;
    }
    // FIN set fuentes y notas     
      
      const optionslinealaffiliates = {
          
        responsive: true,
        
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    plugins: {
        legend: {
            display: false,
            
        },
        tooltip: {
            intersect: true,
            callbacks: {
                title: function (tooltipItem) { 
                    //console.log(tooltipItem)
                    return tooltipItem[0].label+ " Mar 21 \n" + tooltipItem[0].dataset.label.split('/')[0] +"\n" + +tooltipItem[0].parsed.x +"\nTotal\n" +tooltipItem[0].parsed.x +"\n Nuevos Afiliados\n"
                },
                label: function(tooltipItem) {
                    
                   return ""
                },
                
                
            }
        },
        
    },
    
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
      },
      y1: {
        type: 'linear',
        display: false,
        position: 'right',

        // grid line settings
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      },
      y2: {
        type: 'linear',
        display: false,
        position: 'right',

        // grid line settings
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      },
    },
    
    
      };
      
    const datadistribution = {
        labels: ['Minsa', 'Conadis','Midis'],
        datasets: [
          {
            
            data: [35,30,35],
            backgroundColor: [
              '#0275d8',
              '#f9373c',
              '#008080'
              
            ],
            borderColor: [
              '#0275d8',
              '#f9373c',
              '#008080'
              
            ],
            borderWidth: 1,
          },
        ],
      };
    const data = {
        labels: ['No certificados', 'Certificados'],
        datasets: [
          {
            
            data: [40, 40],
            backgroundColor: [
              '#0275d8',
              
              '#f9373c',
              
            ],
            borderColor: [
              '#0275d8',
              '#f9373c',
              
            ],
            borderWidth: 1,
          },
        ],
      };
    
    const open = Boolean(anchorEl);
    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
        checkedF: true,
        checkedG: true,
    });

    

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
      };

    const PrettoSlider = withStyles({
        root: {
          color: '#f9373c',
          height: 8,
        },
        thumb: {
          height: 20,
          width: 20,
          backgroundColor: '#f9373c',
          border: '2px solid #fff',
          marginTop: -8,
          marginLeft: -12,
          '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
          },
        },
        active: {},
        valueLabel: {
          left: 'calc(-50% + 4px)',
        },
        track: {
          height: 8,
          borderRadius: 4,
        },
        rail: {
          height: 8,
          borderRadius: 4,
        },
      })(Slider);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    
    function valuetext(value) {
        return `${value}°C`;
    }

    const getIntroOfPage = (label) => {
        if (label === 'Page A') {
            return "Page A is about men's clothing";
        } if (label === 'Page B') {
            return "Page B is about women's dress";
        } if (label === 'Page C') {
            return "Page C is about women's bag";
        } if (label === 'Page D') {
            return 'Page D is about household goods';
        } if (label === 'Page E') {
            return 'Page E is about food';
        } if (label === 'Page F') {
            return 'Page F is about baby food';
        }
    };
    
    const getDistEdad= async (id)=>{
        var ubigeo=new Ubigeoperu()

        //
        //setDepartaments(await ubigeo.getRegions())
        
        setDistEdad(null)
        setDistSex(null)
        setErrorDistEdad(null)
        setErrorDistSex(null)

        var objetoRequest={
            codubigeo: id
        }
        axios.post(URL.apiURL+'getcantpersexorangoedadesxubigeo', objetoRequest, headers)
        .then(response => convertDistEdad(response.data))
        .catch(err=> {setErrorDistEdad(err);});

    }

    const getdistributiongender=(id)=>{
        if(agedist[0]>agedist[1]){
            setEdadMinR(agedist[1])
            setEdadMaxR(agedist[0])

            agedist[0]=edadMinR
            agedist[1]=edadMaxR
            
        }
        setErrorDistGender(null)
        setDistGender(null)
        var objetoRequest={
            codubigeo: id,
            edadmin: agedist[0] > agedist[1] ? agedist[1]:agedist[0],
            edadmax: (agedist[1] < agedist[0] ? agedist[0] : agedist[1] === 100) ? 200 : agedist[1] < agedist[0] ? agedist[0] : agedist[1] 
            
        }
        axios.post(URL.apiURL+'getcantpersexorangoedadesxubigeoxedades', objetoRequest, headers)
        .then(response => convertdatadistgender(response.data.data))
        .catch(err=> setErrorDistGender(err));
        
        
    }

    const getdistributionsexgravity=(id)=>{
        if(agegravity[0]>agegravity[1]){
            setEdadMinRGravity(agegravity[1])
            setEdadMaxRGravity(agegravity[0])

            agegravity[0]=edadMinRGravity
            agegravity[1]=edadMaxRGravity
            
        }
        setErrorLevelDanger(null)
        setLevelDanger(null)
        var vmale = document.getElementById("male").checked;
        var vfemale = document.getElementById("female").checked;
        var vsexo = "";
        if(vmale === true && vfemale === false){
            vsexo = "M";
        }else if(vmale === false && vfemale === true){
            vsexo = "F";
        } 
        var objetoRequest={
            codubigeo: id,
            edadmin: agegravity[0] > agegravity[1] ? agegravity[1]:agegravity[0],
            edadmax: agegravity[1] < agegravity[0] ? agegravity[0] : agegravity[1],
            sexo: vsexo
            
        }
        //console.log(objetoRequest)
        setEdadMin(agegravity[0])
        setEdadMax(agegravity[1])
        
        axios.post(URL.apiURL+'getcantpernivelgravedadsexorangoedadesxubigeoxedades', objetoRequest, headers)
        .then(response => convertleveldanger(response.data.data))
        .catch(err=> setErrorLevelDanger(err));
        
    }

    const getdataNSE=(id)=>{
        
        setErrordataNSE(null)
        setDataNSE([])
        var objetoRequest={
            codubigeo: id,
            from: yearNSE[0],
            to: yearNSE[1],
            
        }
        axios.post(URL.apiURL+'filter_nse', objetoRequest, headers)
        .then(response => {
            let data = response.data
            let emptyData = data.length<=0
            //console.log(emptyData)
            if(!emptyData){
                data.forEach(data=>{
                    //data.nivel = data.nivel === 'NO POBRE' ? 'NSE A' : data.nivel === 'POBRE' ? 'NSE B' : data.nivel === 'POBRE' ? 'NSE B'
                    data.percentage = Math.round(parseFloat(data.percentage)) +'%'

                })
                setDataNSE(data)
                getDataNSEComponent()
            }else{
                setDataNSE([])
                setErrordataNSE({error:'no hay datos'})
                getDataNSEComponent()
            }
        })
        .catch(err=>{
            setErrordataNSE(err)
            getDataNSEComponent()
        });
        
        
    }

    const getdistributionafiliates = (id,filter) =>{
        setErrorAfiliates(null)
        setDistAfiliates(null)
        //console.log(typeof filter +' '+ 'tipo->' +filter+'contador->'+contador)
        if(contador ===0 && filter ===null){
            filter=2
        }
        var objetoRequest={
            codubigeo: id,
            tipoLimite: 0,
            filtro:filter==='' ||filter==='true' || filter===true ? 2: 1
        }
        //console.log(objetoRequest)
        axios.post(URL.apiURL+'getdistribucionafiliadoxubigeo', objetoRequest, headers)
        .then(response => converdataafiliates(response.data.data))
        .catch(err=> setErrorAfiliates(err));
    }
    
    const converdataafiliates = (data)=>{
        
        if(data.length>0){
            setDistAfiliates(data)
            //console.log(DistAfiliates)
            DistributionDistAfiliates()
        }else{
            setDistAfiliates(null)
            setErrorAfiliates({error:'no hay datos'})
            DistributionDistAfiliates()
        }
    }

    const convertleveldanger = (data)=>{
        let dataempty = data.length<=0
        //console.log(data)
        
        if(!dataempty){
            let total  = 0
            let result = []
            for (let index = 0; index < data.length; index++) {
                total= total+= data[index].Cantidad 
            }
            
            for (let index = 0; index < data.length; index++) {
                data[index].porcentaje = Math.round(((data[index].Cantidad*100)/total))+"%"
            }

            result = data
            setLevelDanger(result)
            //console.log(result)
            DistributionDistLeveGravity()
        }else{
            setLevelDanger(null)
            setErrorLevelDanger({err:'no hay datos'})
            DistributionDistLeveGravity()
        }
    }

    const convertdatadistgender = (data)=>{
        let result = []
        let dataempty = data.length<=0

        if(!dataempty){
            
            let male = []
            let female =[]
            male = data.filter(data=>data.Sexo==='M')
            female = data.filter(data=>data.Sexo==='F')
            let total=0
            let totalF= 0
            let totalM= 0
            for (let index = 0; index < male.length; index++) {
                total+=male[index].Cantidad + female[index].Cantidad
                totalF+= totalF+ female[index].Cantidad
                totalM+= totalM +male[index].Cantidad
                var percentagemale = Math.round(((male[index].Cantidad*100)/total))
                var percentagefemale = Math.round(((female[index].Cantidad*100)/total))
                male[index].porcentajehombre = percentagemale
                male[index].porcentajemujer = percentagefemale
                male[index].CantidadMujer = female[index].Cantidad

            };
            let totalobject = {
                RangoEdad: 'Total',
                porcentajehombre: Math.round(((totalM*100)/(totalF+totalM))),
                porcentajemujer:Math.round(((totalF*100)/(totalF+totalM))),
                Cantidad: totalM,
                CantidadMujer:totalF
            }
            male.unshift(totalobject)

            result = male

            setDistGender(result)
            DistributionGenderComponent()
        }else{
            setDistGender(null)
            setErrorDistGender({error:'no hay datos'})
            DistributionGenderComponent()
        }
        
    }

    const getdepartaments = ()=>{
        axios.post(URL.apiURL+'getubigeodepartamentos', {}, headers)
        .then(response => {
            let array = {
                Departamento:'Todas las regiones',
                CodUbigeo:'0'
            }
            //console.log(Departaments)
            let dataubigeo = response.data.data
            dataubigeo.unshift(array)
            
            setDepartaments(dataubigeo)
            //console.log(Departaments)
            //console.log(response.data.data)
        })
        .catch(err=> console.log(err));
    }

    const getafiliateshistoriclines = (id)=>{
        setDistLineAfiliateFinal(null)
        DistLineAfiliateFinal = null
        setErrorLineAfiliates(null)

        let fini = DayToday === 30 || DayToday === '30' || DayToday === 31 ? 1 : DayToday === '3' || DayToday === 3 ?  3 : DayToday === '6' || DayToday === 6 ?  6 : DayToday === '1' || DayToday === 1 ? 12 : DayToday === '6a' ? 72 : 120
            DayToday === 30 || DayToday === '30' || DayToday === 31 ? setTypefilterDate("DAY") : 
            DayToday === 3 || DayToday === '3' ? setTypefilterDate("MONTH") :
            DayToday === 6 || DayToday === '6' ? setTypefilterDate("MONTH") :
            DayToday === 1 || DayToday === '1' ? setTypefilterDate("MONTH") :
            setTypefilterDate("YEAR")


        let d = new Date()
        let fechaFin = d.getFullYear() + "-"+d.getMonth() + "-"+d.getDate()
        var e = new Date()
        //console.log(DayToday)

        e.setMonth(e.getMonth() - (fini+1))
        let fechaInicioFiltro = e.getFullYear() +"-"+ (e.getMonth()+1) +"-"+ e.getDate()
       //console.log("fecha: "+e.getFullYear() +"-"+ (e.getMonth()+1) +"-"+ e.getDate())

        //console.log(fechaFin+ " nn "+fechaInicioFiltro)
        let objectRequest = {
            codubigeo: id,
            fechainicio: fechaInicioFiltro,
            fechafin:fechaFin,
            format:typeFilterDate
        }
        //console.log(Age.toString())
        //console.log(objectRequest)
        function convertirFecha (fechaString) {
            var fechaSp = fechaString.split("-");
            var anio = new Date().getFullYear();
            if (fechaSp.length == 3) {
              anio = fechaSp[2];
            }
            var mes = fechaSp[1] - 1;
            var dia = fechaSp[0];
          
            return new Date(anio, mes, dia);
          }
        axios.post(URL.apiURL+'gethistoricoafiliadosinstitucionfechas', objectRequest, headers)
        .then(response => {
            //console.log(objectRequest)
            //console.log(objectRequest.fechafin, objectRequest.fechainicio)
            //console.log(objectRequest.fechafin.substring(0,4) - objectRequest.fechainicio.substring(0,4))
            let filtro= objectRequest.fechafin.substring(0,4) - objectRequest.fechainicio.substring(0,4)
            let filtrom = filtro ===0? Math.round((new Date(objectRequest.fechafin)- new Date(objectRequest.fechainicio))/ (1000*60*60*24*30)):0
            filtrom = filtrom.toString()+'m'
            //console.log(filtrom)
            if(response.data.data.length>0){
                //console.log(response.data.data)
                let resultfinal = []
                let totalgeneral = 0
                response.data.data.forEach(element=>{
                    totalgeneral +=element.CantidadAfiliados
                })
                let Minsa = response.data.data.filter(data=>data.NombreInst === 'MINSA')
                let Conadis = response.data.data.filter(data=>data.NombreInst === 'CONADIS')
                let Midis = response.data.data.filter(data=>data.NombreInst === 'MIDIS')
                //console.log(Conadis)
                let isMinsaempty = Minsa.length<=0
                let isConadisempty = Conadis.length<=0
                let isMidisempty = Midis.length<=0
                let TotalAcumulado = 0
                if(!isMinsaempty){
                    let totalMinsa = 0
                    //console.log('-total-minsa')
                    Minsa.forEach(element => {
                        totalMinsa+=element.CantidadAfiliados
                        //console.log(totalMinsa)
                    })
                    let percentage = Math.round(((totalMinsa*100)/totalgeneral))
                    resultfinal.push({
                        nombre:"MINSA",color:"#0275d8", porcentaje: percentage,  objetos:[]
                    })
                    let index = resultfinal.length -1
                    Minsa.forEach(element => {
                        TotalAcumulado = element.CantidadAfiliados+TotalAcumulado
                        resultfinal[index].objetos.push({
                            
                            nombre: typeFilterDate==='DAY' ? element.Fecha.split('-')[2]: element.Fecha,
                            cantidad:   element.CantidadAfiliados,
                            totalAcumulado: TotalAcumulado,
                            total: totalMinsa
                        })
                    })
                }
                if(!isConadisempty){
                    TotalAcumulado =0
                    let totalConadis = 0
                    Conadis.forEach(element => {
                        totalConadis+=element.CantidadAfiliados
                    })
                    let percentage = Math.round(((totalConadis*100)/totalgeneral))
                    resultfinal.push({
                        nombre:"CONADIS", porcentaje:percentage,color:"#E6282E", objetos:[]
                    })
                    Conadis.forEach(element => {
                        TotalAcumulado = element.CantidadAfiliados+TotalAcumulado
                        let index = resultfinal.length -1
                        resultfinal[index].objetos.push({
                            nombre: typeFilterDate==='DAY' ? element.Fecha.split('-')[2]: element.Fecha,
                            cantidad:   element.CantidadAfiliados,
                            totalAcumulado: TotalAcumulado,
                            total: totalConadis
                        })
                    })
                    //console.log(resultfinal)
                }
                if(!isMidisempty){
                    let anhosObtenido = []
                    anhosObtenido = Midis.map((item)=>{
                        return item.Fecha.includes('-') ? item.Fecha : parseInt(item.Fecha)  
                     })
                     //console.log('tamaño',Midis.length)
                    if(Midis.length<=filtro || filtrom === '6m' || filtrom === '3m' || filtrom === '1m'){
                        //console.log('si entro')
                        
                        //console.log('obtenido',anhosObtenido)

                        let anhoActual = new Date().getFullYear()
                        let anhoMesActual = new Date().getFullYear()+'-'+ new Date().getMonth()
                        
                        let anhosFaltantes = []
                        
                        let calculo = ()=>{
                            let aux =[]
                            if(filtro === 1 || filtrom === '6m'){
                                let valor = filtro === 1 ? 12 : filtrom === '6m' ? 6 : filtrom === '3m' ? 3: 1
                                //console.log('un año',anhoMesActual)
                                let mes = anhoMesActual
                                for(let i=0; i<valor+1; i++){
                                    
                                   
                                    if(anhosObtenido.some(item=>item === mes) ){
                                        
                                        mes = mes.substring(5) !== '1' ? 
                                            mes.substring(0,5)+(parseInt(mes.substring(5))-1 ).toString() : 
                                            (parseInt(mes.substring(0,4))-1).toString()+'-12'
                                        

                                        continue
                                    }else{
                                        
                                        
                                        aux.push(mes)
                                        mes =  mes.substring(5) !== '1' ? 
                                            mes.substring(0,5)+(parseInt(mes.substring(5))-1 ).toString(): 
                                            (parseInt(mes.substring(0,4))-1).toString()+'-12'

                                        
                                        console.log(mes)
                                        
                                    }
                                }
                            }else{
                                for(let i=0; i<filtro+1; i++){
                                    if(anhosObtenido.some(item=>item === anhoActual-i ) ){
                                        continue
                                    }else{
                                        aux.push(anhoActual-i)
                                    }
                                }
                            }
    
                            
                            return aux
                        }
                        anhosFaltantes= calculo()

                        //console.log('*******Resultado******')
                        //console.log(anhosFaltantes)
                        anhosObtenido = anhosFaltantes.concat(anhosObtenido)
                    }
                    anhosObtenido.sort(function(a, b) {
                        return a - b;
                      });

                    //console.log('años',anhosObtenido)

                    TotalAcumulado =0
                    let totalMidis = 0
                    Midis.forEach(element => {
                        totalMidis+=element.CantidadAfiliados
                    })

                    //console.log('elementos',Midis)
                    let percentage = Math.round(((totalMidis*100)/totalgeneral))
                    resultfinal.push({
                        nombre:"MIDIS",
                        color:"#00b347", 
                        porcentaje:percentage, 
                        objetos:[]
                    })
                    
                    Midis.forEach(element => {
                        //let dateformat = typeFilterDate==='DAY'? element.Fecha.split('/')[2] + '-' +element.Fecha.split('/')[1] +'-'+ element.Fecha.split('/')[0] :element.Fecha.split('/')[2] +'-'+ element.Fecha.split('/')[1] 
                        let index = resultfinal.length -1
                        TotalAcumulado = element.CantidadAfiliados+TotalAcumulado
                        //console.log('añosobtenidos',anhosObtenido)
                        anhosObtenido = filtro === 1 ? anhosObtenido.sort((function(a, b) {
                            return Date.parse(b) - Date.parse(a);
                          })).reverse(): anhosObtenido
                        anhosObtenido.forEach((e,i)=>{
                            if(e === parseInt(element.Fecha) || e === element.Fecha)
                            {
                                //console.log('el',element.Fecha)
                                resultfinal[index].objetos.push({
                                    nombre: typeFilterDate==='DAY' ? element.Fecha.split('-')[2]: element.Fecha,
                                    cantidad:   element.CantidadAfiliados,
                                    totalAcumulado: TotalAcumulado,
                                    total: totalMidis
                                })
                            }else{
                                resultfinal[index].objetos.push({
                                    nombre: e,
                                    cantidad:   0,
                                    totalAcumulado: TotalAcumulado,
                                    total: totalMidis
                                })
                            }
                            
                            
                        })

                        
                    })
                }
                setDistLineAfiliateFinal(resultfinal)
                DistLineAfiliateFinal = resultfinal
                setErrorLineAfiliates(null)
                
            }else{
                setErrorLineAfiliates({error:'no hay datos'})
            }
        })
        .catch(err=> setErrorLineAfiliates(err));
    }

    const getdataline = (event)=>{
        if(event.target.value === '30'){
            setTypefilterDate('DAY')
            typeFilterDate = 'DAY'
            setDayToday(event.target.value)
            DayToday = event.target.value
        }else if(event.target.value === '6a' || event.target.value === '10a' ){
            setTypefilterDate('YEAR')
            typeFilterDate = 'YEAR'
            setDayToday(event.target.value)
            DayToday = event.target.value
        }else{
            setTypefilterDate('MONTH')
            typeFilterDate = 'MONTH'
            setDayToday(event.target.value)
            DayToday = event.target.value
        }
        //setDayToday(event.target.value)
        //DayToday = event.target.value
        getafiliateshistoriclines(CodeUbigeo)
    }
    
    const getprovinces = (event)=>{
        let index = event.target.selectedIndex;
        setRegion(event.target.options[index].text)
        
        setIndexDepartaments(event.target.value)
        
        if(event.target.value !== '0'){
            
            setCodeUbigeo(event.target.value)
            CodeUbigeo = event.target.value

            let objectRequest = {
                codubigeo: event.target.value
            }
            axios.post(URL.apiURL+'getubigeoprovincias', objectRequest, headers)
            .then(response => {
                let array = {
                    Provincia:'Todas las provincias',
                    CodUbigeo:'0'
                }
                //console.log(Departaments)
                let dataubigeo = response.data.data
                dataubigeo.unshift(array)
                setProvinces(dataubigeo)
            })
            .catch(err=> console.log(err));
            
        }else{
            setCodeUbigeo(0)
            CodeUbigeo = 0
            
            //console.log(CodeUbigeo)
            var dataprovinces = [{
                Provincia:'Todas las provincias',
                CodUbigeo:'0'
            }]
            setProvinces(dataprovinces)
        }
       
        
    }

    const getdistricts = (event)=>{
        let index = event.target.selectedIndex;
        setProvincia(event.target.options[index].text)
        //console.log(provincia)
        setIndexProvinces(event.target.value)
        if(event.target.value !== '0'){
            setCodeUbigeo(event.target.value)
            CodeUbigeo = event.target.value
            //console.log(CodeUbigeo)
            let objectRequest = {
                codubigeo: event.target.value
            }
            axios.post(URL.apiURL+'getubigeodistritos', objectRequest, headers)
            .then(response => {
                let array = {
                    Distrito:'Todas los distritos',
                    CodUbigeo:'0'
                }
                //console.log(Departaments)
                let dataubigeo = response.data.data
                dataubigeo.unshift(array)
                
                setDistricts(dataubigeo)
            })
            .catch(err=> console.log(err));
            
        }else{
            setCodeUbigeo(IndexProvinces)
            CodeUbigeo = IndexProvinces
            //console.log(CodeUbigeo.substr(0,2))
            setCodeUbigeo(CodeUbigeo.substr(0,2)+'0000')
            CodeUbigeo = CodeUbigeo.substr(0,2)+'0000'
            //console.log(CodeUbigeo)
            var datadistricts = [{
                CodUbigeo:'0',
                Distrito:'Todas las provincias'
            }]
            setDistricts(datadistricts)
        }
        
        
    }

    const getcodubigeodistrict = (event)=>{
        let index = event.target.selectedIndex;
        setDistrito(event.target.options[index].text)
        //console.log(distrito)
        setIndexDistricts(event.target.value)
       
        if(event.target.value !== '0'){
            setCodeUbigeo(event.target.value)
            CodeUbigeo = event.target.value
        }else{
            setCodeUbigeo(IndexProvinces)
            CodeUbigeo = IndexProvinces
        }
    }

    const openTooltipLineAfiliates = ()=>{
        let noexistTag = document.getElementsByTagName('text')[14] === undefined
        if(!noexistTag){
            if(document.getElementsByTagName('text')[14].innerHTML !== document.getElementsByTagName('text')[14].innerHTML){
                document.getElementsByTagName('text')[14].innerHTML = document.getElementsByTagName('text')[14].innerHTML + 'Mar' +'2021'
                document.getElementsByTagName('text')[14].innerHTML = ''
            }else{
               
            }
        }
    }

    const searchdata = () =>{
        
        getDistEdad(CodeUbigeo)
        getdistributiongender(CodeUbigeo)
        getdistributionafiliates(CodeUbigeo)
        getdistributionsexgravity(CodeUbigeo)
        getafiliateshistoriclines(CodeUbigeo)
        getdataNSE(CodeUbigeo)
        setCodeUbigeoSearch(CodeUbigeo)
        RenderBarLateral()
        setFilter(true)
        sumar()
        //BrechaPorCertificar()
    }

    const convertDistEdad= (data)=>{
        var datos= data.data
        if(datos.length>0){
            var resultado=[]

            var total=0
            var totalF=0
            var totalM=0
            
            for (let index = 0; index < datos.length; index= index + 2) {

                total=datos[index].Cantidad + datos[index+1].Cantidad
                totalF= totalF+ datos[index].Cantidad
                totalM= totalM +datos[index+1].Cantidad
                var objeto={
                    nombre:datos[index].RangoEdad,
                    PA:Math.round(((datos[index].Cantidad*100)/total))+"%",
                    PO:Math.round(((datos[index+1].Cantidad*100)/total))+"%",
                    PACantidad:datos[index].Cantidad,
                    POCantidad:datos[index+1].Cantidad
                }
                resultado.push(objeto)
            }

            var resultadoSex=[]
            var objetoF={
                id:1,
                color:"#FE46D5",
                porcentaje: Math.round(((totalF*100)/(totalF+totalM))),
                nombre:"MUJERES",
                labels:"",
                cantidad:totalF
            }

            var objetoM={
                id:2,
                color:"#2F80ED",
                porcentaje:Math.round(((totalM*100)/(totalF+totalM))),
                nombre:"HOMBRES",
                labels:"",
                cantidad:totalM
            }
            resultadoSex.push(objetoF)
            resultadoSex.push(objetoM)

            setDistEdad(resultado)
            setDistSex(resultadoSex)
        }else {
            setDistEdad(null)
            setErrorDistEdad({error:'no hay datos'})
        }
        
    }    
    
    const DistEdadComponent=()=>{        
        if(DistEdad){
            return <> 
                <DistribucionEdad DistEdad={DistEdad} ></DistribucionEdad>
                <ReporteDEdad 
                    registros={DistEdad} region={region} provincia={provincia} distrito={distrito}>
                </ReporteDEdad>
            </>
        }
        else{
            return <Spiner/>
            // return <div className='de--title row' style={{marginLeft:'8%'}}><LoadingComponent configuracion="large" error={ErrorDistEdad} funcionrefresh={getDistEdad} id={CodeUbigeo}  ></LoadingComponent></div>
        }
    }
    
    const DistributionGenderComponent=()=>{
        let rango = ''
        if(DistGender){
            rango = `${edadMinR} - ${edadMaxR}`
            return <> <DistributionGender DistGender={DistGender} tipoGrafico="pie"></DistributionGender>

                <ReporteDSexo
                registros={DistGender}
                rango= {rango}
                region={region} provincia={provincia} distrito={distrito}
                />  
                
           
            </>
        }
        else{
            return <Spiner/>
            // return <div className='de--title row justify-content-center'><LoadingComponent configuracion="otros" error={ErrorDistGender} funcionrefresh={getdistributiongender} id={CodeUbigeo} ></LoadingComponent></div>
        }
    }

    const getDataNSEComponent = ()=>{
        if(DataNSE.length>0){
            return <DataNSEComponent DataNSE={DataNSE}></DataNSEComponent>
        }
        else{
            return <Spiner/>
            // return <div className='de--title row justify-content-center'><LoadingComponent configuracion="otros" error={ErrordataNSE} funcionrefresh={getdataNSE} id={CodeUbigeo} ></LoadingComponent></div>
        }
    }

    
    const DistributionDistLeveGravity = ()=>{
        if(LevelDanger){
            let rango = ''
            rango = `${edadMin} - ${edadMax}`
            return <> <DistributionLevelGravity LevelDanger={LevelDanger} /> 
            
                <ReporteDGravedad
                registros={LevelDanger} 
                rango= {rango}
                region={region} provincia={provincia} distrito={distrito}
                ></ReporteDGravedad>
            </>
        }
        else{
            return <Spiner/>
            // return <div className='de--title row justify-content-center' style={{marginTop:'10px'}}><LoadingComponent configuracion="otros" error={ErrorLevelDanger} funcionrefresh={getdistributionsexgravity} id={CodeUbigeo} ></LoadingComponent></div>
        }
    }
    
    const sumar=()=>{
        setContador(contador+1)
        setFilter(contador%2 === 0 ? false: true)
    }

    const DistributionDistAfiliates = ()=>{
        if(DistAfiliates){
            
            return <> <DistributionAfiliates filtro={filter} onChange={value =>{setFilter(value);sumar(); getdistributionafiliates(CodeUbigeo,filter)}} DistAfiliates={DistAfiliates}/>
                <ReporteDAfiliados
                    registros={DistAfiliates}
                    region={region} provincia={provincia} distrito={distrito}
                ></ReporteDAfiliados>
            </>
        }
        else{
            return <Spiner/>
            // return <div className='de--title row justify-content-center' style={{marginTop:'10px'}}><LoadingComponent configuracion="otros" error={ErrorAfiliates} funcionrefresh={getdistributionafiliates} id={CodeUbigeo} ></LoadingComponent></div>
        }
    }

    const descargarExcel=(id)=>{
        document.getElementById(id).click()
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active) {
            //console.log(payload)
            const monthName = ['En', 'Febr','Mar','Abr','May', 'Jun', 'Jul', 'Ag', 'Sep', 'Oct', 'Nov', 'Dic']
            return (
                
                <div className="pop-div row">
                    {

                        payload.map(data=>{
                            return <div className="col-sm">
                                <div style={{color:'gray', marginBottom:'10px'}}>{data.payload.day+' ' + monthName[Month - 1]+' '+Age}</div>
                                <label style={{marginBottom:'10px'}}>{data.name}</label>
                                <div style={{marginBottom:'10px'}}>{data.payload.value.toLocaleString()}</div>
                                <div style={{color:'gray', marginBottom:'10px'}}>Total</div>
                            </div>
                        })
                        
                    }
                   
                </div>

            );
        }

	    return null;
    };
    
     
    return (
        <>
            <PageHeader activeTableros={true}/>
            <SliderPrincipal/>
                <div className="margin-top"> 
                    <div className="container_extra">
                        <div className="row margin-bottom ">
                            <div className="col-12"> 
                                <div className="heading heading--primary heading--center"><span className="heading__pre-title"></span>
                                    <h2 className="heading__title no-margin-bottom text-primary"><span>RESULTADOS DE LA POBLACIÓN PCD DEL PERÚ</span></h2>
                                </div>
                            </div>
                        </div> 

                        {/* LISTA UBIGEO */}
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header ">
                                        <p className="card-text text-primary font-w900">SELECCIONE UBICACIÓN</p>
                                    </div>
                                    <div className="card-body">
                                        <div className="row centrado">  
                                            <div className="mb-3 col-md-3">
                                                <label className="control-label form-label text-primary"><strong>DEPARTAMENTO </strong></label>                                        
                                                <select className="form-control" onChange={getprovinces} value={IndexDepartaments}>                                            
                                                    {                                                
                                                        Departaments.map((x,index)=>{                                                    
                                                            return <option value={x.CodUbigeo} key={index}>{x.Departamento} </option>
                                                        })                                                
                                                    }
                                                </select>
                                            </div>
                                            <div className="mb-3 col-md-3">
                                                <label className="control-label form-label text-primary"><strong>PROVINCIA </strong></label>
                                                <select className="form-control" onChange={getdistricts} value={IndexProvinces}>
                                                    {                                                
                                                        Provinces.map((x,index)=>{                                                    
                                                            return <option value={x.CodUbigeo} key={index}>{x.Provincia}</option>
                                                        })                                                
                                                    }
                                                </select>
                                            </div>
                                            <div className="mb-3 col-md-3">
                                                <label className="control-label form-label text-primary"><strong>DISTRITO</strong></label>
                                                <select className="form-control" onChange={getcodubigeodistrict}  value={IndexDistricts}>
                                                    {                                                        
                                                        Districts.map((x,index)=>{                                                            
                                                            return <option value={x.CodUbigeo} key={index}>{x.Distrito}</option>
                                                        })                                                        
                                                    }                                                    
                                                </select>
                                            </div>
                                            <div className="mb-3 col-md-3">
                                                <label className="control-label form-label text-primary">&nbsp;</label>
                                                <button className="btn btn-danger form-control" onClick={searchdata}><i className="fa fa-search scale5"></i>&nbsp;BUSCAR </button>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div> 
                        </div>

                         {/* 2DO GRAFICO*/}
                        <div className="row margin-top">
                            <div className="col-12">
                                <div className="card">
                                    {/* <div className="card-header "> </div> */}
                                    <div className="card-body">
                                        { DistEdadComponent() }
                                        <div dangerouslySetInnerHTML={{ __html: setFuentes([1,2,4])}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* SEGUN SEXO - NIVEL DE GRAVEDAD */}
                        <div className="row margin-top">
                            <div className="col-6">
                                <div className="card">
                                    <div className="card-header ">                                    
                                        <p className="card-text text-primary font-w900">DISTRIBUCIÓN POR SEXO</p>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="container-fluid">
                                                <div className="row align-items-end">
                                                    <div className="col-lg-7">
                                                        <h6 >FILTRAR POR EDAD</h6>
                                                    </div>                                                 
                                                    <div className="col-lg-5 text-right">
                                                        {/* <SimplePopoverHistorial typegraphic={'historicsex'}/> */}
                                                        <ReactHTMLTableToExcel
                                                            id="descargar-Dsexo-mapa"
                                                            className="btn btn-danger btn-xs"
                                                            table="table-Dsexo"
                                                            filename="CONADIS PUD SEXO "
                                                            sheet="CONADIS PUD SEXO"
                                                            buttonText="Descargar"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="col-12 labelubicationclassage">
                                                <PrettoSlider  valueLabelDisplay="auto" aria-label="pretto slider" onChange={ChangeValueage} onClick={ClickonChangevaluegender} onTouchEnd={ClickonChangevaluegender} defaultValue={agedist}/>
                                            </div>
                                            <div className="col-1" style={{marginTop:'8px'}}>
                                                <label className='text-dark font-w900'>DE</label>
                                            </div>
                                            <div className="col-3 " style={{marginBottom:'10px'}}>
                                                <input value={edadMinR} onChange={ChangeValueagemin} onBlur={validateagelimit(agedist[0],agedist[1])} type="number" className="form-control" id="exampleFormControlInput1 " />
                                            </div>
                                            <div className="col-1 text-center" style={{marginTop:'8px'}}>
                                                <label className='text-dark font-w900'>A</label>
                                            </div>
                                            <div className="col-3" style={{marginBottom:'10px'}}>
                                                <input value={edadMaxR} onChange={ChangeValueagemax} onBlur={validateagelimit(agedist[0],agedist[1])}  type="number" className="form-control" id="exampleFormControlInput1"/>
                                            </div>
                                            <div className="col-1 col-sm-2 col-md-1 text-center p-0" style={{marginTop:'8px'}}>
                                                <label className='text-dark font-w900'>AÑOS</label>
                                            </div>
                                            <div className="col-3 col-sm-2 col-md-1 text-center" >
                                                {/* <button name="" id="" className="btn buton-excel red--button" type="button" value="Buscar" onClick={handleRange}/> */}
                                                <button className="btn btn-danger"  onClick={handleRange}>BUSCAR </button>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            {DistributionGenderComponent()}
                                        </div>
                                        <div dangerouslySetInnerHTML={{ __html: setFuentes([1,2,4])}}></div>
                                    </div>
                                </div>
                            </div>
                            {/* SEGUN NIVEL DE GRAVEDAD */}                        
                            <div className="col-6">
                                <div className="card">
                                    <div className="card-header ">                                    
                                        <p className="card-text text-primary font-w900">NIVEL DE GRAVEDAD</p>
                                    </div>
                                    <div className="card-body">
                                        <div className="row" >
                                            <div className="container-fluid">
                                                <div className="row align-items-end">
                                                    <div className="col-lg-7">
                                                        <h6 >FILTRAR POR EDAD</h6>
                                                    </div>                                                 
                                                    <div className="col-lg-5 text-right">
                                                        {/* <SimplePopoverHistorial typegraphic={'historicgravity'}/> */}
                                                        <ReactHTMLTableToExcel
                                                            id="descargar-Dgravedad-mapa"
                                                            className="btn btn-danger btn-xs"
                                                            table="table-Dgravedad"
                                                            filename="CONADIS PUD GRAVEDAD "
                                                            sheet="CONADIS PUD GRAVEDAD"
                                                            buttonText="Descargar"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 labelubicationclassage">
                                                <PrettoSlider   valueLabelDisplay="auto" aria-label="pretto slider" onChange={ChangeValueagegravity} onClick={ClickonChangevaluegravity} onTouchEnd={ClickonChangevaluegravity} defaultValue={agegravity}/>
                                            </div>
                                            <div className="col-1 ">
                                                <label className='text-dark font-w900' style={{marginTop:'5px'}}>DE</label>
                                            </div>
                                            <div className="col-3 " style={{marginBottom:'10px'}}>
                                                <input value={edadMinRGravity} onChange={ChangeValueagemingravity} onBlur={validateagelimitgravity(agegravity[0],agegravity[1])}  type="text" className="form-control" id="exampleFormControlInput1 " />
                                            </div>
                                            <div className="col-1 text-center">
                                                <label className='text-dark font-w900' style={{marginTop:'5px'}}>A</label>
                                            </div>
                                            <div className="col-3" style={{marginBottom:'10px'}}>
                                                <input value={edadMaxRGravity} onChange={ChangeValueagemaxgravity} onBlur={validateagelimitgravity(agegravity[0],agegravity[1])}  type="text" className="form-control" id="exampleFormControlInput1"/>
                                            </div>
                                            <div className="col-1 col-sm-2 col-md-1 text-center p-0">
                                                <label className='text-dark font-w900' style={{marginTop:'5px'}}>AÑOS</label>
                                            </div>
                                            <div className="col-3 col-sm-2 col-md-1 text-center" >
                                                {/* <input name="" id="" className="buton-excel red--button" type="button" value="Buscar" onClick={handleRangeGravity}/> */}
                                                <button className="btn btn-danger"  onClick={handleRangeGravity}>BUSCAR </button>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-4">
                                                <span className='text-dark font-w900'>FILTRAR POR SEXO</span>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-check" >
                                                    <input  className="form-check-input" type="checkbox" onChange={Changegender} id="male"/>
                                                    <label className="form-check-label" for="flexCheckIndeterminate" style={{marginLeft:'10px',marginTop:'3px'}}>
                                                        MASCULINO
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-check" >
                                                    <input  className="form-check-input " type="checkbox" onChange={Changegender} id="female"/>
                                                    <label className="form-check-label " for="flexCheckIndeterminate" style={{marginLeft:'10px',marginTop:'3px'}}>
                                                        FEMENINO
                                                    </label>
                                                </div>
                                            </div>   
                                        </div>

                                        <div className="card-body margin-left">
                                            { DistributionDistLeveGravity() }
                                        </div>                                        
                                        <div dangerouslySetInnerHTML={{ __html: setFuentes([1,2,4])}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {/* DISTRIBUCION POR ENTIDAD / ENEDIS*/}
                        <div className="row margin-top">
                            <div className="col-6">
                                <div className="card">
                                    <div className="card-header ">                                    
                                        <p className="card-text text-primary font-w900">DISTRIBUCIÓN POR ENTIDAD</p>
                                    </div>
                                    <div className="card-body">
                                        { DistributionDistAfiliates() }
                                        <div dangerouslySetInnerHTML={{ __html: setFuentes([1,2,4,5])}}></div>
                                        <div ><b>NOTA:</b> Una persona con discapacidad se puede encontrar en mas una base de datos.</div>
                                    </div>
                                </div>
                            </div>
                            {/* ENEDIS */}
                            <div className="col-6">
                                <div className="card">
                                    <div className="card-header ">                                    
                                        <p className="card-text text-primary font-w900">PCD POR CERTIFICAR, CÁLCULO SEGÚN ENEDIS</p>
                                    </div>
                                    <div className="card-body">
                                        <BrechaPorCertificar/> 
                                        <div dangerouslySetInnerHTML={{ __html: setFuentes([1,2,3,4])}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*  RNPCD / PROGRAMA CONTIGO*/}
                        <div className="row margin-top">                            
                            {/* RNPCD */}                        
                            <div className="col-6">
                                <div className="card">
                                    <div className="card-header ">                                    
                                        <p className="card-text text-primary font-w900"> BRECHA DE LA POBLACIÓN NO INSCRITA EN EL RNPCD VS. PCD CERTIFICADA SEGÚN HIS DISCAP WEB</p>
                                    </div>
                                    <div className="card-body">
                                        <BrechaInscripcionCONADIS/>
                                        <div dangerouslySetInnerHTML={{ __html: setFuentes([1,2,3,4])}}></div>
                                    </div>
                                </div>
                            </div>
                            {/* PROGRAMA CONTIGO */}
                            <div className="col-6">
                                <div className="card">
                                    <div className="card-header ">                                    
                                        <p className="card-text text-primary font-w900">POTENCIALES PCD SEVERA NO INSCRITOS EN EL PROGRAMA CONTIGO</p>
                                    </div>
                                    <div className="card-body">
                                        <BrechaProgramaSocialCONTIGO/>
                                        <div dangerouslySetInnerHTML={{ __html: setFuentes([1,2,4])}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                       {/* PENSION 65*/}
                       <div className="row margin-top">                            
                            {/* PENSION 65 */}                        
                            <div className="col-6">
                                <div className="card">
                                    <div className="card-header ">                                    
                                        <p className="card-text text-primary font-w900">USUARIOS CON DISCAPACIDAD DEL PROGRAMA PENSIÓN 65</p>
                                    </div>
                                    <div className="card-body">
                                        <Pension65_Cobertura tipoData="cobertura"/>
                                        <div dangerouslySetInnerHTML={{ __html: setFuentes([1,2,4,5])}}></div>
                                    </div>
                                </div>
                            </div>
                            {/* PENSION 65 */}                        
                            <div className="col-6 margin-bottom">
                                <div className="card">
                                    <div className="card-header ">                                    
                                        <p className="card-text text-primary font-w900">POTENCIALES USUARIOS RESPECTO AL PROGRAMA PENSIÓN 65</p>
                                    </div>
                                    <div className="card-body">
                                        <Pension65_Cobertura tipoData="brecha"/>
                                        <div dangerouslySetInnerHTML={{ __html: setFuentes([1,2,4,5])}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                         
                    </div>
                </div>
        </>
    )
}

export default PageDashboard

import React, {useState,useEffect, Component} from 'react' 
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
// import "../cuotaempleoPage.css"
import axios from 'axios';
import '../../../AppStyle.css'
import Spiner from '../../../componentes/Spiner';
function EstablecimientoSalud (props) {
    const [dataCuota, setDataCuota] = useState(props.listaSedes);
    const [listaSalud, setListaSalud] = useState([]); 
    const [dataShow, setDataShow] = useState(false); 

    useEffect(() => {
        getAPISalud([]);
        const timer = setTimeout(() => {
            getAPISalud();
          }, 200);
        return () => clearTimeout(timer);
      }, []);

    const getAPISalud=()=>{
        axios.get(`https://fiscalizacion.conadisperu.gob.pe/api/getEstablecimientosSalud/`).then(response => {
            setListaSalud(response.data);
            setDataShow(true);
        }).catch(function(error) {
            console.log(error);
        });
    }


    return(
        <div className="col-12">
            <div className="row">
                <div className="col-md-12 text-center"> 
                    <div className="card">
                        <div className="card-header ">
                            <h5 className="card-text font-w900">SERVICIOS DE SALUD A NIVEL NACIONAL, SEGÚN CATEGORÍAS POR NIVELES DE ATENCIÓN</h5>
                        </div> 
                    </div>
                </div> 
                <div className="col-md-12 text-center"> 
                    <div className="card">
                        <div className="card-body">
                            {  
                            dataShow ?
                            <div className="table-responsive"> 
                                <div className="col text-right" style={{marginBottom: '5px'}}> 
                                    <ReactHtmlTableToExcel
                                        id="test-table-xls-button"
                                        className="btn btn-danger"
                                        table="tblsalud"
                                        filename="CONADIS - ESTABLECIMIENTOS DE SALUD"
                                        sheet="ESTAB.SALUD"
                                        buttonText="Descargar Información"
                                    /> 
                                </div>                               
                                <table id='tblsalud' border="1" className="table table-striped table-bordered "  width="100%">
                                    <thead> 
                                        <tr className='tablita'>
                                        <th colSpan= "13">ESTABLECIMIENTOS DE SALUD A NIVEL NACIONAL, SEGÚN INSTITUCIONES
                                            PRESTADORAS DE SERVICIOS DE SALUD RENIPRESS DE LA SUPERINTENDENCIA NACIONAL DE SALUD – SUSALUD, Y POR REGIONES</th> 
                                            
                                        </tr>
                                        <tr className='tablita' style={{fontSize: '14px'}}>                                            
                                            <th>REGIONES</th>
                                            <th>TOTAL</th>                                            
                                            <th>PUESTO DE SALUD</th>
                                            <th>PUESTO DE SALUD CON MÉDICO</th>
                                            <th>CENTRO DE SALUD SIN INTERNAMIENTO</th>
                                            <th>CENTRO DE SALUD CON INTERNAMIENTO</th> 
                                            <th>HOSPITAL I</th>
                                            <th>HOSPITAL II</th>
                                            <th>HOSPITALES DE ATENCIÓN ESPECIALIZADA, CLÍNICAS DE ATENCIÓN ESPECIALIZADA</th>
                                            <th>HOSPITAL III - HOSPITALES Y CLÍNICAS DE ATENCIÓN GENERAL</th>
                                            <th>INSTITUTO ESPECIALIZADO</th>
                                            <th>HOSPITALES Y CLÍNICAS DE ATENCIÓN ESPECIALIZADA</th>
                                            <th>SIN INFORMACIÓN</th> 
                                        </tr> 
                                    </thead>
                                    <tbody style={{fontSize: '15px'}}>
                                        {
                                            listaSalud.map((x,index)=>{                           
                                                return( 
                                                    <tr key={index}>                                         
                                                        <td className="colorNegrita" >{x.region}</td>  
                                                        <td className="colorNegrita" >{x.info1 || 0}</td>
                                                        <td className="colorNegrita" >{x.info2 || 0}</td>
                                                        <td className="colorNegrita" >{x.info3 || 0}</td>
                                                        <td className="colorNegrita" >{x.info4 || 0}</td>
                                                        <td className="colorNegrita" >{x.info5 || 0}</td>
                                                        <td className="colorNegrita" >{x.info6 || 0}</td>
                                                        <td className="colorNegrita" >{x.info7 || 0}</td>
                                                        <td className="colorNegrita" >{x.info8 || 0}</td>
                                                        <td className="colorNegrita" >{x.info9 || 0}</td>
                                                        <td className="colorNegrita" >{x.info10 || 0}</td>
                                                        <td className="colorNegrita" >{x.info11 || 0}</td>
                                                        <td className="colorNegrita" >{x.info12 || 0}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr className='tablita'>
                                            <th colSpan= "13" className="colorNegritaLeft">
                                            Lima DIRIS Centro: comprende los distritos: LIMA DIRIS CENTRO: Breña, Jesús María, La Victoria, Lima, Lince, Magdalena del Mar, Miraflores, Pueblo Libre, San Borja, San Isidro, San Juan de Lurigancho, San Luis, San Miguel, Surquillo. 
                                            LIMA DIRIS Este: Ate, Chaclacayo, Cieneguilla, El Agustino, Huaycan, La Molina, Lurigancho, Santa Anita. 
                                            LIMA DIRIS Norte: Ancón, Carabayllo, Comas, Independencia, Los Olivos, Puente Piedra, Rímac, San Martín de Porres, Santa Rosa. 
                                            LIMA DIRIS Sur: Barranco, Chorrillos, Lurín, Pachacamac, Pucusana, Punta Hermosa, Punta Negra, San Bartolo, San Juan de Miraflores, Santa María del Mar, Santiago de Surco, Villa El Salvador, Villa María del Triunfo.												
                                            <br/>
                                                <a className="aside-socials__link text-primary" href="https://www.datosabiertos.gob.pe/dataset/establecimientos-de-salud " target="_blank">
                                                    IR A FUENTE
                                                </a>
                                            </th> 
                                        </tr>
                                    </tfoot>
                                </table>   
                            </div>
                            : <Spiner/>  
                            }                                         
                        </div> 
                    </div> 
                </div>     
                <div className="col-md-12 text-center">
                    <div className="card">
                        <div className="card-body">
                        {  
                            dataShow ?
                            <div className="donation-item">
                                <div className="col text-right"> 
                                    <ReactHtmlTableToExcel
                                        id="test-table-xls-button"
                                        className="btn btn-danger"
                                        table="tblsalud"
                                        filename="CONADIS - ESTABLECIMIENTOS DE SALUD"
                                        sheet="ESTAB.SALUD"
                                        buttonText="Descargar Información"
                                    /> 
                                </div>
                                <div className="donation-item__img2">
                                    <img className="img--bg" style={{position: 'initial'}} src="img/grafico01.png" alt="img"/>
                                </div>
                            </div>
                            : <Spiner/>  
                        } 
                        </div>                         
                    </div>
                </div>
                 
            </div>
        </div>
        
    );
}

export default EstablecimientoSalud;
import React, {useState,useEffect} from 'react'
import URL from '../../url.json'
import axios from 'axios';
import headers from '../../headers.json';

 const piura = ()=>{ return {hola:"ffff",op:"dffff"} };

 const getDataPension65_data=(cod)=>{
    
    //const [dataSource, setdataSource] = useState([]);
  var ddd;

    //setErrorBrechaPcd(null)

      axios.post(URL.apiURL+'getPension65_resumen',{ubigeo:'0'},headers)
      .then(response => {
           //setdataSource(response.data);
           ddd =response.data;
      })
      .catch(err=> {
          console.log('error al obtener la informacion', err)
          //setErrorBrechaPcd(err);
      })
      return ddd
  }
//export { getDataPension65_data}
export default piura;

import Control from "react-leaflet-custom-control";

export function LeyendaMapaPoblacion() {
    let legend = [];
    legend.push( 
        <div className="card">
            <div className="card-body">
                <table border="0" style={{ widht: '100%'}}>
                    <thead style={{ height: '200px'}}> 
                        <tr> 
                            <th colSpan="2" rowSpan= "8" className="color-degradado" style={{ width: '25px'}}></th>
                        </tr> 
                        <tr style={{ width: '70px'}}><th>&nbsp;5.000 </th></tr>
                        <tr style={{ width: '70px'}}><th>&nbsp; </th></tr>
                        <tr style={{ width: '70px'}}><th>&nbsp; </th></tr>
                        <tr style={{ width: '70px'}}><th>&nbsp;2.000 </th></tr>
                        <tr style={{ width: '70px'}}><th>&nbsp; </th></tr>
                        <tr style={{ width: '70px'}}><th>&nbsp; </th></tr>
                        <tr style={{ width: '70px'}}><th>&nbsp;0 </th></tr> 
                    </thead> 
                </table>  
            </div>
        </div>
    );

    return (
        <Control position="bottomleft">
             {legend}          
        </Control>
    )
}

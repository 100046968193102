import Control from "react-leaflet-custom-control";

export function LeyendaMapaInversion() {
    let legend = [];
    legend.push( 
        <div className="card">
            <div className="card-body">
                <table border="0">
                    <tbody>
                        <tr>
                            <td className="font-w900"><strong>LEYENDA</strong></td>
                        </tr>
                        <tr>
                            <td className="leyenda">
                                <img className="leyendaImg" src="https://maps.google.com/mapfiles/ms/micons/green-dot.png"/>
                                <strong> CUMPLIMIENTO (0.5%) </strong>
                            </td>
                        </tr>
                        <tr>
                            <td className="leyenda">
                                <img className="leyendaImg" src="https://maps.google.com/mapfiles/ms/micons/yellow-dot.png"/>
                                <strong> NO PRESENTARON INFORMACIÓN </strong>
                            </td>
                        </tr>
                        <tr>
                            <td className="leyenda">
                                <img className="leyendaImg" src="https://maps.google.com/mapfiles/ms/micons/red-dot.png"/>
                                <strong> NO CUMPLE (0.5%) </strong>
                            </td>
                        </tr>                      
                    </tbody>
                </table>
            </div>
        </div>
    );
      

    return (
        <Control position="bottomleft">
             {legend}          
        </Control>
    )
}


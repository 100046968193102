
import React, {useState,useEffect, Component} from 'react' 
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
// import "../cuotaempleoPage.css"
import axios from 'axios';
import '../../../AppStyle.css'
import Spiner from '../../../componentes/Spiner';

function CebePrite (props) {
    const [listaCebeprite, setListaCebeprite] = useState([]); 
    const [dataShow, setDataShow] = useState(false); 

    useEffect(() => {
        getAPICebeprite([]);
        const timer = setTimeout(() => {
            getAPICebeprite();
          }, 200);
        return () => clearTimeout(timer);
      }, []);

    const getAPICebeprite=()=>{
        axios.get(`https://fiscalizacion.conadisperu.gob.pe/api/getCebePrite/`).then(response => {
            setListaCebeprite(response.data);
            setDataShow(true);
        }).catch(function(error) {
            console.log(error);
        });
    }


    return(
        <div className="col-12">
            <div className="row">
                <div className="col-md-12 text-center"> 
                    <div className="card">
                        <div className="card-header ">
                            <h5 className="card-text font-w900"> INSTITUCIONES DE CENTRO DE EDUCACIÓN BÁSICA ESPECIAL (CEBE)  
                            Y PROGRAMA DE INTERVENCIÓN TEMPRANA (PRITE), SEGÚN REGIONES</h5>
                        </div> 
                    </div>
                </div> 
                     
                <div className="col-md-7 text-center">
                    <div className="card">
                        <div className="card-body"> 
                        {  
                            dataShow ?
                            <div className="donation-item">
                                <div className="col text-right"> 
                                    <ReactHtmlTableToExcel
                                        id="test-table-xls-button"
                                        className="btn btn-danger"
                                        table="tblcebeprite"
                                        filename="CONADIS - CEBE Y PRITE"
                                        sheet="CEBE Y PRITE"
                                        buttonText="Descargar Información"
                                    /> 
                                </div>
                                <div className="donation-item__img2">
                                    <img className="img--bg" style={{position: 'initial'}} src="img/grafico02.png" alt="img"/>
                                </div>
                            </div> 
                            : <Spiner/>  
                        }
                        </div> 
                    </div>
                </div>
                <div className="col-md-5 text-center"> 
                    <div className="card"> 
                        <div className="card-body">
                        {  
                            dataShow ?
                            <div className="table-responsive">
                                <table id='tblcebeprite' border="1" className="table table-striped table-bordered "  width="100%">                                    
                                    <thead>
                                        <tr className='tablita' >
                                            <th colSpan= "4">
                                            INSTITUCIONES DE CENTRO DE EDUCACIÓN BÁSICA ESPECIAL (CEBE)  
                                            Y PROGRAMA DE INTERVENCIÓN TEMPRANA (PRITE), SEGÚN REGIONES
                                            </th> 
                                        </tr> 
                                        <tr className='tablita' style={{fontSize: '14px'}}> 
                                            <th>REGIONES</th>
                                            <th>TOTAL</th>
                                            <th>CENTRO DE EDUACIÓN BÁSICA ESPECIAL (CEBES)</th>
                                            <th>PROGRAMAS DE INTERVENCIÓN (PRITE)</th>                                            
                                        </tr> 
                                    </thead>
                                    <tbody style={{fontSize: '15px'}}>
                                        {
                                            listaCebeprite.map((x,index)=>{                           
                                                return( 
                                                    <tr key={index}>                                         
                                                        <td className="colorNegrita" >{x.region}</td>  
                                                        <td className="colorNegrita" >{x.info1 || 0}</td>
                                                        <td className="colorNegrita" >{x.info2 || 0}</td>
                                                        <td className="colorNegrita" >{x.info3 || 0}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr className='tablita'>
                                            <th colSpan= "4" className="colorNegritaLeft">
                                                1/ Comprende los 43 distritos que conforman la provincia de Lima.<br/>
                                                2/ Comprende a las provincias de: Barranca, Cajatambo, Canta, Cañete, Huaral, Huarochiri, Huaura, Oyón y Yauyos.
                                                <br/>
                                                <a className="aside-socials__link text-primary" 
                                                    href="https://www.datosabiertos.gob.pe/dataset/datos-sobre-discapacidad-en-educaci%C3%B3n/resource/68eef25f-24af-4e7f-8246-987b0495524c#{currentView:!grid,view-grid:{columnsWidth:[{column:%22REGI%EF%BF%BDN%22,width:211}]}}" target="_blank">
                                                    IR A FUENTE
                                                </a>
                                            </th> 
                                        </tr>
                                    </tfoot>
                                </table>   
                            </div> 
                            : <Spiner/>  
                        }                                          
                        </div> 
                    </div> 
                </div> 
            </div>
        </div>
    );
}

export default CebePrite;
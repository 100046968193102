import React, {useState,useEffect} from 'react'
import { NavLink } from 'react-router-dom';
import '../AppStyle.css';

const PageFooter = (props) => {
    return ( 
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 col-lg-5" id="socials">
                        <div className="footer-logo">
                            <a className="footer-logo__link" href="index.html">
                                <img className="footer-logo__img" src="img/conadis.png" alt="logo"/>
                            </a>
                        </div>
                        <ul className="footer-socials">
                            <li className="footer-socials__item">
                                <a className="footer-socials__link" href="#"><img className="footer-logo__img" src="img/bicentenario_logo.png" alt="logo"/>
                                </a>
                            </li>

                            <li className="footer-socials__item"><a className="footer-socials__link" href="https://facebook.com/mimp.conadis" target="_blank"><i className="fa fa-facebook fa-2x" aria-hidden="true"></i></a></li>
                            <li className="footer-socials__item"><a className="footer-socials__link" href="https://twitter.com/Mimpconadis" target="_blank"><i className="fa fa-twitter fa-2x" aria-hidden="true"></i></a></li>
                            <li className="footer-socials__item"><a className="footer-socials__link" href="https://instagram.com/mimpconadis/" target="_blank"><i className="fa fa-instagram fa-2x" aria-hidden="true"></i></a></li>
                            <li className="footer-socials__item"><a className="footer-socials__link" href="https://youtube.com/conadis2011" target="_blank"><i className="fa fa-youtube fa-2x" aria-hidden="true"></i></a></li>
                        </ul>
                    </div>
                    <div className="col-sm-12 col-lg-4">
                        <h4 className="footer__title">Sede Central:</h4>
                        <div className="footer-contacts">
                            {/* <p className="footer-contacts__address">Elliott Ave, Parkville VIC 3052, Melbourne Canada</p>
                            <p className="footer-contacts__phone">Phone: <a href="tel:+31859644725">+31 85 964 47 25</a></p>
                            <p className="footer-contacts__mail">Email: <a href="mailto:support@helpo.org">support@helpo.org</a></p> */}
                            <p className="footer-contacts__address"><i className="fa fa-map-marker"></i>&nbsp;Av. Arequipa 375 – Lima, Perú</p>
                            <p className="footer-contacts__phone"><i className="fa fa-headphones"></i>&nbsp;Central telefónica: <a href="tel:+6305170">(01) 630 5170</a></p>
                            <p className="footer-contacts__mail"><i className="fa fa-phone" ></i>&nbsp;Línea gratuita: <a href="tel:080000151">0800-00151</a></p>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-3">
                        <h4 className="footer__title">Enlaces de Interés:</h4>
                        <nav>
                            <ul className="footer-menu">
                                <li className="footer-menu__item"><a className="footer-menu__link" href="https://www.gob.pe/mimp" target="_blank">MIMP</a></li>
                                <li className="footer-menu__item"><a className="footer-menu__link" href="https://www.gob.pe/midis" target="_blank">MIDIS</a></li>
                                <li className="footer-menu__item"><a className="footer-menu__link" href="https://contigo.gob.pe/" target="_blank">CONTIGO</a></li>
                                <li className="footer-menu__item"><a className="footer-menu__link" href="https://www.onpe.gob.pe/" target="_blank" >ONPE</a></li> 
                                <li className="footer-menu__item"><a className="footer-menu__link" href="https://portal.jne.gob.pe/portal" target="_blank">JNE</a></li>									
                                <li className="footer-menu__item"><a className="footer-menu__link" href="https://www.reniec.gob.pe/" target="_blank">RENIEC</a></li>
                                {/* <li className="footer-menu__item"><a className="footer-menu__link" href="causes.html">Causes</a></li> */}
                            </ul>
                        </nav>
                    </div>
                    {/* <div className="col-sm-6 col-lg-3">
                        <h4 className="footer__title">Donate</h4>
                        <a className="button footer__button button--filled" href="#">Portal de Transparencia</a>
                        <a className="button footer__button button--filled" href="#">Libro de Reclamaciones</a>
                    </div> */}
                </div>
                <div className="row align-items-baseline">
                    <div className="col-sm-12 col-lg-12">
                        <p className="footer-copyright">© 2022 CONADIS - Todos los Derechos Reservados</p>
                    </div>
                    {/* <div className="col-sm-12 col-lg-6">
                        <div className="footer-privacy">
                            <a className="footer-privacy__link" href="http://www.transparencia.gob.pe/enlaces/pte_transparencia_enlaces.aspx?id_entidad=13649&id_tema=1&ver=D#.YJVPjrVKiUm" target="_blank">
                                <i className="fa fa-search" aria-hidden="true"></i>&nbsp;Portal de Transparencia</a>
                                <span className="footer-privacy__divider">|</span>
                            <a className="footer-privacy__link" href="https://reclamos.servicios.gob.pe/?institution_id=45" target="_blank">
                                <i className="fa fa-book" aria-hidden="true"></i>&nbsp;Libro de Reclamaciones</a>
                        </div>
                    </div> */}
                </div>
            </div>
        </footer>
    )
}

export default PageFooter

import React, {useEffect,useRef,useState } from 'react'
import axios from 'axios';
// import ResumenTablaEmpleo from './resumenCuotaMapa';
import LeyendaMap from './leyendaEducaMapa';
import mapaPeru from './mapaperu.json' 
import { Map, TileLayer, Marker, Popup, Polygon, GeoJSON, MapControl } from 'react-leaflet'
import MapBoxGLLayer from './MapBoxGLLayer';

import URL from '../../url.json'
 
import 'leaflet/dist/leaflet.css'
import "./cuotaempleoPage.css"
import '../../AppStyle.css';
import Spiner from '../../componentes/Spiner';
import ResumenTablaEducacion from './resumenEducacionMapa';
import ResumenIE from './InstitucionesEducativas';
import ResumenCetpro from './Cetpros';
import ResumenUniversidades from './Universidades';
import { AppConstant } from '../../constants';
// import { LeyendaMapaPoblacion } from './leyendaPoblacion';
// import ReporteTable from './reporteTable';
// import InformacionMapa from './InformacionMapa';

const MapEdu = (props) => { 
    const { anio} = props;
    // TODO MAPA    
    const [listaSedesEducacion, setListaSedesEducacion] = useState([]);
    const [showEducacion, setShowEducacion] = useState(true); 
    const [showIE, setShowIE] = useState(false);
    const [tableDataIE, setTableDataIE] = useState(null);
    
    const [showCetpro, setShowCetpro] = useState(false);
    const [tableDataCetpro, setTableDataCetpro] = useState(null);

    const [showUniv, setShowUniv] = useState(false);
    const [tableDataUniv, setTableDataUniv] = useState(null);

    const [isPoblacion, setPoblacion] = useState([]);
    const GeoJSONEL = useRef(null);
    const MapEL = useRef(null);
    const MapBoxEl = useRef(null);
    const [tableData, setTableData] = useState(null);

    useEffect(() => {
        setListaSedesEducacion(null);
        const timer = setTimeout(() => {
            getAPIEducacion();
          }, 200);
        return () => clearTimeout(timer);
      }, [anio]);
   

    // TODO MAPA
    const getAPIEducacion=()=>{
        axios.get(`${AppConstant.apiUrl}/getEducaMateriaPCD`, { params: { anio}}).then(response => {
            setListaSedesEducacion(response.data);
        }).catch(function(error) {
            console.log(error);
        });
    }
    const onEachFeature = (feature, layer) => {
        layer.on({
        //   mouseover: highlightFeature,
        //   mouseout: resetHighlight,
          click: clickFeature.bind(this)
        //   click: zoomToFeature
        });
        layer.bindTooltip(
            "<div><b>" +feature.properties.NOMBDEP +"</b></div>",
            { permanent: true, direction:'center', className: 'countryLabel'}
        ); 
    }
    const highlightFeature = (e) => {
        var layer = e.target;
        layer.setStyle({
            weight: 5,
            color: "red",
            dashArray: "",
            fillOpacity: 0.7
        });
        layer.bringToFront();    
        // MapInfoEl.current.setInfo(layer.feature.properties);
    }   
    const resetHighlight = (event) => {
        GeoJSONEL.current.leafletElement.resetStyle(event.target);
     }
    function clickFeature({ target }) {
        setPoblacion(target.feature.properties)
        // setTableDataIE(null);
        // setTableDataCetpro(null);
        // setTableDataUniv(null);

        setShowEducacion(true);
        setShowIE(false);
        setShowCetpro(false);
        setShowUniv(false);

        setInfoSedes(target.feature.properties.FIRST_IDDP);

        target.setStyle({ 
          weight: 5,
          color: '#C60701',
          fillOpacity: 1,
        });
        target.bringToFront();

       
       

    }
    const style = (feature) => {
        return ({
            weight: 4,
            opacity: 5,
            fillOpacity: 0.8,
            color:'#4A4C70',
            fillColor:getColor(feature.properties, listaSedesEducacion),
        });
    }  

    const getColor = (mapa, sedes) =>{
        for (let index = 0; index < sedes.length; index++) {
            if(sedes[index].cod_sede == mapa.FIRST_IDDP){ 
                   if(sedes[index].tot_ef > 0) {
                    return '#03E366' //Verde
                   } else {
                    return '#F56273' //Rojo
                   }
            }
        }
    };    

    const setInfoSedes = (sede) => {
        axios.post(URL.apiFiscaURL+'getEducaInfo',{ codsede: sede, tipo: 1 }).then((response)=>{
            setTableDataIE(response.data) 
        }).catch(err=> { console.log("este->",err)});
        
        axios.post(URL.apiFiscaURL+'getEducaInfo',{ codsede: sede, tipo: 2 }).then((response)=>{
            setTableDataCetpro(response.data) 
        }).catch(err=> { console.log("este->",err)});

        axios.post(URL.apiFiscaURL+'getEducaInfo',{ codsede: sede, tipo: 3 }).then((response)=>{
            setTableDataUniv(response.data) 
        }).catch(err=> { console.log("este->",err)});

    }
    
    const clickIE = () => {
        setShowEducacion(false);
        setShowIE(true);
        setShowCetpro(false);
        setShowUniv(false);
    }
    const clickCetpro = () => {
        setShowEducacion(false);
        setShowIE(false);
        setShowCetpro(true);
        setShowUniv(false);
    }
    const clickUniv = () => {
        setShowEducacion(false);
        setShowIE(false);
        setShowCetpro(false);
        setShowUniv(true);
    }

    const MapaPoblacion=()=>{
        if(listaSedesEducacion){
            return( 
                <Map  
                    ref={MapEL} 
                    center={[-9.378835, -74.906473]} 
                    zoom={6} 
                    maxZoom= {10}
                    minZoom= {6}
                    doubleClickZoom={false}
                    scrollWheelZoom={false}
                    attributionControl={false}
                    // zoomControl={false}
                    // dragging={false}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
                    />
                    <MapBoxGLLayer
                        ref={MapBoxEl} 
                        accessToken={'pk.eyJ1IjoieGN1bGxvYXgiLCJhIjoiY2wyZndoeHR1MDBuNTNsbHIwMzc5MHVlciJ9.5_UMSagakftvT7JmY7hc8w'}
                    />
                    <GeoJSON ref={GeoJSONEL}  data={mapaPeru.features} style={style} onEachFeature={onEachFeature} />
                    {/* <LeyendaMapaPoblacion/> */}
                </Map>
            ) 
        } else{
            return <Spiner/>
        }
    }
    // FIN TODO MAPA

    return (
        <div className="col-12">
            <div className="row">                       
                <div className="col-lg-5 text-center">
                     
                    <div className="card">
                        <div className="card-body">
                            {MapaPoblacion()}                                                            
                        </div> 
                    </div>
                     
                </div>
                <div className="col-lg-7 text-center">
                    <div className="row margin-bottom">
                        <div className="col-12">
                            <div className="heading heading--primary heading--center">
                                { (isPoblacion['NOMBDEP'] != null) ? 
                                    <div>
                                        <h5 className="heading__title no-margin-bottom text-primary"><span> {isPoblacion['NOMBDEP']}</span> </h5>
                                        <div className="row align-items-baseline">
                                            <div className="col-12">
                                                <ul className="shop-filter">
                                                    <li className="shop-filter__item shop-filter__item--active">
                                                        <span><h6><i className="fa fa-info-circle"></i>&nbsp;SEGÚN RUBRO DE FISCALIZACIÓN</h6></span>
                                                        <ul className="shop-filter__sub-list">
                                                            <li><span onClick={clickIE}>INSTITUCIONES EDUCATIVAS</span></li>
                                                            <li><span onClick={clickCetpro}>CETPROS</span></li>
                                                            <li><span onClick={clickUniv}>UNIVERSIDADES</span></li> 
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    : <h5 className='text-primary'> SELECCIONE UNA REGIÓN EN EL MAPA PARA VER MÁS INFORMACIÓN</h5> 
                                }
                            </div> 
                        </div>
                    </div>

                    { listaSedesEducacion && showEducacion ?
                        <div className="row"> 
                            <div className="col-12 ">
                                <div className="card">
                                    <div className="card-header text-center">
                                        <h4 className="card-text text-primary font-w900"> RESULTADOS SEGÚN REGIONES</h4>
                                    </div>
                                    <div className="card-body">
                                        {<ResumenTablaEducacion listaSedes={listaSedesEducacion}/>}
                                    </div> 
                                </div>
                            </div>
                        </div>                 
                        : ''
                    }
                    {
                        showIE ? <ResumenIE lista={tableDataIE}/> : ''
                    }
                    {
                        showCetpro ? <ResumenCetpro lista={tableDataCetpro}/> : ''
                    }
                    {
                        showUniv ? <ResumenUniversidades lista={tableDataUniv}/> : ''
                    }
                </div>
            </div>
        </div>
    )
}

export default MapEdu
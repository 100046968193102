import React, {useState,useEffect} from 'react'
import {  NavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import {IconButton, Divider} from '@material-ui/core';
import { Link } from "react-router-dom";



const useStyles = makeStyles((theme) => ({
    typography: {
      padding: theme.spacing(2),
    },
  }));
  
export default function SimplePopoverHistorial(props) {
    useEffect(async() => {
        settypegraphic(props.typegraphic)
        //console.log(props.typegraphic)
    })
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [typegraphic, settypegraphic] = useState(props.typegraphic)
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    
    

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
  
    return (
      <div>
        <IconButton aria-describedby={id} variant="contained" aria-label="delete" className={classes.margin} onClick={handleClick}>
            <i className="fa fa-ellipsis-v"></i>
        </IconButton>
       
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Typography className={classes.typography}><Link to={"/"+typegraphic} style={{ textDecoration: 'none', color:'black' }}>Ver detalles históricos</Link></Typography>
          {/*<Divider variant="middle" />
          <Typography className={classes.typography}>Descargar historial</Typography>*/}
        </Popover>
      </div>
    );
}


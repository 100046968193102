
import React, {useState,useEffect, Component} from 'react' 
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import "../cuotaempleoPage.css"

function ResumenTablaRubro (props) {
    const [dataCuota, setDataCuota] = useState(props.listaSedes);
    useEffect(() => {
        setDataCuota(props.listaSedes)
    }, [props.listaSedes]);

    return(        
        <div className="table-responsive"> 
            <div className="col text-right" style={{marginBottom: '5px'}}> 
                <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-danger"
                    table="tblRubro"
                    filename="CONADIS - EJE EMPLEO (RUBRO DISCAPACIDAD)"
                    sheet="RUBRO DISCAPACIDAD"
                    buttonText="Descargar Información"
                /> 
            </div>

            <table id="tblRubro" border="1" className="table table-striped table-bordered " >
                <thead> 
                    <tr className='tablita'>
                        <th colSpan= "8"> RUBRO DISCAPACIDAD (15%) </th> 
                    </tr>
                    <tr className='tablita'> 
                        <th rowspan= "2">ENTIDADES</th>
                        <th rowspan= "2">TOTAL DE ENTIDADES FISCALIZADAS</th>
                        <th rowspan= "2">TOTAL DE ENTIDADES QUE SÍ PRESENTARON INFORMACIÓN </th>
                        <th rowspan= "2">%</th>
                        <th colspan= "2">CUMPLIMIENTO DEL RUBRO DE DISCAPACIDAD PARA OTORGAR LA BONIFICACIÓN DEL 15%</th>
                        <th rowspan= "2">TOTAL DE ENTIDADES QUE NO PRESENTARON A LA FISCALIZACÓN</th> 
                        <th rowspan= "2">%</th>
                    </tr> 
                    <tr className='tablita'>                                       
                        <th>SI (ABS) </th>
                        <th>NO (ABS)</th> 
                    </tr> 
                </thead>
                <tbody style={{fontSize: '14px'}}>
                    {
                        dataCuota.map((x,index)=>{                           
                            return( 
                                <tr key={index}>                                         
                                    <td className=" colorNegrita" >{x.sede}</td>  
                                    <td className="colorNegrita" >{x.tot_ef || 0}</td>
                                    <td className="colorNegrita" >{x.totef_sinfo || 0}</td>
                                    <td className="colorNegrita" >{parseFloat(x.totef_sinfo_porc || 0).toFixed(2)}</td>
                                    <td className="colorNegrita" >{x.tot_bon01 || 0}</td>
                                    <td className="colorNegrita" >{x.tot_bon02 || 0}</td>
                                    <td className="colorNegrita" >{x.totef_ninfo || 0}</td>
                                    <td className="colorNegrita" >{parseFloat(x.totef_ninfo_porc || 0).toFixed(2)}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
                <tfoot>
                    <tr className='tablita'>
                        <th colspan= "8" class="colorNegritaLeft">
                            * Rubro de discapacidad: Contemplada en el artículo 48° de la Ley N° 29973, Ley General de la Persona con Discapacidad.<br/>
                            Fuente: Sub Dirección de Fiscalización - CONADIS<br/>
                            Fecha de Corte: 31/12/2021
                        </th> 
                    </tr>
                </tfoot>
            </table>
        </div>     
    );
    
}

export default ResumenTablaRubro;

import React, {useState,useEffect,useRef} from 'react'
import ReactHtmlTableToExcel from 'react-html-table-to-excel' 
import CurrencyFormat from 'react-currency-format';

import "../cuotaempleoPage.css"

  function ResumenTablaInversion (props) {
    console.log(props);
    const [dataInversion, setDataInversion] = useState(props.listaSedes);
    const [showTable, setShowTable] = useState(false);
    const tableRefHide = useRef(null);
    useEffect(() => {
        setDataInversion(props.listaSedes);
        setShowTable(false)
    }, [props.listaSedes]);

    return(
        <div className="table-responsive">
            <div className="col text-right" style={{marginBottom: '5px'}}> 
                <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-danger"
                    table="tblCuota"
                    filename="CONADIS - EJE PRESUPUESTO (INVERSIÓN)"
                    sheet="INVERSIÓN"
                    buttonText="Descargar Información"
                /> 
            </div> 
            <table id="tblCuota" border="1" className="table table-striped table-bordered">
                <thead> 
                    <tr className='tablita'>
                        <th colSpan= "6"> CUMPLIMIENTO DEL PRESUPUESTO PÚBLICO (0.5% PARA ACCESIBILIDAD) </th> 
                    </tr>
                    <tr className='tablita'> 
                        <th>ENTIDADES</th>
                        <th>ASIGNACIÓN PRESUPUESTAL (PIM - 2021 PARA ACCESIBILIDAD)</th>
                        {/* <th>ASIGNACIÓN PRESUPUESTAL FALTANTE (PIM - 2021 ACCESIBILIDAD) </th> */}
                        <th>% CUMPLIMIENTO</th>
                        {/* <th>% FALTANTE AL CUMPLIMIENTO DEL 0.5%</th> */}
                        <th>TOTAL ENTIDADES FISCALIZADOS</th>
                    </tr>
                </thead>
                <tbody style={{fontSize: '14px'}}>
                    {
                         dataInversion.map((x,index)=>{                           
                            return( 
                                <tr key={index}>                                        
                                    <td className="colorNegrita">{x.entidad}</td>                                      
                                    <td className="colorNegrita">
                                        <CurrencyFormat value={parseFloat(x.asignacion || 0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'S/ '} />
                                    </td>                                      
                                    {/* <td className="colorNegrita" >
                                        <CurrencyFormat value={parseFloat(x.asignacion_falt || 0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'S/ '} />
                                    </td> */}
                                    <td className="colorNegrita" >{parseFloat(x.cumple_porc || 0).toFixed(2)}</td>
                                    {/* <td className="colorNegrita" >{parseFloat(x.cumple_porc_falta || 0).toFixed(2)}</td> */}
                                    <td className="colorNegrita" >{x.tot_fisca || 0}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
                <tfoot>
                    <tr className='tablita'>
                        <th colSpan= "4" className="colorNegritaLeft">
                            * Recursos del Estado e implementación de las OMAPED y OREDIS: contemplada en el artículo 6, 69, 70 ° de la Ley N° 29973, Ley General de la Persona con Discapacidad, concordado con la Trigésima Segunda Disposición Complementaria Final de la Ley N° 31084, Ley de Presupuesto del Sector Público para el año Fiscal 2021.<br/>
                            ** El eje priorizado de presupuesto público, bajo un enfoque de cumplimiento normativo de la fiscalización, realiza fiscalizaciones para verificar el cumplimiento de los mandatos contenidos en el ordenamiento jurídico nacional referidos al uso total del porcentaje del presupuesto institucional asignado de los gobiernos locales y regionales, 
                            con la finalidad de la realización y adecuación de obras, así como el financiamiento de planes, programas y proyectos.<br/>
                            1/ Comprende los 43 Distritos de la provincia de Lima.<br/>
                            Fuente: Sub Dirección de Fiscalización - CONADIS<br/>
                            Fecha de Corte: 31/12/2021
                        </th> 
                    </tr>
                </tfoot>
            </table>
            { showTable ?
                <table id="tblCuotaHide" border="1" ref={tableRefHide}>
                    <thead> 									
                        <tr> 
                            <th>ENTIDADES</th>
                            <th>% CUMPLIMIENTO</th>
                            <th>ASIGNACIÓN PRESUPUESTAL FALTANTE (PIM - 2021 ACCESIBILIDAD) </th>
                            <th>% CUMPLIMIENTO</th>
                            <th>% FALTANTE AL CUMPLIMIENTO DEL 0.5%</th>
                            <th>TOTAL ENTIDADES FISCALIZADOS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataInversion.map((x,index)=>{                           
                                return( 
                                    <tr key={index}>                                        
                                        <td>{x.entidad}</td>                                      
                                        <td>
                                            <CurrencyFormat value={parseFloat(x.asignacion || 0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'S/ '} />
                                        </td>                                      
                                        <td>
                                            <CurrencyFormat value={parseFloat(x.asignacion_falt || 0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'S/ '} />
                                        </td>
                                        <td>{parseFloat(x.cumple_porc || 0).toFixed(2)}</td>
                                        <td>{parseFloat(x.cumple_porc_falta || 0).toFixed(2)}</td>
                                        <td>{x.tot_fisca || 0}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colSpan= "6" className="colorNegritaLeft">
                                * Recursos del Estado e implementación de las OMAPED y OREDIS: contemplada en el artículo 6, 69, 70 ° de la Ley N° 29973, Ley General de la Persona con Discapacidad, concordado con la Trigésima Segunda Disposición Complementaria Final de la Ley N° 31084, Ley de Presupuesto del Sector Público para el año Fiscal 2021.<br/>
                                ** El eje priorizado de presupuesto público, bajo un enfoque de cumplimiento normativo de la fiscalización, realiza fiscalizaciones para verificar el cumplimiento de los mandatos contenidos en el ordenamiento jurídico nacional referidos al uso total del porcentaje del presupuesto institucional asignado de los gobiernos locales y regionales, 
                                con la finalidad de la realización y adecuación de obras, así como el financiamiento de planes, programas y proyectos.<br/>
                                1/ Comprende los 43 Distritos de la provincia de Lima.<br/>
                                Fuente: Sub Dirección de Fiscalización - CONADIS<br/>
                                Fecha de Corte: 31/12/2021
                            </th> 
                        </tr>
                    </tfoot>
                </table> : '' }
        </div>     
    );
    
}
export default ResumenTablaInversion;

import Control from "react-leaflet-custom-control";

export function LeyendaMapaRubro() {
    let legend = [];
    legend.push( 
        <div className="card" key="lr1">
            <div className="card-body">
                <table border="0">
                    <tbody>
                        <tr>
                            <td className="font-w900"><strong>LEYENDA</strong></td>
                        </tr>
                        <tr>
                            <td className="leyenda">
                                <img className="leyendaImg" src="https://maps.google.com/mapfiles/ms/micons/green-dot.png"/>
                                <strong> SI CUMPLE - BONIFICACIÓN DEL 15% </strong>
                            </td>
                        </tr> 
                        <tr>
                            <td className="leyenda">
                                <img className="leyendaImg" src="https://maps.google.com/mapfiles/ms/micons/red-dot.png"/>
                                <strong> NO CUMPLE - BONIFICACIÓN DEL 15% </strong>
                            </td>
                        </tr>                      
                    </tbody>
                </table>
            </div>
        </div>
    );
      

    return (
        <Control position="bottomleft">
             {legend}          
        </Control>
    )
}






// import { MapControl, withLeaflet } from "react-leaflet";
// import L from "leaflet";

// class LeyendaRubroMapa extends MapControl {
//   createLeafletElement(props) {
//     // console.log(props.sede);
//   }

//   componentDidMount() {
//     let sede_ope = this.props.sede;
//     console.log(sede_ope);
//     // get color depending on population density value
//     const getColor = d => {
//       return  sede_ope;
//     };
//     const legend = L.control({ position: "bottomleft" });
//     legend.onAdd = () => {
//       const div = L.DomUtil.create("div", "legend");
//       let labels = [];

//         labels.push(
//         '<div class="card">'+
//             '<div class="card-body">'+
//                 '<table>'+
//                     '<tbody>'+
//                         '<tr>'+
//                             '<td class="font-w900"><strong>LEYENDA</strong></td>'+
//                         '</tr>'+
//                         '<tr>'+
//                             '<td>'+
//                                 '<img style="width: 20px; height: 20px" src="https://maps.google.com/mapfiles/ms/micons/green-dot.png">'+
//                                 '<strong> SI CUMPLE - BONIFICACIÓN DEL 15% </strong>'+
//                             '</td>'+
//                         '</tr>'+
//                         '<tr>'+
//                             '<td>'+
//                                 '<img style="width: 20px; height: 20px" src="https://maps.google.com/mapfiles/ms/micons/red-dot.png">'+
//                                 '<strong> NO CUMPLE - BONIFICACIÓN DEL 15% </strong>'+
//                             '</td>'+
//                         '</tr>   '+                        
//                     '</tbody>'+
//                 '</table>'+
//             '</div>'+
//         '</div>'
//         );
//     //   }
            
//       div.innerHTML = labels.join("<br>");
//       return div;
//     };

//     const { map } = this.props.leaflet;
//     legend.addTo(map);
//     // home.addTo(map);
//   }
// }

// export default withLeaflet(LeyendaRubroMapa);

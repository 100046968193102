
import React, {useState,useEffect,useRef} from 'react'
import ReactHtmlTableToExcel from 'react-html-table-to-excel' 
import CurrencyFormat from 'react-currency-format';

import "./cuotaempleoPage.css"

 function ResumenUniversidades (props) {
    const [dataEducacion, setDataEducacion] = useState(props.lista);
    const [showTable, setShowTable] = useState(false);
    const tableRefHide = useRef(null);
    useEffect(() => {
        setDataEducacion(props.lista);
        setShowTable(false)
    }, [props.lista]);

    return(
        <div className="table-responsive">
            <div className="card">
                <div className="card-header text-center">
                    <h4 className="card-text text-primary font-w900"> RESULTADOS DE UNIVERSIDADES</h4>
                </div> 
                <div className="card-body">
                    <div className="col text-right" style={{marginBottom: '5px'}}> 
                        <ReactHtmlTableToExcel
                            id="test-table-xls-button"
                            className="btn btn-danger"
                            table="tblCuota"
                            filename="CONADIS - EJE EDUCACIÓN - UNIVERSIDADES"
                            sheet="EJE EDUCACIÓN - UNIVERSIDADES"
                            buttonText="Descargar Información"
                        /> 
                    </div> 
                    <table id="tblCuota" border="1" className="table table-striped table-bordered">
                        <thead>
                            <tr className='tablita'>
                                <th colSpan= "9"> UNIVERSIDADES FISCALIZADAS</th> 
                            </tr>
                            <tr className='tablita'> 
                                <th rowSpan= "2">ENTIDADES</th>
                                <th colSpan= "4">AÑO 2019</th> 
                                <th colSpan= "4">AÑO 2020</th>
                            </tr>
                            <tr className='tablita'> 
                                <th>TOTAL DE ESTUDIANTES EN LA IE</th>
                                <th>TOTAL DE ESTUDIANTES CON DISCAPACIDAD</th>
                                <th>TOTAL DE ECD FEMENINO</th>
                                <th>TOTAL DE ECD MASCULINO</th>
                                
                                <th>TOTAL DE ESTUDIANTES EN LA IE</th>
                                <th>TOTAL DE ESTUDIANTES CON DISCAPACIDAD</th>
                                <th>TOTAL DE ECD FEMENINO</th>
                                <th>TOTAL DE ECD MASCULINO</th> 
                            </tr>  
                        </thead>
                        <tbody style={{fontSize: '14px'}}>
                            {
                                dataEducacion.map((x,index)=>{                           
                                    return( 
                                        <tr key={index}>
                                            {
                                                x.ccdd != null && x.orden == 99 ? <td class="colorNegrita" style={{ color: '#BF0808'}}>{x.entidad}</td>
                                                : <td class="colorNegritaLeft" >{x.entidad}</td>
                                            }
                                            <td class="colorNegrita ">{x.tot_estudiantes_ie_2019 || 0}</td>
                                            <td class="colorNegrita ">{x.tot_estudiantes_pcd_2019 || 0}</td>
                                            <td class="colorNegrita ">{x.tot_ecd_femenino_2019 || 0}</td>
                                            <td class="colorNegrita ">{x.tot_ecd_masculino_2019 || 0}</td>  
                                            <td class="colorNegrita ">{x.tot_estudiantes_ie_2020 || 0}</td>
                                            <td class="colorNegrita ">{x.tot_estudiantes_pcd_2020 || 0}</td>
                                            <td class="colorNegrita ">{x.tot_ecd_femenino_2020 || 0}</td>
                                            <td class="colorNegrita ">{x.tot_ecd_masculino_2020 || 0}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                        <tfoot>
                            <tr className='tablita'>
                                <th colSpan= "9" className="colorNegritaLeft">
                                    1/ Comprende los 43 Distritos de la provincia de Lima.<br/> 
                                    Nota: En los Ministerios de Energía y Minas, Defensa y Ministerio d ela Mujer y Poblaciones Vulnerbles se  procederá a realizar una fiscalización de gabinete, considerando la informacion del año  2021. <br/>
                                    Fuente: Sub Dirección de Fiscalización - CONADIS<br/>
                                    Fecha de Corte: 31/12/2021
                                </th> 
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>     
    );
    
}
export default ResumenUniversidades;

